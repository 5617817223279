import { Button, Form, InputNumber, Select } from 'antd';
import React from 'react';
import {
  DEPOSIT_WAYS,
  getAccountMinimumDeposit,
  getDepositDialogInstractions,
  getDepositDialogTitle,
} from '../../../assets/comfig/deposit-ways';
import { converImageToBase64String } from '../../../helpers/convertImageToBase64';
import { API_ENDPOINTS } from '../../../assets/api/endpoints';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getUserTokenInCookie } from '../../../helpers/cookiesHandler';
import './OtherDeposit.scss';

function OtherDeposit({
  depositMethod,
  depositMethodName,
  userAccountsOptions,
  userAccounts,
  closeModelSuccessHandler,
  errorHandler,
}: {
  depositMethod: DEPOSIT_WAYS;
  depositMethodName: string;
  userAccountsOptions: { labebl: string; value: string }[];
  userAccounts: any[];
  closeModelSuccessHandler: () => void;
  errorHandler: (error: string) => void;
}) {
  const { t } = useTranslation();
  const [depositForm] = Form.useForm();
  const dialogTitle = React.useMemo(() => getDepositDialogTitle(depositMethod, t), [depositMethod]);
  const dialogInstractions = React.useMemo(() => getDepositDialogInstractions(depositMethod, t), [depositMethod]);
  const [accountMinimumDeposit, setAccountMinimumDeposit] = React.useState<number | string>('');
  const [selectedAccount, setSelectedAccount] = React.useState<any>(null);
  const [depositProof, setDepositProof] = React.useState<any | null>(null);
  const [depositFormDisabled, setDepositFormDisabled] = React.useState(true);
  const [depositFormLoading, setDepositFormLoading] = React.useState(false);

  const onAccountChange = () => {
    const account = userAccounts?.find((account) => account?.id === depositForm.getFieldsValue()?.accountId);
    setSelectedAccount(account);
    const minDeposit = getAccountMinimumDeposit(account?.accountType?.id || '');
    setAccountMinimumDeposit(minDeposit);
  };

  const onImageUpload = async (e: any) => {
    e.preventDefault();
    const uploadedFile = e.target.files[0];
    await converImageToBase64String(uploadedFile).then((imageUrl) => {
      setDepositProof({
        content: imageUrl,
        mime: uploadedFile?.type,
        name: uploadedFile?.name,
        size: uploadedFile?.size,
      });
      onDepositFormChange();
    });
  };

  const onDepositFormChange = () => {
    const depositFormValues = depositForm.getFieldsValue();

    setDepositFormDisabled(
      depositFormValues?.ammount < accountMinimumDeposit ||
        !depositFormValues.wallet ||
        depositProof === null ||
        selectedAccount === null
    );
  };

  React.useEffect(() => onDepositFormChange(), [depositProof]);

  const onDepositFormSubmit = React.useCallback(() => {
    const formValues = depositForm.getFieldsValue();
    setDepositFormLoading(true);

    axios
      .post(
        API_ENDPOINTS.cryptoDeposit,
        {
          account: selectedAccount,
          amount: formValues.ammount.toString(),
          image: depositProof,
          merchant: 'cryptoWallet',
          wallet: formValues.wallet,
          walletName: depositMethodName,
        },
        {
          headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
        }
      )
      .then(() => {
        setDepositFormLoading(false);
        closeModelSuccessHandler();
      })
      .catch((error) => {
        console.error(error);
        errorHandler(t<string>('somethingWentWrongWithTheDeposit'));
        setDepositFormLoading(false);
      });
  }, []);

  return (
    <section className='other-deposit-container'>
      <h3 className='dialog-title'>{dialogTitle}</h3>
      <p className='dialog-instractions'>{dialogInstractions}</p>
      <Form
        name='basic'
        form={depositForm}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}
        onValuesChange={() => onDepositFormChange()}
        autoComplete='off'
        onFinish={onDepositFormSubmit}
      >
        <Form.Item
          name='accountId'
          label={t<string>('account')}
          rules={[{ required: true, message: t<string>('accountIsRequired') }]}
        >
          <Select style={{ width: '100%' }} options={userAccountsOptions} onChange={onAccountChange} />
        </Form.Item>
        <Form.Item
          name='ammount'
          label={t<string>('amount')}
          rules={[
            { required: true, message: t<string>('amountIsRequired') },
            () => ({
              validator(_, value) {
                return value >= accountMinimumDeposit
                  ? Promise.resolve()
                  : Promise.reject(`${t<string>('minimumValueIs')}: ${accountMinimumDeposit}`);
              },
            }),
          ]}
        >
          <InputNumber
            defaultValue={accountMinimumDeposit}
            style={{ width: '100%' }}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => value?.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item
          name='wallet'
          label={t<string>('walletIsRequired')}
          rules={[{ required: true, message: t<string>('wallet') }]}
        >
          <InputNumber defaultValue={''} style={{ width: '100%' }} />
        </Form.Item>
        <div className='upload-confirmation-container'>
          <span className='file-name'>{depositProof?.name}</span>
          <label htmlFor='upload-confirmation-picture'>{t<string>('uploadConfirmation')}</label>
          <input id='upload-confirmation-picture' type='file' accept='image/*' onChange={onImageUpload} />
        </div>
        <Button type='primary' htmlType='submit' disabled={depositFormDisabled} loading={depositFormLoading}>
          {t<string>('sendDeposit')}
        </Button>
      </Form>
    </section>
  );
}

export default OtherDeposit;
