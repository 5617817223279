import { Outlet, Navigate } from 'react-router';
import React from 'react';
import { useMainContext } from '../store/MainContext';
import { Alert, Layout, Menu, Popconfirm, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import Sider from 'antd/es/layout/Sider';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import { getMenuItems } from '../helpers/menuItems';
import { LoadingOutlined, PoweroffOutlined, SwapOutlined } from '@ant-design/icons';
import Languages from '../components/Languages/Languages';
import { useTranslation } from 'react-i18next';
import { getUserLanguageInCookie, getUserTokenInCookie } from '../helpers/cookiesHandler';
import { companyDetailsMock } from '../mocks/companyDetailsMock';
import './ProtectedRoutes.scss';
import axios from 'axios';
import { API_ENDPOINTS } from '../assets/api/endpoints';

function ProtectedRoutes() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSmallScreen = React.useMemo(() => window.innerWidth < 700, []);
  const { mainState, setMainState } = useMainContext();
  const [userDetailsError, setUserDetailsError] = React.useState('');
  const [errorCompanyDetails, setErrorCompanyDetails] = React.useState('');
  const [collapsedSidePanel, setCollapsedSidePanel] = React.useState(isSmallScreen);
  const [selectedMenuItem, setSelectedMenuItem] = React.useState('');
  const currectYear = React.useMemo(() => new Date().getFullYear(), []);
  const userInfo = React.useMemo(() => JSON.parse(window.localStorage.getItem('userInfo') ?? '{}'), []);

  React.useEffect(() => {
    setSelectedMenuItem(window.location.pathname.replace('/', ''));
    const body = document.body;
    body.className = '';
    body.classList.add(getUserLanguageInCookie() === 'ar' ? 'rtl' : 'ltr');
  }, []);

  const menuItems = React.useMemo(() => getMenuItems(t, userInfo.isPartner, userInfo.kycApproved), [t]);

  React.useEffect(() => {
    setMainState({ ...mainState, ...companyDetailsMock });

    axios
      .get(API_ENDPOINTS.personalInfo, {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((userData: any) => {
        window.localStorage.setItem('userInfo', JSON.stringify(userData?.data?.data));
      })
      .catch((error) => {
        console.error(error);
      });

    if (!userInfo.id) {
      navigate('/sign-in');
    }
  }, []);

  const onMenuItemSelect = React.useCallback((menuItemKey: string) => {
    navigate(menuItemKey);
    setSelectedMenuItem(window.location.pathname.replace('/', ''));
  }, []);

  return Boolean(mainState?.isLoggedIn) ? (
    <section className='app'>
      <main className='main'>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            style={{ backgroundColor: mainState.colorBgMenu }}
            collapsible
            collapsed={collapsedSidePanel}
            onCollapse={(value) => setCollapsedSidePanel(value)}
            collapsedWidth={70}
            trigger={
              <div style={{ backgroundColor: mainState.colorBgMenu }}>
                <SwapOutlined style={{ fontSize: '24px' }} />
              </div>
            }
          >
            <div className='side-panel-logo-container'>
              {mainState.companyLogo ? (
                <img src={mainState.companyLogo} alt='Company Logo' className='side-panel-logo' />
              ) : (
                <LoadingOutlined style={{ fontSize: 24, color: '#ffffff' }} spin />
              )}
            </div>
            <Menu
              theme='dark'
              mode='inline'
              triggerSubMenuAction='click'
              items={menuItems.menu}
              onSelect={(menuItem) => onMenuItemSelect(menuItem.key)}
              selectedKeys={[selectedMenuItem]}
            />
          </Sider>
          <Layout className='site-layout'>
            <Header
              className='main-header'
              style={{ overflow: 'hidden', height: '46px', backgroundColor: mainState.colorBgMenu }}
            >
              <Menu
                theme='dark'
                mode='horizontal'
                triggerSubMenuAction='click'
                items={menuItems.header}
                onSelect={(menuItem) => onMenuItemSelect(menuItem.key)}
                selectedKeys={[selectedMenuItem]}
              />
            </Header>
            <Content style={{ margin: '0 16px' }}>
              {mainState.isDetailsLoading || mainState.isCompanyLoading ? (
                <div className='user-details-spinner-container'>
                  <Spin size='large' />
                </div>
              ) : (
                <Outlet />
              )}
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              {mainState.companyName ? (
                <div>
                  <p className='footer-content capture'>{t<string>('footerContent')}</p>
                  <p className='footer-content'>
                    Copyright © {currectYear} {mainState.companyName}. {t<string>('allRightsReserved')}
                  </p>
                </div>
              ) : (
                <LoadingOutlined style={{ fontSize: 16, color: '#001529' }} spin />
              )}
            </Footer>
          </Layout>
        </Layout>
        {userDetailsError ? (
          <div className='alert-container'>
            <Alert
              description={userDetailsError}
              type='error'
              showIcon
              closable
              onClose={() => setUserDetailsError('')}
            />
          </div>
        ) : undefined}
        {errorCompanyDetails ? (
          <div className='alert-container'>
            <Alert
              description={errorCompanyDetails}
              type='error'
              showIcon
              closable
              onClose={() => setErrorCompanyDetails('')}
            />
          </div>
        ) : undefined}
      </main>
      <Popconfirm
        placement={getUserLanguageInCookie() === 'ar' ? 'bottomLeft' : 'bottomRight'}
        title={t<string>('areYouSureYouWantToLogOut')}
        onConfirm={() => navigate('sign-in')}
        okText={t<string>('yes')}
        cancelText={t<string>('no')}
      >
        <PoweroffOutlined className='logout-button' />
      </Popconfirm>
      <Languages />
    </section>
  ) : (
    <Navigate to='/sign-in' />
  );
}

export default ProtectedRoutes;
