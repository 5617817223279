import { Alert, Badge, Card, Form, Input, Select } from 'antd';
import React from 'react';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { AlertOutlined } from '@ant-design/icons';
import SocialMedia from '../../components/SocialMedia/SocialMedia';
import { useTranslation } from 'react-i18next';
import './PromotionalMaterials.scss';
import axios from 'axios';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';

function PromotionalMaterials() {
  const { t } = useTranslation();
  const [ibMyClientsForm] = Form.useForm();
  const [selectedAccountNumber, setSelectedAccountNumber] = React.useState<null | number>(null);
  const [ibAccounts, setIbAccounts] = React.useState([]);
  const [userAccountsOptions, setUserAccountsOptions] = React.useState([]);
  const [userAccountsLoading, setUserAccountsLoading] = React.useState(false);
  const [userAccountsError, setUserAccountsError] = React.useState('');

  React.useEffect(() => {
    setUserAccountsLoading(true);

    axios
      .get(API_ENDPOINTS.account, {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((myAccounts: any) => {
        const allIbAccounts = myAccounts?.data?.data?.ib;

        setIbAccounts(allIbAccounts);
        setUserAccountsOptions(
          allIbAccounts?.map(
            (account: any) =>
              ({
                value: account?.id,
                label: `#${account?.number} ${account?.accountType.name} | ${account?.balance} ${account?.currency}`,
              } as never)
          )
        );
        setSelectedAccountNumber(allIbAccounts[0].number);
        ibMyClientsForm.setFieldValue('accountId', allIbAccounts[0].id);
        ibMyClientsForm.setFieldValue(
          'myReferalLink',
          `https://inzo.co/open-live-account?ib=${allIbAccounts[0].number}`
        );
        ibMyClientsForm.setFieldValue(
          'myTestReferalLink',
          `https://inzo.co/open-demo-account?ib=${allIbAccounts[0].number}`
        );
        setUserAccountsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setUserAccountsError(t<string>('somethingWentWrongWithLoadingTheAccounts'));
        setUserAccountsLoading(false);
      });
  }, []);

  function onAccountChange() {
    const selectedAccount = ibAccounts.find(
      (account: any) => account?.id === ibMyClientsForm.getFieldValue('accountId')
    ) || { number: 0 };
    setSelectedAccountNumber(selectedAccount.number);
    ibMyClientsForm.setFieldValue('myReferalLink', `https://inzo.co/open-live-account?ib=${selectedAccount?.number}`);
    ibMyClientsForm.setFieldValue(
      'myTestReferalLink',
      `https://inzo.co/open-demo-account?ib=${selectedAccount.number}`
    );
  }

  return (
    <section className='my-traders-container'>
      <h1>{t<string>('myPromotionalMaterials')}</h1>
      <Badge.Ribbon text={<AlertOutlined style={{ fontSize: 18 }} />}>
        <Card title={t<string>('myPromotionalMaterialsControlForm')}>
          <Form
            name='basic'
            form={ibMyClientsForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}
            autoComplete='off'
            disabled={userAccountsLoading}
          >
            <Form.Item
              name='accountId'
              label={t<string>('account')}
              rules={[{ required: true, message: t<string>('accountIsRequired') }]}
            >
              <Select
                style={{ width: '100%' }}
                options={userAccountsOptions}
                loading={userAccountsLoading}
                onChange={() => onAccountChange()}
              />
            </Form.Item>
            <div>
              <Form.Item label={t<string>('myReferalLink')} name='myReferalLink'>
                <Input disabled={true} />
              </Form.Item>
              <SocialMedia
                isLoading={userAccountsLoading}
                url='https://inzo.co/open-live-account?ib='
                accountNumber={selectedAccountNumber || 0}
              />
            </div>
            <div>
              <Form.Item label={t<string>('myTestReferalLink')} name='myTestReferalLink'>
                <Input disabled={true} />
              </Form.Item>
              <SocialMedia
                isLoading={userAccountsLoading}
                url='https://inzo.co/open-demo-account?ib='
                accountNumber={selectedAccountNumber || 0}
              />
            </div>
          </Form>
        </Card>
      </Badge.Ribbon>
      {userAccountsError ? (
        <div className='alert-container'>
          <Alert
            description={userAccountsError}
            type='success'
            showIcon
            closable
            onClose={() => setUserAccountsError('')}
          />
        </div>
      ) : undefined}
    </section>
  );
}

export default PromotionalMaterials;
