import {
  DollarCircleFilled,
  BankOutlined,
  UserOutlined,
  FileDoneOutlined,
  RiseOutlined,
  AreaChartOutlined,
  TrophyOutlined,
  InteractionOutlined,
  GatewayOutlined,
  CarryOutOutlined,
  BarChartOutlined,
  SlidersOutlined,
  SettingOutlined,
  AppstoreOutlined,
  PieChartOutlined,
  AlertOutlined,
  IdcardOutlined,
  MonitorOutlined,
  RadiusSettingOutlined,
} from '@ant-design/icons';

export function getMenuItems(
  t: (value: string) => void,
  isIb: boolean,
  isActive: boolean
): { header: any[]; menu: any[] } {
  return {
    header: [
      { key: 'home', label: t('home'), style: { fontSize: 12 }, disabled: !isActive },
      { key: 'downloads', label: t('downloads'), style: { fontSize: 12 }, disabled: !isActive },
      { key: 'support', label: t('support'), style: { fontSize: 12 } },
      { key: 'top-traders', label: t('topTraders'), style: { fontSize: 12 }, disabled: !isActive },
    ],
    menu: [
      {
        key: 'my-profile',
        label: t('myProfile'),
        icon: <UserOutlined />,
        style: { fontSize: 12 },
        disabled: !isActive,
      },
      { key: 'my-documents', label: t('myDocuments'), icon: <FileDoneOutlined />, style: { fontSize: 12 } },
      {
        key: 'my-accounts',
        label: t('myAccounts'),
        icon: <AppstoreOutlined />,
        style: { fontSize: 12 },
        disabled: !isActive,
      },
      {
        key: 'open-account',
        label: t('openAccount'),
        icon: <BankOutlined />,
        style: { fontSize: 12 },
        disabled: !isActive,
        children: [
          { label: t('liveAccount'), key: 'live-account', icon: <RiseOutlined />, style: { fontSize: 12 } },
          {
            label: t('demoAccount'),
            key: 'demo-account',
            icon: <AreaChartOutlined />,
            style: { fontSize: 12 },
          },
          { label: t('ibAccount'), key: 'ib-account', icon: <TrophyOutlined />, style: { fontSize: 12 } },
        ],
      },
      {
        key: 'deposit-withdraw',
        label: t('depositAndWithdraw'),
        icon: <DollarCircleFilled />,
        style: { fontSize: 12 },
        disabled: !isActive,
        children: [
          { label: t('deposit'), key: 'deposit', icon: <CarryOutOutlined />, style: { fontSize: 12 } },
          { label: t('withdraw'), key: 'withdraw', icon: <GatewayOutlined />, style: { fontSize: 12 } },
          { label: t('transfer'), key: 'transfer', icon: <InteractionOutlined />, style: { fontSize: 12 } },
        ],
      },
      {
        key: 'trading',
        label: t('trading'),
        icon: <BarChartOutlined />,
        style: { fontSize: 12 },
        disabled: !isActive,
        children: [
          {
            label: t('tradingHistory'),
            key: 'trading-history',
            icon: <SlidersOutlined />,
            style: { fontSize: 12 },
          },
          {
            label: t('platformPasswords'),
            key: 'platforms-password',
            icon: <SettingOutlined />,
            style: { fontSize: 12 },
          },
        ],
      },
      {
        key: 'agent-portal',
        label: t('agentPortal'),
        icon: <PieChartOutlined />,
        style: { fontSize: 12 },
        disabled: !isIb || !isActive,
        children: [
          {
            label: t('promotions'),
            key: 'promotional-materials',
            icon: <AlertOutlined />,
            style: { fontSize: 12 },
          },
          {
            label: t('clients'),
            key: 'ib-my-clients',
            icon: <IdcardOutlined />,
            style: { fontSize: 12 },
          },
          {
            label: t('secondaryAgents'),
            key: 'ib-my-secondary-agents',
            icon: <MonitorOutlined />,
            style: { fontSize: 12 },
          },
        ],
      },
      { key: 'copy', label: t('copy'), icon: <RadiusSettingOutlined />, style: { fontSize: 12 }, disabled: !isActive },
    ],
  };
}
