export const liveMT5AccountsTypes: Record<string, {id: string; values: number[]; label: string}> = {
    standard_MT5: {
        id: 'd48f26b0-0bde-11ed-8c5f-b17f3651b995',
        values: [1, 50, 100],
        label: 'MT5 Standard + Account',
    },
    zero_MT5: {
        id: '18cfc1d0-2856-11ec-9002-55ac8a3dc838',
        values: [1, 50, 100, 200],
        label: 'MT5 Zero Account',
    },
    vip_MT5: {
        id: '1f584d10-14a6-11ec-b13e-8d950accb896',
        values: [1, 50],
        label: 'MT5 VIP Account',
    },
    stock_MT5: {
        id: 'e00547c0-2ce8-11ec-b4d0-456159ba30b2',
        values: [1, 20],
        label: 'MT5 Stock Account',
    },
    mini_MT5: {
        id: 'ab67ba30-1a49-11ed-8ec7-4f7a54de5c7c',
        values: [1, 20, 100, 200, 300, 500],
        label: 'MT5 Mini Account',
    },
    contest: {
        id: 'bb7fdf80-8717-11ec-b216-bb25ffef5a9c',
        values: [1, 100, 200, 500],
        label: 'MT5 Contest Account',
    },
};

export const liveCTraderAccountsTypes: Record<string, {id: string; values: number[]; label: string}> = {
    standard_cTrader: {
        id: '491fe850-cda1-11ed-b152-45cef807a0e7',
        values: [1, 20, 50, 100],
        label: 'cTrader Standard Account',
    },
    zero_cTrader: {
        id: 'a91e81d0-ce14-11ed-b152-45cef807a0e7',
        values: [1, 20, 50, 100, 200],
        label: 'cTrader Zero Account',
    },
    vip_cTrader: {
        id: 'c1667ae0-ce14-11ed-b152-45cef807a0e7',
        values: [1, 20, 50],
        label: 'cTrader VIP Account',
    },
    stock_cTrader: {
        id: 'd33706e0-ce14-11ed-b152-45cef807a0e7',
        values: [1, 20],
        label: 'cTrader Stock Account',
    },
};

export const demoMT5AccountsTypes: Record<string, {id: string; values: number[]; label: string}> = {
    standard_MT5: {
        id: 'd03ea540-8785-11ed-93d3-c755764a12ca',
        values: [1, 20, 50, 100, 200, 400, 500],
        label: 'MT5 Standard Demo Acocunt',
    },
    zero_MT5: {
        id: 'eb27fd20-8785-11ed-93d3-c755764a12ca',
        values: [1, 20, 50, 100, 200],
        label: 'MT5 Zero Demo Acocunt',
    },
    mini_MT5: {
        id: 'fed8bad0-8785-11ed-93d3-c755764a12ca',
        values: [1, 20, 50, 100, 200, 400, 500],
        label: 'MT5 Mini Demo Acocunt',
    },
}

export const demoCTraderAccountsTypes: Record<string, {id: string; values: number[]; label: string}> = {
    standard_cTrader: {
        id: 'fc8cc140-ce4d-11ed-b2c0-b1d90f5c440c',
        values: [1, 20, 50, 100, 200, 400, 500],
        label: 'cTrader Standard Demo Acocunt',
    },
    zero_cTrader: {
        id: '1046f4d0-ce4e-11ed-b2c0-b1d90f5c440c',
        values: [1, 20, 50, 100, 200],
        label: 'cTrader Zero Demo Acocunt',
    },
    stock_cTrader: {
        id: '22f5f9f0-ce4e-11ed-b2c0-b1d90f5c440c',
        values: [1, 20],
        label: 'cTrader Stock Demo Acocunt',
    },
}