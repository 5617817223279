import { Alert, Badge, Button, Card, Modal } from 'antd';
import { DollarOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import React from 'react';
import { DEPOSIT_WAYS, getDepositMethodName } from '../../assets/comfig/deposit-ways';
import OtherDeposit from './OtherDeposit/OtherDeposit';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import BinPayDeposit from './BinPayDeposit/BinPayDeposit';
import { useTranslation } from 'react-i18next';
import { DEPOSIT_METHODS } from '../../assets/comfig/deposit-config';
import axios from 'axios';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import './Deposit.scss';

function Deposit() {
  const { t } = useTranslation();
  const [depositMethod, setDepositMethod] = React.useState<DEPOSIT_WAYS | null>(null);
  const [depositFormLoading, setDepositFormLoading] = React.useState(true);
  const [userAccounts, setUserAccounts] = React.useState([]);
  const [userAccountsOptions, setUserAccountsOptions] = React.useState([]);
  const [userAccountsError, setUserAccountsError] = React.useState('');
  const depositMethodName = React.useMemo(() => getDepositMethodName(depositMethod), [depositMethod]);
  const [depositFormSuccess, setDepositFormSuccess] = React.useState('');
  const [depositFormError, setDepositFormError] = React.useState('');

  React.useEffect(() => {
    setDepositFormLoading(true);
    axios
      .get(API_ENDPOINTS.account, {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((myAccounts: any) => {
        const tradingAccounts = myAccounts?.data?.data?.trading;
        setUserAccounts(tradingAccounts);
        setUserAccountsOptions(
          tradingAccounts.map(
            (account: any) =>
              ({
                value: account?.id,
                label: `#${account?.number} ${account?.accountType.name} | ${account?.balance} ${account?.currency}`,
              } as never)
          )
        );
        setDepositFormLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setUserAccountsError(t<string>('somethingWentWrongWithLoadingTheAccounts'));
        setDepositFormLoading(false);
      });
  }, []);

  const handelSuccessDeposit = () => {
    setDepositMethod(null);
    setDepositFormSuccess(t<string>('theDepositWasSentSuccessfully'));
  };

  return (
    <section className='deposit-container'>
      <h1>{t<string>('depositToYourAccounts')}</h1>
      <Badge.Ribbon text={<DeploymentUnitOutlined style={{ fontSize: 18 }} />}>
        <Card title={t<string>('depositInDigitalCurrencies')}>
          <div className='cards-container'>
            <Button type='primary' loading={depositFormLoading} onClick={() => setDepositMethod(DEPOSIT_WAYS.binPay)}>
              {t<string>('cryptoDeposit')}
            </Button>
          </div>
        </Card>
      </Badge.Ribbon>
      <Badge.Ribbon text={<DollarOutlined style={{ fontSize: 18 }} />}>
        <Card title={t<string>('depositUsingWallets')}>
          <div className='cards-container'>
            {DEPOSIT_METHODS.map((depositMethod) => (
              <Button type='primary' loading={depositFormLoading} onClick={() => setDepositMethod(depositMethod.value)}>
                {depositMethod.label}
              </Button>
            ))}
          </div>
        </Card>
      </Badge.Ribbon>
      {depositMethod ? (
        <Modal
          title={`${t<string>('depositUsing')} ${depositMethodName}`}
          open={Boolean(depositMethod)}
          footer={null}
          onCancel={() => setDepositMethod(null)}
        >
          {depositMethod === DEPOSIT_WAYS.binPay ? (
            <BinPayDeposit
              userAccountsOptions={userAccountsOptions}
              userAccounts={userAccounts}
              closeModelSuccessHandler={() => handelSuccessDeposit()}
              errorHandler={(error) => setDepositFormError(error)}
            />
          ) : (
            <OtherDeposit
              depositMethod={depositMethod}
              depositMethodName={depositMethodName || ''}
              userAccountsOptions={userAccountsOptions}
              userAccounts={userAccounts}
              closeModelSuccessHandler={() => handelSuccessDeposit()}
              errorHandler={(error) => setDepositFormError(error)}
            />
          )}
        </Modal>
      ) : undefined}
      {userAccountsError ? (
        <div className='alert-container'>
          <Alert
            description={userAccountsError}
            type='error'
            showIcon
            closable
            onClose={() => setUserAccountsError('')}
          />
        </div>
      ) : undefined}
      {depositFormSuccess ? (
        <div className='alert-container'>
          <Alert
            description={depositFormSuccess}
            type='success'
            showIcon
            closable
            onClose={() => setDepositFormSuccess('')}
          />
        </div>
      ) : undefined}
      {depositFormError ? (
        <div className='alert-container'>
          <Alert
            description={depositFormError}
            type='error'
            showIcon
            closable
            onClose={() => setDepositFormError('')}
          />
        </div>
      ) : undefined}
    </section>
  );
}

export default Deposit;
