import { Result, Table, Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { getTradesColumns } from '../../assets/comfig/trades-columns';
import { TradesData } from '../../models/platform';
import { TradeInfo } from '../../models/TradeInfo';
import axios from 'axios';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import './Home.scss';

function Home() {
  const { t } = useTranslation();
  const [mt5Trades, setMt5Trades] = React.useState<TradeInfo[]>([]);
  const [cTraderTrades, setCTraderTrades] = React.useState<TradeInfo[]>([]);
  const [tradesLoadingError, setTradesLoadingError] = React.useState('');
  const [tradesLoading, setTradesLoading] = React.useState(false);

  React.useEffect(() => {
    setTradesLoading(true);

    axios
      .get(API_ENDPOINTS.recentTradingActivity, { headers: { Authorization: `Bearer ${getUserTokenInCookie()}` } })
      .then((userTrades: any) => {
        const userTradesData: TradesData[] = userTrades.data.data;
        const userMT5TradesData: TradeInfo[] =
          userTradesData.find((userTradeData: TradesData) => userTradeData.platform.type === 'MT5')?.tradingActivity
            ?.data ?? [];
        const userCTraderTradesData: TradeInfo[] =
          userTradesData.find((userTradeData: TradesData) => userTradeData.platform.type === 'CTRD')?.tradingActivity
            ?.data ?? [];

        setMt5Trades(userMT5TradesData.map((trade: TradeInfo, index: number) => ({ ...trade, key: index })));
        setCTraderTrades(userCTraderTradesData.map((trade: TradeInfo, index: number) => ({ ...trade, key: index })));

        setTradesLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setTradesLoadingError(t<string>('somethingWentWrongWithFetchingTheRecentTrades'));
      });
  }, []);

  const tradesColumns = getTradesColumns(t);

  const tabsItems = React.useMemo(
    () => [
      {
        key: 'mt5',
        label: 'MT5',
        children: (
          <>
            {mt5Trades!?.length > 0 ? (
              <h2>
                {t<string>('total')}: {mt5Trades?.length}
              </h2>
            ) : null}
            <Table
              columns={tradesColumns as any}
              dataSource={mt5Trades}
              bordered
              loading={tradesLoading}
              pagination={{ pageSize: 15 }}
            />
          </>
        ),
      },
      {
        key: 'cTrader',
        label: 'cTrader',
        children: (
          <>
            {cTraderTrades!?.length > 0 ? (
              <h2>
                {t<string>('total')}: {cTraderTrades?.length}
              </h2>
            ) : null}
            <Table
              columns={tradesColumns as any}
              dataSource={cTraderTrades}
              bordered
              loading={tradesLoading}
              pagination={{ pageSize: 15 }}
            />
          </>
        ),
      },
    ],
    [tradesColumns]
  );

  return (
    <section className='my-trades-container'>
      <h1>{t<string>('recentTradingActivity')}</h1>
      <div className='my-trades-table-container'>
        {tradesLoadingError ? (
          <Result status='warning' title={t<string>('problemsWithLoadingTradingActivities')} />
        ) : (
          <Tabs defaultActiveKey='mt5' items={tabsItems} />
        )}
      </div>
    </section>
  );
}

export default Home;
