export const getMyTradersColumns = (t: (value: string) => void) => {
  return [
    {
      title: t('name'),
      dataIndex: 'fullName',
      fixed: 'left' as any,
      sorter: (a: { fullName: string }, b: { fullName: string }) => a.fullName.localeCompare(b.fullName),
    },
    {
      title: t('email'),
      dataIndex: 'email',
      sorter: (a: { email: string }, b: { email: string }) => a.email.localeCompare(b.email),
    },
    {
      title: t('account'),
      dataIndex: 'number',
      sorter: (a: { number: number }, b: { number: number }) => a.number - b.number,
    },
    {
      title: t('balance'),
      dataIndex: 'balance',
      sorter: (a: { balance: number }, b: { balance: number }) => a.balance - b.balance,
      render: (balance: number) => (
        <span style={{ color: balance > 0 ? 'green' : balance < 0 ? 'red' : 'gray' }}>{balance}</span>
      ),
    },
    {
      title: t('currency'),
      dataIndex: 'currency',
      sorter: (a: { currency: string }, b: { currency: string }) => a.currency.localeCompare(b.currency),
    },
    {
      title: t('commission'),
      dataIndex: 'commission',
      sorter: (a: { commission: number }, b: { commission: number }) => a.commission - b.commission,
    },
    {
      title: t('totalCommission'),
      dataIndex: 'commissionTotal',
      sorter: (a: { commissionTotal: number }, b: { commissionTotal: number }) => a.commissionTotal - b.commissionTotal,
    },
  ];
};
