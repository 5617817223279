import { InteractionOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Card, Checkbox, Form, InputNumber, Select } from 'antd';
import React from 'react';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import './Transfer.scss';

function Transfer() {
  const { t } = useTranslation();
  const [transferForm] = Form.useForm();
  const [userAccountsError, setUserAccountsError] = React.useState('');
  const [userAccountsOptions, setUserAccountsOptions] = React.useState([]);
  const [transferFormValid, setTransferFormValid] = React.useState(false);
  const [isTransferFormLoading, setIsTransferFormLoading] = React.useState(false);
  const [userAccountsLoading, setUserAccountsLoading] = React.useState(false);
  const [transferSuccess, setTransferSuccess] = React.useState('');
  const [transferError, setTransferError] = React.useState('');

  React.useEffect(() => {
    setUserAccountsLoading(true);

    axios
      .get(API_ENDPOINTS.account, {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((myAccounts: any) => {
        const tradingAccounts = myAccounts?.data?.data?.trading;
        setUserAccountsOptions(
          tradingAccounts.map(
            (account: any) =>
              ({
                value: account?.id,
                label: `#${account?.number} ${account?.accountType.name} | ${account?.balance} ${account?.currency}`,
              } as never)
          )
        );
        setUserAccountsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setUserAccountsError(t<string>('somethingWentWrongWithLoadingTheAccounts'));
        setUserAccountsLoading(false);
      });
  }, []);

  function onTransferFormChange(transferValues: any) {
    setTransferFormValid(
      Boolean(transferValues.fromAccount) &&
        Boolean(transferValues.toAccount) &&
        transferValues.fromAccount !== transferValues.toAccount &&
        Boolean(transferValues.ammount) &&
        transferValues.ammount >= 50 &&
        Boolean(transferValues.agree)
    );
  }

  const onTransferFormSubmit = React.useCallback(() => {
    setIsTransferFormLoading(true);

    axios
      .post(API_ENDPOINTS.transferFunds, transferForm.getFieldsValue(), {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((_: any) => {
        setIsTransferFormLoading(false);
        setTransferSuccess('The transfer went successfully');
      })
      .catch((error) => {
        console.error(error);
        setTransferError(t<string>('somethingWentWrongWithThisTransfer'));
        setIsTransferFormLoading(false);
      });
  }, []);

  return (
    <>
      <section className='transfer-container'>
        <h1>{t<string>('trasnferBetweenAccounts')}</h1>
        <Badge.Ribbon text={<InteractionOutlined style={{ fontSize: 18 }} />}>
          <Card title={t<string>('trasnferBetweenAccountsForm')}>
            <Form
              name='basic'
              form={transferForm}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600, display: 'flex', flexDirection: 'column' }}
              onValuesChange={(_: any, transferValues) => onTransferFormChange(transferValues)}
              autoComplete='off'
              initialValues={{ text: '', type: '' }}
              onFinish={onTransferFormSubmit}
            >
              <Form.Item
                name='fromAccount'
                label={t<string>('fromAccount')}
                rules={[
                  { required: true, message: t<string>('accountIsRequired') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('toAccount') !== value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t<string>('shouldNotBeTheSameAsToAccount')));
                    },
                  }),
                ]}
              >
                <Select style={{ width: '100%' }} options={userAccountsOptions} loading={userAccountsLoading} />
              </Form.Item>
              <Form.Item
                name='toAccount'
                label={t<string>('toAccount')}
                rules={[
                  { required: true, message: t<string>('accountIsRequired') },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('fromAccount') !== value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t<string>('shouldNotBeTheSameAsFromAccount')));
                    },
                  }),
                ]}
              >
                <Select style={{ width: '100%' }} options={userAccountsOptions} loading={userAccountsLoading} />
              </Form.Item>
              <Form.Item
                name='ammount'
                label={t<string>('amount')}
                rules={[
                  { required: true, message: t<string>('amountIsRequired') },
                  () => ({
                    validator(_, value) {
                      return value >= 50
                        ? Promise.resolve()
                        : Promise.reject(`${t<string>('theAmmountShouldBeAtLeast')}: 50$`);
                    },
                  }),
                ]}
              >
                <InputNumber
                  defaultValue={''}
                  style={{ width: '100%' }}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value: any) => value?.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
              <div style={{ display: 'flex' }}>
                <Form.Item name='agree' valuePropName='checked'>
                  <Checkbox style={{ marginRight: 8 }} />
                </Form.Item>
                <div style={{ marginTop: 4 }}>
                  <span className='no-wrap'>{t<string>('agreeOnThe')}</span>
                  <a href='https://my.inzo/assets/files/terms-and-conditions.pdf' target='_blank' className='no-wrap'>
                    {t<string>('termsAndConditions')}
                  </a>
                </div>
              </div>
              <Form.Item wrapperCol={{ offset: 8, span: 32 }}>
                <Button type='primary' htmlType='submit' disabled={!transferFormValid} loading={isTransferFormLoading}>
                  {t<string>('transfer')}
                </Button>
              </Form.Item>
              <Alert
                description={t<string>('transferInfo')}
                type='info'
                showIcon
                style={{ marginBottom: 16, fontSize: 12 }}
              />
            </Form>
          </Card>
        </Badge.Ribbon>
      </section>
      {userAccountsError ? (
        <div className='alert-container'>
          <Alert
            description={userAccountsError}
            type='error'
            showIcon
            closable
            onClose={() => setUserAccountsError('')}
          />
        </div>
      ) : undefined}
      {transferError ? (
        <div className='alert-container'>
          <Alert description={transferError} type='error' showIcon closable onClose={() => setTransferError('')} />
        </div>
      ) : undefined}
      {transferSuccess ? (
        <div className='alert-container'>
          <Alert
            description={transferSuccess}
            type='success'
            showIcon
            closable
            onClose={() => setTransferSuccess('')}
          />
        </div>
      ) : undefined}
    </>
  );
}

export default Transfer;
