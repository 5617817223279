import { Tag } from 'antd';
import { ReactNode } from 'react';
import { initialTheme } from './initial-theme';

export enum DEPOSIT_WAYS {
  binPay = 'bin-pay',
  payeer = 'payeer',
  zainCash = 'zain-cash',
  perfectMoney = 'perfect-money',
  westernUnion = 'western-union',
  bankTransfer = 'bank-transfer',
  fastpay = 'fastpay',
  asiaHawala = 'asia-hawala',
}

export function getDepositMethodName(depositMethod: DEPOSIT_WAYS | null) {
  switch (depositMethod) {
    case DEPOSIT_WAYS.binPay:
      return 'BinPay';
    case DEPOSIT_WAYS.payeer:
      return 'Payeer';
    case DEPOSIT_WAYS.zainCash:
      return 'Zain Cash';
    case DEPOSIT_WAYS.perfectMoney:
      return 'Perfect Money';
    case DEPOSIT_WAYS.westernUnion:
      return 'Western Union';
    case DEPOSIT_WAYS.bankTransfer:
      return 'Bank Transfer';
    case DEPOSIT_WAYS.fastpay:
      return 'Fastpay';
    case DEPOSIT_WAYS.asiaHawala:
      return 'Asia Hawala';
    default:
      return null;
  }
}

export function getDepositDialogTitle(depositMethod: DEPOSIT_WAYS | null, t: (value: string) => void) {
  switch (depositMethod) {
    case DEPOSIT_WAYS.payeer:
      return `${t('sendMoenyTo')}: P1059861408`;
    case DEPOSIT_WAYS.zainCash:
      return `${t('sendMoenyTo')}: 180726`;
    case DEPOSIT_WAYS.perfectMoney:
      return `${t('sendMoenyTo')}: U37888889`;
    case DEPOSIT_WAYS.westernUnion:
      return `${t('sendMoenyTo')} +9647739803543`;
    case DEPOSIT_WAYS.bankTransfer:
      return `${t('sendMoenyTo')} +9647833404823`;
    case DEPOSIT_WAYS.fastpay:
      return `${t('sendMoenyTo')}: 07813322374`;
    case DEPOSIT_WAYS.asiaHawala:
      return `${t('sendMoenyTo')}: 07717754767`;
    default:
      return null;
  }
}
export function getDepositDialogInstractions(depositMethod: DEPOSIT_WAYS | null, t: (value: string) => void) {
  switch (depositMethod) {
    case DEPOSIT_WAYS.payeer:
      return (
        <>
          <h4 style={{ margin: '0 0 4px 0' }}>{t('followTheInstructions') as ReactNode}</h4>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              1
            </Tag>
            <span style={{ marginRight: 6 }}>{t('goToPayeerApp') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              2
            </Tag>
            <span style={{ marginRight: 6 }}>{t('sendTheMoneyToOurWalletAbove') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              3
            </Tag>
            <span style={{ marginRight: 6 }}>{t('clickConfirmTransfer') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              4
            </Tag>
            <span style={{ marginRight: 6 }}>
              {t('takeAScreenshotAndUploadItToTheConfirmTransferField') as ReactNode}
            </span>
          </>
        </>
      );
    case DEPOSIT_WAYS.zainCash:
      return (
        <>
          <h4 style={{ margin: '0 0 4px 0' }}>{t('followTheInstructions') as ReactNode}</h4>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              1
            </Tag>
            <span style={{ marginRight: 6 }}>{t('goToZainCashApplication') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              2
            </Tag>
            <span style={{ marginRight: 6 }}>{t('goToTheWithdrawOption') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              3
            </Tag>
            <span style={{ marginRight: 6 }}>{t('writeOurAgencyNumberAbove') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              4
            </Tag>
            <span style={{ marginRight: 6 }}>{t('clickConfirmTransfer') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              5
            </Tag>
            <span style={{ marginRight: 6 }}>
              {t('takeAScreenshotAndUploadItToTheConfirmTransferField') as ReactNode}
            </span>
          </>
        </>
      );
    case DEPOSIT_WAYS.perfectMoney:
      return (
        <>
          <h4 style={{ margin: '0 0 4px 0' }}>{t('followTheInstructions') as ReactNode}</h4>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              1
            </Tag>
            <span style={{ marginRight: 6 }}>{t('goToPerfectMoneyApplication') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              2
            </Tag>
            <span style={{ marginRight: 6 }}>{t('sendTheMoneyToOurWalletAbove') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              3
            </Tag>
            <span style={{ marginRight: 6 }}>{t('clickConfirmTransfer') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              4
            </Tag>
            <span style={{ marginRight: 6 }}>
              {t('takeAScreenshotAndUploadItToTheConfirmTransferField') as ReactNode}
            </span>
          </>
        </>
      );
    case DEPOSIT_WAYS.westernUnion:
      return (
        <>
          <h4 style={{ margin: '0 0 4px 0' }}>{t('followTheInstructions') as ReactNode}</h4>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              1
            </Tag>
            <span style={{ marginRight: 6 }}>
              {t('callTheNumberAboveToInquireAboutTheNearestWesternUnionCenter') as ReactNode}
            </span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              2
            </Tag>
            <span style={{ marginRight: 6 }}>{t('transferTheFunds') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              3
            </Tag>
            <span style={{ marginRight: 6 }}>
              {t('takeAPictureOfTheTransferReceiptAndAttachItInTheTransferProofField') as ReactNode}
            </span>
          </>
        </>
      );
    case DEPOSIT_WAYS.bankTransfer:
      return (
        <>
          <h4 style={{ margin: '0 0 4px 0' }}>{t('followTheInstructions') as ReactNode}</h4>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              1
            </Tag>
            <span style={{ marginRight: 6 }}>
              {t('callTheNumberAboveToInquireAboutTheNearestMoneyExchangeToTransferMoney') as ReactNode}
            </span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              2
            </Tag>
            <span style={{ marginRight: 6 }}>{t('transferTheFunds') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              3
            </Tag>
            <span style={{ marginRight: 6 }}>
              {t('takeAPictureOfTheTransferReceiptAndAttachItInTheTransferProofField') as ReactNode}
            </span>
          </>
        </>
      );
    case DEPOSIT_WAYS.fastpay:
      return (
        <>
          <h4 style={{ margin: '0 0 4px 0' }}>{t('followTheInstructions') as ReactNode}</h4>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              1
            </Tag>
            <span style={{ marginRight: 6 }}>{t('goToFastPayApp') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              2
            </Tag>
            <span style={{ marginRight: 6 }}>{t('goToTheWithdrawalOption') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              3
            </Tag>
            <span style={{ marginRight: 6 }}>
              {t('typeOurAgencyNumberAboveThenWriteInTheFieldBelowTheAmountYouWantToTransfer') as ReactNode}
            </span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              4
            </Tag>
            <span style={{ marginRight: 6 }}>{t('clickConfirmAndThenEnterYourPassword') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              5
            </Tag>
            <span style={{ marginRight: 6 }}>
              {t('thenClickOnViewInvoiceTakeAScreenshotAndAttachItAsProof') as ReactNode}
            </span>
          </>
        </>
      );
    case DEPOSIT_WAYS.asiaHawala:
      return (
        <>
          <h4 style={{ margin: '0 0 4px 0' }}>{t('followTheInstructions') as ReactNode}</h4>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              1
            </Tag>
            <span style={{ marginRight: 6 }}>{t('goToAsiaHawalaApp') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              2
            </Tag>
            <span style={{ marginRight: 6 }}>{t('goToTransfers') as ReactNode}</span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              3
            </Tag>
            <span style={{ marginRight: 6 }}>
              {t('writeTheNumberAboveThenBelowItTheMoneyYouWantToTransfer') as ReactNode}
            </span>
          </>
          <>
            <Tag color={initialTheme.colorPrimary} style={{ marginBottom: 4 }}>
              4
            </Tag>
            <span style={{ marginRight: 6 }}>
              {t('clickConfirmTransferTakeAScreenshotAndUploadItToTheConfirmTransferField') as ReactNode}
            </span>
          </>
        </>
      );
    default:
      return null;
  }
}

export function getAccountMinimumDeposit(accountId: string): number {
  switch (accountId) {
    case '12b7d060-2515-11ec-aa9d-2175dd60a83e': // MT5 standard
    case '491fe850-cda1-11ed-b152-45cef807a0e7': // cTrader standard
      return 100;
    case 'e00547c0-2ce8-11ec-b4d0-456159ba30b2': // MT5 stocks
    case 'd33706e0-ce14-11ed-b152-45cef807a0e7': // cTrader stocks
      return 500;
    case '18cfc1d0-2856-11ec-9002-55ac8a3dc838': // MT5 zero
    case 'a91e81d0-ce14-11ed-b152-45cef807a0e7': // cTrader zero
      return 5000;
    case '1f584d10-14a6-11ec-b13e-8d950accb896': // MT5 vip
    case 'c1667ae0-ce14-11ed-b152-45cef807a0e7': // cTrader vip
      return 50000;
    case 'ab67ba30-1a49-11ed-8ec7-4f7a54de5c7c': // MT5 mini
      return 50;
    case 'bb7fdf80-8717-11ec-b216-bb25ffef5a9c': // MT5 Contest
      return 1000;
    case 'd48f26b0-0bde-11ed-8c5f-b17f3651b995': // MT5 standard +
      return 100;
    default:
      return 50;
  }
}

export const DEPOSIT_CURENCIES = [
  { label: 'BTC', value: 'btc' },
  { label: 'ETH', value: 'eth' },
  { label: 'DASH', value: 'dash' },
  { label: 'LTC', value: 'ltc' },
  { label: 'XMR', value: 'xmr' },
  { label: 'XRP', value: 'xrp' },
  { label: 'BNB', value: 'bnb' },
  { label: 'BCH', value: 'bch' },
  { label: 'TUSD', value: 'tusd' },
  { label: 'USDT_ETH', value: 'usdt_eth' },
  { label: 'BUSD_BSC', value: 'busd_bsc' },
  { label: 'USDT_TRX', value: 'usdt_trx' },
  { label: 'USDC_BSC', value: 'usdc_bsc' },
  { label: 'USDC_TRX', value: 'usdc_trx' },
];
