import { LanguagePhrases } from "../models/LanguagePhrases";

export const polishPhrases: LanguagePhrases = {
    home: "Dom",
    downloads: "Pliki do pobrania",
    support: "Wsparcie",
    topTraders: "Najlepsi handlowcy",
    myProfile: "Mój profil",
    myDocuments: "Moje dokumenty",
    myAccounts: "Moje konta",
    openAccount: "Otwarte Konto",
    liveAccount: "Konto na żywo",
    demoAccount: "Konto demonstracyjne",
    ibAccount: "Konto IB",
    depositAndWithdraw: "Wpłata i wypłata",
    deposit: "Depozyt",
    withdraw: "Wycofać",
    transfer: "Przenosić",
    trading: "Handlowy",
    tradingHistory: "Historia handlu",
    platformPasswords: "Hasła platformy",
    agentPortal: "Portal agenta",
    promotions: "Promocje",
    clients: "Klienci",
    secondaryAgents: "Agenci drugorzędni",
    myDetails: "Moje szczegóły",
    changePassword: "Zmień hasło",
    myDetailsForm: "Formularz Moje dane",
    changePasswordForm: "Zmień formularz hasła",
    firstName: "Imię",
    surname: "Nazwisko",
    email: "E-mail",
    phone: "Telefon",
    address: "Adres",
    city: "Miasto",
    zip: "Zamek błyskawiczny",
    state: "Państwo",
    country: "Kraj",
    password: "Hasło",
    confirmPassword: "Potwierdź hasło",
    updateDetails: "Zaktualizuj szczegóły",
    updatePassword: "Aktualizować hasło",
    fileType: "Typ pliku",
    status: "Status",
    uploadDate: "Data przesłania",
    preview: "Zapowiedź",
    upload: "Wgrywać",
    uploadDocuments: "Prześlij dokumenty",
    mt5LiveAccounts: "Konta rzeczywiste MT5",
    mt5DemoAccounts: "Konta demonstracyjne MT5",
    cTraderLiveAccounts: "Konta rzeczywiste cTrader",
    cTraderDemoAccounts: "Konta demonstracyjne cTrader",
    youDontHaveAccountsOfThisType: "Nie masz kont tego typu",
    number: "Numer",
    group: "Grupa",
    balance: "Balansować",
    currency: "Waluta",
    leverage: "Wpływ",
    createdAt: "Utworzono o godz",
    openLiveAccount: "Otwórz konto rzeczywiste",
    liveAccountForm: "Formularz konta rzeczywistego",
    platform: "Platforma",
    liveAccountType: "Typ konta rzeczywistego",
    accountLaverage: "Przewalutowanie konta",
    agreeOnThe: "Zgadzam się na",
    termsAndConditions: "Regulamin",
    createNewAccount: "Stwórz nowe konto",
    openDemoAccount: "Otwórz konto demonstracyjne",
    demoAccountForm: "Formularz konta demonstracyjnego",
    demoAccountType: "Typ konta demonstracyjnego",
    openIbAccount: "Otwórz konto IB",
    ibAccountForm: "Formularz konta IB",
    writeYourServicesHere: "Wpisz swoje usługi tutaj",
    depositToYourAccounts: "Wpłać na swoje konta",
    depositInDigitalCurrencies: "Depozyt w walutach cyfrowych",
    depositUsingWallets: "Depozyt za pomocą portfeli",
    cashWithdraw: "Wypłata gotówki",
    withdrawUsingWallets: "Wypłacaj za pomocą portfeli",
    walletsWithdrawal: "Portfele Wypłata",
    newAccountWasCreated: "Utworzono nowe konto",
    somethingWentWrongWithCreatingNewAccount: "Podczas tworzenia nowego konta coś poszło nie tak",
    platformShoudntBeEmpty: "Platforma nie powinna być pusta",
    accountTypeShoudntBeEmpty: "Typ konta nie powinien być pusty",
    accountLeverageShoudntBeEmpty: "Dźwignia konta nie powinna być pusta",
    firstNameFieldShouldNotBeEmpty: "Pole Imię nie powinno być puste",
    surnameFieldShouldNotBeEmpty: "Pole Nazwisko nie powinno być puste",
    emailFieldShouldNotBeEmpty: "Pole e-mail nie powinno być puste",
    pleaseMakeSureTheEmailIsValid: "Upewnij się, że adres e-mail jest prawidłowy",
    accountIsRequired: "Pole konta nie powinno być puste",
    amountIsRequired: "Pole Ammount nie powinno być puste",
    currencyIsRequired: "Pole waluty nie powinno być puste",
    minimumValueIs: "Minimalna wartośćIs",
    sendDeposit: "Wyślij depozyt",
    somethingWentWrongWithTheDeposit: "Coś poszło nie tak z depozytem",
    accountLeverage: "Dźwignia finansowa konta",
    walletIsRequired: "Portfel jest wymagany",
    wallet: "Portfel",
    uploadConfirmation: "Prześlij potwierdzenie",
    somethingWentWrongWithLoadingTheAccounts: "Podczas ładowania kont coś poszło nie tak",
    theDepositWasSentSuccessfully: "Depozyt został wysłany pomyślnie",
    depositUsing: "Wpłać za pomocą",
    desktopWindows: "Windows na pulpicie",
    desktopMacOs: "MacO na komputery stacjonarne",
    mobileIOS: "Mobilny iOS",
    mobileAndroid: "Mobilny Android",
    somethingWentWrongWithFetchingTheCompanyDetails: "Podczas pobierania danych firmy coś poszło nie tak",
    somethingWentWrongWithResetingThePassword: "Podczas resetowania hasła coś poszło nie tak",
    resetEmailWasSent: "Adres URL resetowania został wysłany na Twój adres e-mail. Użyj adresu URL, aby zresetować hasło",
    welcomeTo: "Witamy w",
    clietnPortal: "Portal klienta",
    sendRequest: "Wysłać prośbę",
    goBack: "Wróć",
    somethingWentWrongWithFetchingTheRecentTrades: "Coś poszło nie tak z pobieraniem ostatnich transakcji",
    recentTradingActivity: "Ostatnia aktywność handlowa",
    problemsWithLoadingTradingActivities: "Występują pewne problemy z ładowaniem ostatniej aktywności handlowej",
    servicesFieldShouldNotBeEmpty: "Pole Usługi nie powinno być puste",
    createNewIbAccount: "Utwórz nowe konto IB",
    somethingWentWrongWithLoadingTheTrades: "Podczas ładowania transakcji coś poszło nie tak",
    myTraders: "Moi Traderzy",
    myTradersControlForm: "Formularz kontrolny moich handlowców",
    fromDateIsRequired: "Data od jest wymagana",
    toDateIsRequired: "Do daty jest wymagane",
    fromDate: "Od daty",
    toDate: "Spotykać się z kimś",
    theFromDateShouldBeBeforeToDate: "Data od powinna być przed datą do",
    getMyTraders: "Zdobądź moich handlarzy",
    searchInUsersTable: "Szukaj w tabeli użytkowników",
    somethingWentWrongWithLoadingTheSecondaryAgents: "Coś poszło nie tak podczas ładowania agentów dodatkowych",
    mySecondaryAgents: "Moi drugorzędni agenci",
    mySecondaryAgentsControlForm: "Formularz kontrolny moich drugorzędnych agentów",
    getMySecondaryAgents: "Ściągnijcie moich drugorzędnych agentów",
    accountTypeShouldNotBeEmpty: "Typ konta nie powinien być pusty",
    accountLeverageShouldNotBeEmpty: "Dźwignia konta nie powinna być pusta",
    thereIsProblemWithLoadingListCompetition: "Występują problemy z ładowaniem listy konkurencji",
    ibAccounts: "Konta IB",
    youDoNotHaveAccountsOfThisType: "Nie masz kont tego typu",
    somethingWentWrongWithLoadingTheDocuments: "Podczas ładowania dokumentów coś poszło nie tak",
    takeLivePictureOfTheDocument: "Zrób zdjęcie dokumentu na żywo",
    somethingWentWrongWithLoadingPrfileData: "Podczas ładowania danych profilu coś poszło nie tak",
    yourProfileDetailsGotUpdated: "Dane Twojego profilu zostały zaktualizowane",
    somethingWentWrongWithUpdatingPrfileData: "Podczas aktualizowania danych profilu coś poszło nie tak",
    phoneFieldShouldNotBeEmpty: "Pole telefonu nie powinno być puste",
    pleaseMakeSureThePhoneNumberIsValid: "Upewnij się, że numer telefonu jest prawidłowy",
    addressFieldShouldNotBeEmpty: "Pole adresowe nie powinno być puste",
    cityFieldShouldNotBeEmpty: "Pole Miasto nie powinno być puste",
    zipFieldShouldNotBeEmpty: "Pole Zip nie powinno być puste",
    stateFieldShouldNotBeEmpty: "Pole stanu nie powinno być puste",
    countryFieldShouldNotBeEmpty: "Pole kraju nie powinno być puste",
    pleaseMakeSurePasswordIsValid: "Upewnij się, że hasło zawiera co najmniej jedną wielką literę, małą literę, znak specjalny, a długość hasła powinna wynosić co najmniej 8",
    twoPasswordsDoNotMatch: "Oba hasła muszą być takie same",
    pageNotFound: "Strona nie znaleziona",
    makeSureYouUseValidPages: "Upewnij się, że używasz tylko prawidłowych stron",
    GoToHomePage: "Idź do domu",
    somethingWentWrongWithUpdatingPasswordForThisAccount: "Podczas aktualizowania hasła do tego konta coś poszło nie tak",
    passwordType: "Typ hasła",
    passwordTypeIsRequired: "Typ hasła jest wymagany",
    passwordIsRequired: "Wymagane jest hasło",
    confirmPasswordIsRequired: "Potwierdź hasło jest wymagane",
    clickToCopy: "Kliknij, aby skopiować",
    myPromotionalMaterials: "Moje materiały promocyjne",
    myPromotionalMaterialsControlForm: "Mój formularz kontroli materiałów promocyjnych",
    account: "Konto",
    myReferalLink: "Mój link polecający",
    myTestReferalLink: "Mój link referencyjny do wersji demonstracyjnej",
    redirectToSignInPage: "Przekieruj do strony logowania",
    resetPassword: "Zresetuj hasło",
    somethingWentWrongWithSigningIn: "Podczas logowania coś poszło nie tak",
    signIn: "Zalogować się",
    forgotPassword: "Zapomniałeś hasła",
    somethingWentWrongWithSendingYourMessage: "Podczas wysyłania pytania coś poszło nie tak",
    contactOurSupport: "Skontaktuj się z naszym wsparciem",
    questionCategory: "Kategoria pytania",
    questionCategoryShouldNotBeEmpty: "Kategoria pytań nie powinna być pusta",
    changeTheAgentOfYourAccount: "Zmień agenta swojego konta",
    changeThePasswordForYourTradingAccount: "Zmień hasło do swojego konta handlowego",
    changeTheMarginOfYourAccount: "Zmień marżę swojego konta",
    writeYourQuestionHere: "Wpisz swoje pytanie tutaj",
    questionFieldShouldNotBeEmpty: "Pole pytania nie powinno być puste",
    sendQuestion: "Wyślij pytanie",
    yourQuestionSentSuccessfully: "Twoje pytanie zostało pomyślnie wysłane",
    somethingWentWrongWithLoadingTheListOfTopTraders: "Coś poszło nie tak podczas ładowania listy najlepszych traderów",
    somethingWentWrongWithLoadingTheListOfRecentTrades: "Coś poszło nie tak podczas ładowania listy ostatnich transakcji",
    tradingHistoryControlForm: "Formularz kontroli historii transakcji",
    somethingWentWrongWithThisTransfer: "Coś poszło nie tak z tym transferem",
    trasnferBetweenAccounts: "Przelew między kontami",
    trasnferBetweenAccountsForm: "Formularz przelewu między kontami",
    fromAccount: "Z konta",
    toAccount: "Do konta",
    shouldNotBeTheSameAsToAccount: "Nie powinno być tożsame z polem Do konta",
    shouldNotBeTheSameAsFromAccount: "Nie powinno być takie samo jak Z konta",
    theAmmountShouldBeAtLeast: "Kwota powinna wynosić min",
    transferInfo: "Upewnij się, że masz wystarczającą ilość wolnego depozytu zabezpieczającego, aby utrzymać wszelkie otwarte pozycje. Nie ponosimy odpowiedzialności, jeśli poziom depozytu zabezpieczającego spadnie poniżej poziomu minimalnego. Może to spowodować automatyczne zamknięcie wszystkich otwartych pozycji.",
    withdrawMoeny: "Wycofaj Moeny'ego",
    andMaximumValueIs: "a maksymalna wartość to",
    withdrawMethod: "Metoda wypłaty",
    methodIsRequired: "Wymagana jest metoda",
    leaveNote: "Zostaw notatke",
    somethingWentWrongWithLoadingTheWeekDay: "Podczas wczytywania dnia tygodnia coś poszło nie tak",
    pleaseBeAwareThatYouCantWithdrawMoenyOnSaturday: "Pamiętaj, że nie możesz wypłacić pieniędzy w sobotę",
    walletWithdawal: "Wycofanie portfela",
    somethingWentWrongWithTheWithdraw: "Coś poszło nie tak z wycofaniem",
    somethingWentWrongWithLoadingTheUserDetails: "Podczas ładowania danych użytkownika coś poszło nie tak",
    yourAccountIsInactivePleaseContactYourAdminToActivateYourIt: "Twoje konto jest nieaktywne. Skontaktuj się z administratorem, aby go aktywować",
    footerContent: "Ostrzeżenie o ryzyku: Forex i kontrakty CFD są produktami lewarowanymi, wiążą się z wysokim poziomem ryzyka i mogą nie być odpowiednie dla wszystkich inwestorów. Nie powinieneś ryzykować więcej, niż jesteś gotów stracić. Przed podjęciem decyzji o handlu upewnij się, że rozumiesz związane z tym ryzyko i weź pod uwagę swój poziom doświadczenia. W razie potrzeby zasięgnij niezależnej porady.",
    allRightsReserved: "Wszelkie prawa zastrzeżone.",
    areYouSureYouWantToLogOut: "Czy na pewno chcesz się wylogować?",
    yes: "Tak",
    no: "NIE",
    profit: "Zysk",
    name: "Imię",
    time: "Czas",
    deal: "Umowa",
    order: "Zamówienie",
    position: "Pozycja",
    symbol: "Symbol",
    type: "Typ",
    access: "Dostęp",
    volume: "Tom",
    price: "Cena",
    reason: "Powód",
    commission: "Zamawiać",
    swap: "Zamieniać",
    comment: "Komentarz",
    idFront: "Przód identyfikatora",
    idBack: "Identyfikator z tyłu",
    addressFront: "Adres z przodu",
    addressBack: "Adres z tyłu",
    amount: "Kwota",
    getTrades: "Zdobądź transakcje",
    sendMoenyTo: "Wyślij pieniądze do",
    followTheInstructions: "Postępuj zgodnie z instrukcją",
    goToPayeerApp: "Przejdź do aplikacji Payeer",
    sendTheMoneyToOurWalletAbove: "Wyślij pieniądze do naszego portfela powyżej",
    clickConfirmTransfer: "Kliknij Potwierdź transfer",
    takeAScreenshotAndUploadItToTheConfirmTransferField: "Zrób zrzut ekranu i prześlij go w polu Potwierdź transfer",
    goToZainCashApplication: "Przejdź do aplikacji Zain Cash",
    goToTheWithdrawOption: "Przejdź do opcji wypłaty",
    writeOurAgencyNumberAbove: "Wpisz numer naszej agencji powyżej",
    goToPerfectMoneyApplication: "Przejdź do aplikacji Perfect Money",
    callTheNumberAboveToInquireAboutTheNearestWesternUnionCenter: "Zadzwoń pod powyższy numer, aby zapytać o najbliższe centrum Western Union",
    transferTheFunds: "Przelej środki",
    callTheNumberAboveToInquireAboutTheNearestMoneyExchangeToTransferMoney: "Zadzwoń pod powyższy numer, aby zapytać o najbliższy kantor, w którym można przelać pieniądze",
    takeAPictureOfTheTransferReceiptAndAttachItInTheTransferProofField: "Zrób zdjęcie potwierdzenia przelewu i dołącz je w polu Dowód przelewu",
    goToFastPayApp: "Przejdź do aplikacji FastPay",
    goToTheWithdrawalOption: "Przejdź do opcji wypłaty",
    typeOurAgencyNumberAboveThenWriteInTheFieldBelowTheAmountYouWantToTransfer: "Wpisz numer naszej agencji powyżej, a następnie w polu poniżej wpisz kwotę, którą chcesz przelać",
    clickConfirmAndThenEnterYourPassword: "Kliknij Potwierdź, a następnie wprowadź hasło",
    thenClickOnViewInvoiceTakeAScreenshotAndAttachItAsProof: "Następnie kliknij Wyświetl fakturę, zrób zrzut ekranu i dołącz go jako dowód",
    goToAsiaHawalaApp: "Przejdź do aplikacji Asia Hawala",
    goToTransfers: "Przejdź do transferów",
    writeTheNumberAboveThenBelowItTheMoneyYouWantToTransfer: "Wpisz numer powyżej, a pod nim pieniądze, które chcesz przelać",
    clickConfirmTransferTakeAScreenshotAndUploadItToTheConfirmTransferField: "Kliknij Potwierdź przelew, zrób zrzut ekranu i prześlij go do pola Potwierdź przelew",
    cryptoDeposit: "Depozyt kryptograficzny",
    processOrder: "Zamówienie procesowe",
    ERC20PleaseNoteThatWeDoNotReceiveFundsUsingTheBEP20Method: "Zlecenie przetwarzania ERC20 Należy pamiętać, że nie otrzymujemy środków metodą (BEP20).",
    newPassword: "Nowe hasło",
    currentPassword: "aktualne hasło",
    copy: "Kopiuj",
    total: "całkowity",
}