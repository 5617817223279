import { LockOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Spin } from 'antd';
import React from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { EMAIL_VALIDATION, PASSWORD_VALIDATION } from '../../assets/comfig/validation-regex';
import Languages from '../../components/Languages/Languages';
import { deleteUserTokenFromCookie, setUserTokenInCookie } from '../../helpers/cookiesHandler';
import { SignInValues } from '../../models/SignIn';
import { useMainContext } from '../../store/MainContext';
import { companyDetailsMock } from '../../mocks/companyDetailsMock';
import './SignIn.scss';

function SignIn() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mainState, setMainState } = useMainContext();
  const [signInError, setSignInError] = React.useState('');
  const [loadingCompanyDetails, setLoadingCompanyDetails] = React.useState(false);
  const [errorCompanyDetails, setErrorCompanyDetails] = React.useState('');
  const companyDetails = React.useMemo(
    () => ({
      companyName: companyDetailsMock.companyName,
      companyLogo: companyDetailsMock.companyLogo,
      backgroundImage: companyDetailsMock.backgroundImage,
    }),
    []
  );
  const [signInFormLoading, setSignInFormLoading] = React.useState(false);
  const [signInFormDisabled, setSignInFormDisabled] = React.useState(true);

  React.useEffect(() => {
    deleteUserTokenFromCookie();
    setMainState({ ...mainState, isLoggedIn: false });
  }, []);

  const onSubmit = React.useCallback((signInValues: SignInValues) => {
    setSignInFormLoading(true);

    axios
      .all([axios.post(API_ENDPOINTS.loginToken, signInValues), axios.post(API_ENDPOINTS.signIn, signInValues)])
      .then(([tokenInfo, userSignInInfo]) => {
        const userData = userSignInInfo.data.data.userInfo;
        setUserTokenInCookie(tokenInfo.data.token);
        window.localStorage.setItem('userInfo', JSON.stringify(userData));
        setMainState({ ...mainState, isLoggedIn: true });
        setSignInFormLoading(false);

        userData?.kycApproved ? navigate('/home') : navigate('/my-documents');
      })
      .catch((error) => {
        console.error(error);
        setSignInFormLoading(false);
        setSignInError(t<string>('somethingWentWrongWithSigningIn'));
      });
  }, []);
  const onFormChange = (_: any, signInValues: SignInValues) => {
    setSignInFormDisabled(
      !EMAIL_VALIDATION.test(signInValues?.email) || !PASSWORD_VALIDATION.test(signInValues?.password)
    );
  };

  return (
    <>
      <section className='signin-section' style={{ backgroundImage: `url(${companyDetails.backgroundImage})` }}>
        <div className='form-container'>
          <div className='signin-header'>
            {loadingCompanyDetails ? (
              <Spin size='large' />
            ) : (
              <div className='company-details-container'>
                <img className='company-logo' src={companyDetails.companyLogo} alt='Company Logo' />
                <p>
                  {t<string>('welcomeTo')} {companyDetails.companyName} {t<string>('clietnPortal')}
                </p>
              </div>
            )}
          </div>
          <Form
            name='basic'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onSubmit}
            onValuesChange={onFormChange}
            autoComplete='off'
          >
            <Form.Item
              label={t<string>('email')}
              name='email'
              rules={[
                { required: true, message: t<string>('emailFieldShouldNotBeEmpty') },
                { pattern: EMAIL_VALIDATION, message: t<string>('pleaseMakeSureTheEmailIsValid') },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t<string>('password')}
              name='password'
              rules={[
                { required: true, message: t<string>('passwordIsRequired') },
                {
                  pattern: PASSWORD_VALIDATION,
                  message: t<string>('pleaseMakeSurePasswordIsValid'),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 32 }}>
              <Button type='primary' htmlType='submit' disabled={signInFormDisabled} loading={signInFormLoading}>
                {t<string>('signIn')}
              </Button>
            </Form.Item>
            <NavLink to='/forgot-password' className='link'>
              {t<string>('forgotPassword')} <LockOutlined />
            </NavLink>
            <div className='lang-container'>
              <Languages />
            </div>
          </Form>
        </div>
      </section>
      {signInError ? (
        <div className='alert-container'>
          <Alert description={signInError} type='error' showIcon closable onClose={() => setSignInError('')} />
        </div>
      ) : undefined}
      {errorCompanyDetails ? (
        <div className='alert-container'>
          <Alert
            description={errorCompanyDetails}
            type='error'
            showIcon
            closable
            onClose={() => setErrorCompanyDetails('')}
          />
        </div>
      ) : undefined}
    </>
  );
}

export default SignIn;
