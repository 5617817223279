import { ArrowLeftOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { EMAIL_VALIDATION } from '../../assets/comfig/validation-regex';
import Languages from '../../components/Languages/Languages';
import { companyDetailsMock } from '../../mocks/companyDetailsMock';
import axios from 'axios';
import './ForgotPassword.scss';

function ForgotPassword() {
  const { t } = useTranslation();
  const [forgotPasswordError, setForgotPasswordError] = React.useState('');
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = React.useState('');
  const [errorCompanyDetails, setErrorCompanyDetails] = React.useState('');
  const companyDetails = React.useMemo(
    () => ({
      companyName: companyDetailsMock.companyName,
      companyLogo: companyDetailsMock.companyLogo,
      backgroundImage: companyDetailsMock.backgroundImage,
    }),
    []
  );
  const [forgotPasswordFormDLoading, setForgotPasswordFormDLoading] = React.useState(false);
  const [forgotPasswordFormDisabled, setForgotPasswordFormDisabled] = React.useState(true);

  const onSubmit = React.useCallback((forgotPasswordValues: { email: string }) => {
    setForgotPasswordFormDLoading(true);

    axios
      .post(API_ENDPOINTS.forgotPassword, forgotPasswordValues)
      .then(() => {
        setForgotPasswordFormDLoading(false);
        setForgotPasswordSuccess(t<string>('resetEmailWasSent'));
      })
      .catch((error) => {
        console.error(error);
        setForgotPasswordFormDLoading(false);
        setForgotPasswordError(t<string>('somethingWentWrongWithResetingThePassword'));
      });
  }, []);
  const onFormChange = (_: any, forgotPasswordValues: { email: string }) => {
    setForgotPasswordFormDisabled(!EMAIL_VALIDATION.test(forgotPasswordValues?.email));
  };

  return (
    <>
      <section
        className='forgot-password-section'
        style={{ backgroundImage: `url(${companyDetails.backgroundImage})` }}
      >
        <div className='form-container'>
          <div className='forgot-password-header'>
            <div className='company-details-container'>
              <img className='company-logo' src={companyDetails.companyLogo} alt='Company Logo' />
              <p>
                {t<string>('welcomeTo')} {companyDetails.companyName} {t<string>('clietnPortal')}
              </p>
            </div>
          </div>
          <Form
            name='basic'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onSubmit}
            onValuesChange={onFormChange}
            autoComplete='off'
          >
            <Form.Item
              label={t<string>('email')}
              name='email'
              rules={[
                { required: true, message: t<string>('emailFieldShouldNotBeEmpty') },
                { pattern: EMAIL_VALIDATION, message: t<string>('pleaseMakeSureTheEmailIsValid') },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 32 }}>
              <Button
                type='primary'
                htmlType='submit'
                disabled={forgotPasswordFormDisabled}
                loading={forgotPasswordFormDLoading}
              >
                {t<string>('sendRequest')}
              </Button>
            </Form.Item>
            <NavLink to='/sign-in' className='link'>
              <ArrowLeftOutlined />
              {t<string>('goBack')}
            </NavLink>
            <div className='lang-container'>
              <Languages />
            </div>
          </Form>
        </div>
      </section>
      {forgotPasswordSuccess ? (
        <div className='alert-container'>
          <Alert
            description={forgotPasswordSuccess}
            type='success'
            showIcon
            closable
            onClose={() => setForgotPasswordSuccess('')}
          />
        </div>
      ) : undefined}
      {forgotPasswordError ? (
        <div className='alert-container'>
          <Alert
            description={forgotPasswordError}
            type='error'
            showIcon
            closable
            onClose={() => setForgotPasswordError('')}
          />
        </div>
      ) : undefined}
      {errorCompanyDetails ? (
        <div className='alert-container'>
          <Alert
            description={errorCompanyDetails}
            type='error'
            showIcon
            closable
            onClose={() => setErrorCompanyDetails('')}
          />
        </div>
      ) : undefined}
    </>
  );
}

export default ForgotPassword;
