import { Alert, Badge, Button, Card, Form, Result, Select, Table } from 'antd';
import generatePicker from 'antd/es/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import React from 'react';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { getTradesColumns } from '../../assets/comfig/trades-columns';
import { TradeInfo } from '../../models/TradeInfo';
import { dateFormat } from '../../assets/comfig/dateFormat';
import { SlidersOutlined } from '@ant-design/icons';
import { dateToSecondsConverter } from '../../helpers/dateToSecondsConverter';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import './TradingHistory.scss';

const ONE_WEEK_AGO_MS = 604800000;

function TradingHistory() {
  const { t } = useTranslation();
  const [tradingHistoryForm] = Form.useForm();
  const [userAccountsOptions, setUserAccountsOptions] = React.useState([]);
  const [userAccountsLoading, setUserAccountsLoading] = React.useState(false);
  const [userAccountsError, setUserAccountsError] = React.useState('');
  const [trades, setTrades] = React.useState<TradeInfo[] | null>(null);
  const [tradesError, setTradesError] = React.useState('');
  const [tradesLoading, setTradesLoading] = React.useState(false);
  const [tradesFormDisabled, setTradesFormDisabled] = React.useState(true);
  const DatePicker = generatePicker(dateFnsGenerateConfig);
  const todayDate = React.useMemo(() => new Date(), []);

  React.useEffect(() => {
    setUserAccountsLoading(true);

    axios
      .get(API_ENDPOINTS.account, {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((myAccounts: any) => {
        const tradingAccounts = myAccounts?.data?.data?.trading;

        const allAccounts = [
          ...tradingAccounts?.demo,
          ...tradingAccounts?.ib,
          ...tradingAccounts?.investor,
          ...tradingAccounts?.mam,
          ...tradingAccounts?.trading,
          ...tradingAccounts?.wallet,
        ];

        setUserAccountsOptions(
          allAccounts.map(
            (account: any) =>
              ({
                value: account?.id,
                label: `#${account?.number} ${account?.accountType.name} | ${account?.balance} ${account?.currency}`,
              } as never)
          )
        );
        setUserAccountsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setUserAccountsError(t<string>('somethingWentWrongWithLoadingTheAccounts'));
        setUserAccountsLoading(false);
      });
  }, []);

  const onGetTradingHistoryFormSubmit = React.useCallback(() => {
    setTradesLoading(true);
    const tradingHistoryFormValues = tradingHistoryForm.getFieldsValue();

    axios
      .get(
        API_ENDPOINTS.tradingHistory
          .replace('%accountId%', tradingHistoryFormValues.accountId)
          .replace('%from%', dateToSecondsConverter(tradingHistoryFormValues.fromDate))
          .replace('%to%', dateToSecondsConverter(tradingHistoryFormValues.toDate)),
        {
          headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
        }
      )
      .then((tradingHistoryData: any) => {
        const tradingHistory: TradeInfo[] = tradingHistoryData.data.data;

        setTrades(tradingHistory.map((trade: TradeInfo, index: number) => ({ ...trade, key: index })));
        setTradesLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setTradesError(t<string>('somethingWentWrongWithLoadingTheListOfRecentTrades'));
      });
  }, []);

  const onTradeHistoryFormChange = () => {
    const tradingHistoryFormValues = tradingHistoryForm.getFieldsValue();

    const fromDateSec = tradingHistoryFormValues.fromDate?.getTime()
      ? Math.floor(tradingHistoryFormValues.fromDate?.getTime() / 1000)
      : 0;
    const toDateSec = tradingHistoryFormValues.toDate?.getTime()
      ? Math.floor(tradingHistoryFormValues.toDate?.getTime() / 1000)
      : 0;

    setTradesFormDisabled(!tradingHistoryFormValues.accountId || !fromDateSec || !toDateSec || toDateSec < fromDateSec);
  };

  const tradesColumns = getTradesColumns(t);

  return (
    <section className='my-trades-container'>
      <h1>{t<string>('recentTradingActivity')}</h1>
      <Badge.Ribbon text={<SlidersOutlined style={{ fontSize: 18 }} />}>
        <Card title={t<string>('tradingHistoryControlForm')}>
          <Form
            name='basic'
            form={tradingHistoryForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}
            onValuesChange={onTradeHistoryFormChange}
            autoComplete='off'
            disabled={userAccountsLoading}
            onFinish={onGetTradingHistoryFormSubmit}
            initialValues={{
              accountId: null,
              fromDate: new Date(todayDate.getTime() - ONE_WEEK_AGO_MS),
              toDate: todayDate,
            }}
          >
            <div className='trade-history-content'>
              <Form.Item
                name='accountId'
                label={t<string>('account')}
                rules={[{ required: true, message: t<string>('accountIsRequired') }]}
              >
                <Select style={{ width: '100%' }} options={userAccountsOptions} loading={userAccountsLoading} />
              </Form.Item>
              <div className='date-pickers'>
                <Form.Item
                  name='fromDate'
                  label={t<string>('fromDate')}
                  rules={[
                    { required: true, message: t<string>('fromDateIsRequired') },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const doDateMs = getFieldValue('toDate')?.getTime();
                        if (!doDateMs || doDateMs >= value.getTime()) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t<string>('theFromDateShouldBeBeforeToDate')));
                      },
                    }),
                  ]}
                >
                  <DatePicker format={dateFormat} />
                </Form.Item>
                <Form.Item
                  label={t<string>('toDate')}
                  name='toDate'
                  rules={[
                    { required: true, message: t<string>('toDateIsRequired') },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const fromDateMs = getFieldValue('fromDate')?.getTime();
                        if (!fromDateMs || fromDateMs < value.getTime()) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t<string>('theFromDateShouldBeBeforeToDate')));
                      },
                    }),
                  ]}
                >
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
            <Form.Item wrapperCol={{ offset: 8, span: 32 }}>
              <Button type='primary' htmlType='submit' disabled={tradesFormDisabled} loading={tradesLoading}>
                {t<string>('getTrades')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Badge.Ribbon>

      <div className='my-trades-table-container'>
        {tradesError ? (
          <Result status='warning' title={t<string>('problemsWithLoadingTradingActivities')} />
        ) : tradesLoading || trades ? (
          <>
            {trades!?.length > 0 ? (
              <h2>
                {t<string>('total')}: {trades?.length}
              </h2>
            ) : null}
            <Table
              columns={tradesColumns as any}
              dataSource={trades || []}
              bordered
              loading={tradesLoading}
              pagination={{ pageSize: 15 }}
            />
          </>
        ) : null}
      </div>
      {userAccountsError ? (
        <div className='alert-container'>
          <Alert
            description={userAccountsError}
            type='success'
            showIcon
            closable
            onClose={() => setUserAccountsError('')}
          />
        </div>
      ) : undefined}
    </section>
  );
}

export default TradingHistory;
