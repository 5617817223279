import { Button, Checkbox, Form, Input, InputNumber, Select } from 'antd';
import React from 'react';
import { getAccountMinimumDeposit } from '../../../assets/comfig/deposit-ways';
import { API_ENDPOINTS } from '../../../assets/api/endpoints';
import { WITHDRAW_METHODS } from '../../../assets/comfig/withdraw-config';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getUserTokenInCookie } from '../../../helpers/cookiesHandler';
import './WithdrawModal.scss';

function WithdawModal({
  selectedMethod,
  userAccounts,
  userAccountsOptions,
  closeModelSuccessHandler,
  errorHandler,
}: {
  selectedMethod: { label: string; value: string };
  userAccounts: any[];
  userAccountsOptions: { labebl: string; value: string }[];
  closeModelSuccessHandler: () => void;
  errorHandler: (error: string) => void;
}) {
  const { t } = useTranslation();
  const [withdawForm] = Form.useForm();
  const [accountMinimumWithdaw, setAccountMinimumWithdaw] = React.useState<number | string>('');
  const [accountMaximumWithdaw, setAccountMaximumWithdaw] = React.useState<number | string>('');
  const [withdawFormDisabled, setWithdawFormDisabled] = React.useState(true);
  const [withdawFormLoading, setWithdawFormLoading] = React.useState(false);

  const onAccountChange = () => {
    const withdawFormValues = withdawForm.getFieldsValue();

    const minWithdraw = getAccountMinimumDeposit(withdawFormValues.fromAccount || '');
    const maxWithdraw =
      userAccounts.find((account: any) => account.id === withdawFormValues.fromAccount)?.balance || 100;
    setAccountMinimumWithdaw(minWithdraw);
    setAccountMaximumWithdaw(maxWithdraw);
  };

  const onWithdawFormChange = () => {
    const withdawFormValues = withdawForm.getFieldsValue();

    setWithdawFormDisabled(
      !withdawFormValues.fromAccount ||
        withdawFormValues?.ammount < accountMinimumWithdaw ||
        withdawFormValues?.ammount > accountMaximumWithdaw ||
        !withdawFormValues.wallet ||
        !withdawFormValues.agree
    );
  };

  const onWithdawFormSubmit = React.useCallback(() => {
    const formValues = withdawForm.getFieldsValue();
    setWithdawFormLoading(true);

    axios
      .post(
        API_ENDPOINTS.withdraw,
        {
          fromAccount: formValues.fromAccount,
          amount: formValues.amount,
          wallet: formValues.wallet,
          agree: formValues.agree,
          withPayout: false,
          note: formValues.note || '',
          walletMethod: WITHDRAW_METHODS.find((method) => method.value === formValues.walletMethodId)?.label || '',
          walletMethodId: selectedMethod.value,
        },
        { headers: { Authorization: `Bearer ${getUserTokenInCookie()}` } }
      )
      .then(() => {
        setWithdawFormLoading(false);
        closeModelSuccessHandler();
      })
      .catch((error) => {
        console.error(error);
        errorHandler(t<string>('somethingWentWrongWithTheWithdraw'));
        setWithdawFormLoading(false);
      });
  }, []);

  return (
    <section className='withdraw-container'>
      <h3 className='dialog-title'>{`${t<string>('withdrawMoeny')} (${selectedMethod.label})`}</h3>
      <Form
        name='basic'
        form={withdawForm}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}
        onValuesChange={() => onWithdawFormChange()}
        autoComplete='off'
        onFinish={onWithdawFormSubmit}
      >
        <Form.Item
          name='fromAccount'
          label={t<string>('account')}
          rules={[{ required: true, message: t<string>('accountIsRequired') }]}
        >
          <Select style={{ width: '100%' }} options={userAccountsOptions} onChange={onAccountChange} />
        </Form.Item>
        <Form.Item
          name='amount'
          label={t<string>('amount')}
          rules={[
            { required: true, message: t<string>('amountIsRequired') },
            () => ({
              validator(_, value) {
                return value >= accountMinimumWithdaw && value <= accountMaximumWithdaw
                  ? Promise.resolve()
                  : Promise.reject(
                      `${t<string>('minimumValueIs')}: ${accountMinimumWithdaw} ${t<string>(
                        'andMaximumValueIs'
                      )}: ${accountMaximumWithdaw}`
                    );
              },
            }),
          ]}
        >
          <InputNumber
            defaultValue={accountMinimumWithdaw}
            style={{ width: '100%' }}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => value?.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item
          name='wallet'
          label={t<string>('wallet')}
          rules={[{ required: true, message: t<string>('walletIsRequired') }]}
        >
          <InputNumber defaultValue={''} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label={t<string>('leaveNote')} name='note'>
          <Input.TextArea />
        </Form.Item>
        <div style={{ display: 'flex' }}>
          <Form.Item name='agree' valuePropName='checked'>
            <Checkbox style={{ marginRight: 8 }} />
          </Form.Item>
          <div style={{ marginTop: 4 }}>
            <span className='no-wrap'>{t<string>('agreeOnThe')}</span>
            <a href='https://my.inzo/assets/files/terms-and-conditions.pdf' target='_blank' className='no-wrap'>
              {t<string>('termsAndConditions')}
            </a>
          </div>
        </div>
        <Button type='primary' htmlType='submit' disabled={withdawFormDisabled} loading={withdawFormLoading}>
          {t<string>('withdraw')}
        </Button>
      </Form>
    </section>
  );
}

export default WithdawModal;
