import { AreaChartOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Card, Checkbox, Form, Select } from 'antd';
import React from 'react';
import { AccountValues } from '../../models/AccountValues';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { demoCTraderAccountsTypes, demoMT5AccountsTypes } from '../../assets/comfig/accounts-types';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import './DemoAccount.scss';

function DemoAccount() {
  const { t } = useTranslation();
  const [demoAccountForm] = Form.useForm();
  const [demoAccountOptions, setDemoAccountOptions] = React.useState([]);
  const [demoAccountFormValid, setDemoAccountFormValid] = React.useState(false);
  const [isDemoAccountFormLoading, setIsDemoAccountFormLoading] = React.useState(false);
  const [demoAccountSuccess, setDemoAccountSuccess] = React.useState('');
  const [demoAccountError, setDemoAccountError] = React.useState('');
  const [accountMargins, setAccountMargins] = React.useState<number[]>([]);

  const allDemoAccounts = React.useMemo(
    () => [...Object.values(demoMT5AccountsTypes), ...Object.values(demoCTraderAccountsTypes)],
    []
  );

  function onDemoAccountFormChange(demoAccountValues: AccountValues) {
    setDemoAccountFormValid(
      Boolean(demoAccountValues.accountTypeId) &&
        Boolean(demoAccountValues.leverage) &&
        Boolean(demoAccountValues.agree)
    );
  }

  const onDemoAccountFormSubmit = React.useCallback(() => {
    setIsDemoAccountFormLoading(true);
    axios
      .post(API_ENDPOINTS.demoAccount, demoAccountForm.getFieldsValue(), {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((_: any) => {
        setIsDemoAccountFormLoading(false);
        setDemoAccountSuccess(t<string>('newAccountWasCreated'));
      })
      .catch((error) => {
        error.log(error);
        setDemoAccountError(t<string>('somethingWentWrongWithCreatingNewAccount'));
        setIsDemoAccountFormLoading(false);
      });
  }, []);

  const onPlatformChange = (platformId: string) => {
    if (platformId === 'MT5') {
      setDemoAccountOptions(
        (Object.values(demoMT5AccountsTypes) as any).map((account: any) => ({
          label: account.label,
          value: account.id,
        })) || []
      );
    }
    if (platformId === 'cTrader') {
      setDemoAccountOptions(
        (Object.values(demoCTraderAccountsTypes) as any).map((account: any) => ({
          label: account.label,
          value: account.id,
        })) || []
      );
    }
    demoAccountForm.setFieldValue('leverage', null);
    demoAccountForm.setFieldValue('accountTypeId', '');
    onDemoAccountFormChange(demoAccountForm.getFieldsValue());
    setAccountMargins([]);
  };
  const onAccountTypeChange = (accountTypeId: string) => {
    setAccountMargins(allDemoAccounts.find((account) => account.id === accountTypeId)?.values || []);
    demoAccountForm.setFieldValue('leverage', null);
    onDemoAccountFormChange(demoAccountForm.getFieldsValue());
  };

  return (
    <>
      <section className='demoAccount-container'>
        <h1>{t<string>('openDemoAccount')}</h1>
        <Badge.Ribbon text={<AreaChartOutlined style={{ fontSize: 18 }} />}>
          <Card title={t<string>('demoAccountForm')}>
            <Form
              name='basic'
              form={demoAccountForm}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600, display: 'flex', flexDirection: 'column' }}
              onValuesChange={(_: any, demoAccountValues: AccountValues) => onDemoAccountFormChange(demoAccountValues)}
              autoComplete='off'
              initialValues={{ text: '', type: '' }}
              onFinish={onDemoAccountFormSubmit}
            >
              <Form.Item
                name='platform'
                label={t<string>('platform')}
                rules={[{ required: true, message: t<string>('platformShoudntBeEmpty') }]}
              >
                <Select
                  onChange={onPlatformChange}
                  style={{ width: '100%' }}
                  options={[
                    { label: 'MT5', value: 'MT5' },
                    { label: 'cTrader', value: 'cTrader' },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name='accountTypeId'
                label={t<string>('demoAccountType')}
                rules={[{ required: true, message: t<string>('accountTypeShoudntBeEmpty') }]}
              >
                <Select onChange={onAccountTypeChange} style={{ width: '100%' }} options={demoAccountOptions} />
              </Form.Item>
              <Form.Item
                name='leverage'
                label={t<string>('accountLeverage')}
                rules={[{ required: true, message: t<string>('accountLeverageShoudntBeEmpty') }]}
              >
                <Select
                  style={{ width: '100%' }}
                  options={accountMargins.map((margin) => ({ label: `1:${margin}`, value: margin }))}
                />
              </Form.Item>
              <div style={{ display: 'flex' }}>
                <Form.Item name='agree' valuePropName='checked'>
                  <Checkbox style={{ marginRight: 8 }} />
                </Form.Item>
                <div style={{ marginTop: 4 }}>
                  <span className='no-wrap'>{t<string>('agreeOnThe')}</span>
                  <a href='https://my.inzo/assets/files/terms-and-conditions.pdf' target='_blank' className='no-wrap'>
                    {t<string>('termsAndConditions')}
                  </a>
                </div>
              </div>
              <Form.Item wrapperCol={{ offset: 8, span: 32 }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={!demoAccountFormValid}
                  loading={isDemoAccountFormLoading}
                >
                  {t<string>('createNewAccount')}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Badge.Ribbon>
      </section>
      {demoAccountError ? (
        <div className='alert-container'>
          <Alert
            description={demoAccountError}
            type='error'
            showIcon
            closable
            onClose={() => setDemoAccountError('')}
          />
        </div>
      ) : undefined}
      {demoAccountSuccess ? (
        <div className='alert-container'>
          <Alert
            description={demoAccountSuccess}
            type='success'
            showIcon
            closable
            onClose={() => setDemoAccountSuccess('')}
          />
        </div>
      ) : undefined}
    </>
  );
}

export default DemoAccount;
