import { format } from "date-fns";

export const getMyAccountsColumns = (t: (value: string) => void) => {
  return [
    {
      title: t('number'),
      dataIndex: 'number',
      fixed: 'left' as any,
    },
    {
      title: t('group'),
      dataIndex: 'accountType',
      render: (accountType: any) => accountType?.name,
    },
    {
      title: t('balance'),
      dataIndex: 'balance',
    },
    {
      title: t('currency'),
      dataIndex: 'currency',
    },
    {
      title: t('leverage'),
      dataIndex: 'leverage',
    },
    {
      title: t('createdAt'),
      dataIndex: 'createdAt',
      render: (createdAt: Date) => format(new Date(createdAt), 'MM/dd/yyyy'),
    },
  ];
};
