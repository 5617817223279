import { SyncOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { getDocumentsColumns } from '../../assets/comfig/documents-columns';
import { INITIAL_DOCUMENTS } from '../../assets/comfig/initialDocuments';
import { UserDocumentDetails } from '../../models/UserDocumentDetails';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import axios from 'axios';
import './MyDocuments.scss';

const FRONT_CAMERA = 'user';
const RARE_CAMERA = 'environment';
const videoConstraints = {
  facingMode: FRONT_CAMERA,
};

function MyDocuments() {
  const { t } = useTranslation();
  const [documentsUploading, setDocumentsUploading] = React.useState(false);
  const [documentsLoading, setDocumentsLoading] = React.useState(false);
  const [documentsLoadingError, setDocumentsLoadingError] = React.useState('');
  const [documentsUpdatingError, setDocumentsUpdatingError] = React.useState('');
  const [documentsUpdatingSuccess, setDocumentsUpdatingSuccess] = React.useState('');
  const [documents, setDocuments] = React.useState<UserDocumentDetails[]>([]);
  const [selectedUploadedFileType, setSelectedUploadedFileType] = React.useState('');
  const [facingMode, setFacingMode] = React.useState(FRONT_CAMERA);

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) => (prevState === FRONT_CAMERA ? RARE_CAMERA : FRONT_CAMERA));
  }, []);

  // Take a live picture
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [startLiveCam, setStartLiveCam] = React.useState(false);
  const webcamRef = React.useRef(null);
  const onCapture = () => {
    const documentsUpdated = documents.map((document) =>
      document.type === selectedUploadedFileType
        ? { ...document, file: (webcamRef as any).current.getScreenshot(), status: 5, isValid: true }
        : document
    );
    setDocuments(documentsUpdated);

    setStartLiveCam(false);
    setSelectedUploadedFileType('');
  };

  React.useEffect(() => {
    setDocumentsLoading(true);

    axios
      .get(API_ENDPOINTS.documents, { headers: { Authorization: `Bearer ${getUserTokenInCookie()}` } })
      .then((userDocuments: any) => {
        const userDocumentsData = userDocuments?.data?.data;
        const refactoredDocuments = INITIAL_DOCUMENTS.map((initialDocument) => {
          const foundedDocument = userDocumentsData.find(
            (docuemt: UserDocumentDetails) => docuemt.type === initialDocument.type
          );
          return foundedDocument
            ? {
                ...foundedDocument,
                isValid: foundedDocument.status === 2,
                key: initialDocument.type,
              }
            : initialDocument;
        });

        setDocumentsLoading(false);
        setDocuments(refactoredDocuments);
      })
      .catch((error) => {
        console.error(error);
        setDocumentsLoadingError(t<string>('somethingWentWrongWithLoadingTheDocuments'));
      });
  }, []);

  function onUploadDocumentClicked(documentDetails: UserDocumentDetails) {
    setSelectedUploadedFileType(documentDetails?.type || '');
    setIsModalOpen(true);
    setStartLiveCam(true);
  }

  function onHandelClose() {
    setSelectedUploadedFileType('');
    setStartLiveCam(false);
    setIsModalOpen(false);
  }

  function onSubmistUploadDocumnents() {
    setDocumentsUploading(true);

    axios
      .post(API_ENDPOINTS.documents, documents, { headers: { Authorization: `Bearer ${getUserTokenInCookie()}` } })
      .then((documentsResponse: any) => {
        setDocumentsUploading(false);
        setDocuments(documentsResponse?.data);
      })
      .catch((error) => {
        console.error(error);
        setDocumentsUploading(false);
        setDocumentsLoadingError(t<string>('somethingWentWrongWithLoadingTheDocuments'));
      });
  }

  const documentsColumns = getDocumentsColumns(onUploadDocumentClicked, t);

  return (
    <section className='my-documents-container'>
      <h1>{t<string>('myDocuments')}</h1>
      <div className='my-documents-container'>
        <Table
          columns={documentsColumns as any}
          dataSource={documents}
          bordered
          loading={documentsLoading}
          pagination={false}
        />
      </div>
      <Button
        type='primary'
        disabled={!documents[0]?.isValid || !documents[1]?.isValid || !documents[2]?.isValid}
        style={{ marginTop: 20 }}
        loading={documentsUploading}
        onClick={() => onSubmistUploadDocumnents()}
      >
        {t<string>('uploadDocuments')}
      </Button>
      {startLiveCam ? (
        <Modal
          title={t<string>('takeLivePictureOfTheDocument')}
          open={isModalOpen}
          onOk={() => onCapture()}
          onCancel={() => onHandelClose()}
        >
          <div
            style={{
              position: 'absolute',
              top: 67,
              right: 40,
              backgroundColor: '#00000063',
              padding: 4,
              borderRadius: 8,
              zIndex: 100,
            }}
          >
            <SyncOutlined onClick={handleClick} style={{ fontSize: 30, color: '#ffffff', cursor: 'pointer' }} />
          </div>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat='image/jpeg'
            width='100%'
            videoConstraints={{ ...videoConstraints, facingMode }}
          />
        </Modal>
      ) : undefined}
      {documentsLoadingError ? (
        <div className='alert-container'>
          <Alert
            description={documentsLoadingError}
            type='error'
            showIcon
            closable
            onClose={() => setDocumentsLoadingError('')}
          />
        </div>
      ) : undefined}
      {documentsUpdatingError ? (
        <div className='alert-container'>
          <Alert
            description={documentsUpdatingError}
            type='error'
            showIcon
            closable
            onClose={() => setDocumentsUpdatingError('')}
          />
        </div>
      ) : undefined}
      {documentsUpdatingSuccess ? (
        <div className='alert-container'>
          <Alert
            description={documentsUpdatingSuccess}
            type='success'
            showIcon
            closable
            onClose={() => setDocumentsUpdatingSuccess('')}
          />
        </div>
      ) : undefined}
    </section>
  );
}

export default MyDocuments;
