import { Alert, Button, Form, Input, Spin } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { WAIT_REDIRECT_TIME } from '../../assets/comfig/redirect-details';
import { PASSWORD_VALIDATION } from '../../assets/comfig/validation-regex';
import { deleteUserTokenFromCookie } from '../../helpers/cookiesHandler';
import { useMainContext } from '../../store/MainContext';
import { companyDetailsMock } from '../../mocks/companyDetailsMock';
import axios from 'axios';
import './ResetPassword.scss';

function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mainState, setMainState } = useMainContext();
  const [resetPasswordError, setResetPasswordError] = React.useState('');
  const [resetPasswordSuccess, setResetPasswordSuccess] = React.useState('');
  const [loadingCompanyDetails, setLoadingCompanyDetails] = React.useState(false);
  const [errorCompanyDetails, setErrorCompanyDetails] = React.useState('');
  const companyDetails = React.useMemo(
    () => ({
      companyName: companyDetailsMock.companyName,
      companyLogo: companyDetailsMock.companyLogo,
      backgroundImage: companyDetailsMock.backgroundImage,
    }),
    []
  );
  const [resetPasswordFormLoading, setResetPasswordFormLoading] = React.useState(false);
  const [resetPasswordFormDisabled, setResetPasswordFormDisabled] = React.useState(true);

  React.useEffect(() => {
    deleteUserTokenFromCookie();
    setMainState({ ...mainState, isLoggedIn: false });
  }, []);

  const onSubmit = React.useCallback((resetPasswordValues: { password: string }) => {
    setResetPasswordFormLoading(true);

    axios
      .post(API_ENDPOINTS.resetPassword, {
        password: resetPasswordValues.password,
        token: searchParams.get('token'),
      })
      .then((_: any) => {
        setResetPasswordFormLoading(false);
        setResetPasswordSuccess(t<string>('redirectToSignInPage'));
        setTimeout(() => navigate('/sign-in'), WAIT_REDIRECT_TIME);
      })
      .catch((error) => {
        console.error(error);
        setResetPasswordFormLoading(false);
        setResetPasswordError(error.errorMessage);
      });
  }, []);
  const onFormChange = (_: any, resetPasswordValues: { password: string }) => {
    setResetPasswordFormDisabled(!PASSWORD_VALIDATION.test(resetPasswordValues?.password));
  };

  return (
    <>
      <section className='reset-password-section'>
        <div className='reset-password-header'>
          {loadingCompanyDetails ? (
            <Spin size='large' />
          ) : (
            <div className='company-details-container'>
              <img className='company-logo' src={companyDetails.companyLogo} alt='Company Logo' />
              <p>
                {t<string>('welcomeTo')} {companyDetails.companyName} {t<string>('clietnPortal')}
              </p>
            </div>
          )}
        </div>
        <Form
          name='basic'
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          onFinish={onSubmit}
          onValuesChange={onFormChange}
          autoComplete='off'
        >
          <Form.Item
            label={t<string>('password')}
            name='password'
            rules={[
              { required: true, message: t<string>('passwordIsRequired') },
              {
                pattern: PASSWORD_VALIDATION,
                message: t<string>('pleaseMakeSurePasswordIsValid'),
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 32 }}>
            <Button
              type='primary'
              htmlType='submit'
              disabled={resetPasswordFormDisabled}
              loading={resetPasswordFormLoading}
            >
              {t<string>('resetPassword')}
            </Button>
          </Form.Item>
        </Form>
      </section>
      {resetPasswordError ? (
        <div className='alert-container'>
          <Alert
            description={resetPasswordError}
            type='error'
            showIcon
            closable
            onClose={() => setResetPasswordError('')}
          />
        </div>
      ) : undefined}
      {resetPasswordSuccess ? (
        <div className='alert-container'>
          <Alert
            description={resetPasswordSuccess}
            type='success'
            showIcon
            closable
            onClose={() => setResetPasswordSuccess('')}
          />
        </div>
      ) : undefined}
      {errorCompanyDetails ? (
        <div className='alert-container'>
          <Alert
            description={errorCompanyDetails}
            type='error'
            showIcon
            closable
            onClose={() => setErrorCompanyDetails('')}
          />
        </div>
      ) : undefined}
    </>
  );
}

export default ResetPassword;
