import { CompanyDetails } from "../models/CompanyDetails";

export const companyDetailsMock: CompanyDetails = {
    companyName: 'INZO',
    companyLogo: 'https://cdn.skaleapps.io/images/branding/YX68uv9DwM.png',
    backgroundImage: 'https://cdn.skaleapps.io/images/branding/BRLMsQqdHC.jpeg',
    colorPrimary: '#0f2147',
    colorPrimaryHover: '#6fa374',
    colorError: '#ff4d4f',
    colorSuccess: '#52c41a',
    colorInfo: '#1677ff',
    colorTextBase: '#000000',
    colorBgLayout: '#f5f5f5',
    colorBgMenu: '#0f2147',
}