import { User } from "../../models/Users";

export const initialUser: User = {
    accountType: 1,
    active: false,
    archived: false,
    challengeAgentId: '',
    emailConfirmed: false,
    id: '',
    isPartner: false,
    kycApproved: false,
}