import './Copy.scss';

function Copy() {
  return (
    <div>
      <iframe
        id='widgetFrame'
        className='copy-iframe'
        style={{ width: 'calc(100% + 32px)', height: '100vh', position: 'relative' }}
        src='https://ratings.inzo.co/widgets/ratings?widgetKey=social_platform_ratings&theme=light&lang=en'
      ></iframe>
      <script src='https://ratings.inzo.co/widgets/assets/js/iframeResizer.js'></script>
    </div>
  );
}

export default Copy;
