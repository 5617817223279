import { Alert, Result, Table } from 'antd';
import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { getCompetitionColumns } from '../../assets/comfig/competition-columns';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import { Competitioin } from '../../models/competition';
import './TopTraders.scss';

function TopTraders() {
  const { t } = useTranslation();
  const [loadedCompetition, setLoadedCompetition] = React.useState<Competitioin[]>([]);
  const [competitionLoading, setCompetitionLoading] = React.useState(false);
  const [errorCompetition, setErrorCompetition] = React.useState('');

  React.useEffect(() => {
    setCompetitionLoading(true);

    axios
      .get(API_ENDPOINTS.competition, {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((competitionData: any) => {
        setLoadedCompetition(
          competitionData.data.data
            .sort((a: Competitioin, b: Competitioin) => b.profit - a.profit)
            .map((a: Competitioin, index: number) => ({ ...a, key: index }))
        );
        setCompetitionLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setErrorCompetition(t<string>('somethingWentWrongWithLoadingTheListOfTopTraders'));
        setCompetitionLoading(false);
      });
  }, []);

  const competitionColumns = React.useMemo(() => getCompetitionColumns(t), []);

  return (
    <section className='competition-container'>
      <h1>{t<string>('topTraders')}</h1>
      <div className='competition-table-container'>
        {errorCompetition ? (
          <Result status='warning' title={t<string>('There are some problems with loading the list of competition')} />
        ) : (
          <>
            {loadedCompetition!?.length > 0 ? (
              <h2>
                {t<string>('total')}: {loadedCompetition?.length}
              </h2>
            ) : null}
            <Table
              columns={competitionColumns as any}
              dataSource={loadedCompetition}
              bordered
              loading={competitionLoading}
              pagination={false}
            />
          </>
        )}
      </div>
      {errorCompetition ? (
        <div className='alert-container'>
          <Alert
            description={errorCompetition}
            type='error'
            showIcon
            closable
            onClose={() => setErrorCompetition('')}
          />
        </div>
      ) : undefined}
    </section>
  );
}

export default TopTraders;
