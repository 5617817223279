import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './NotFound.scss';

function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Result
      status='error'
      title={t<string>('pageNotFound')}
      subTitle={t<string>('makeSureYouUseValidPages')}
      extra={[
        <Button type='primary' key='console' onClick={() => navigate('/home')}>
          {t<string>('GoToHomePage')}
        </Button>,
      ]}
      className='not-found-container'
    ></Result>
  );
}

export default NotFound;
