import { Tag } from 'antd';

export const getCompetitionColumns = (t: (value: string) => void) => {
  return [
    {
      title: '#',
      dataIndex: 'key',
      fixed: 'left' as any,
      render: (key: number) => key + 1,
    },
    {
      title: t('name'),
      dataIndex: 'name',
      fixed: 'left' as any,
    },
    {
      title: t('account'),
      dataIndex: 'account',
    },
    {
      title: t('profit'),
      dataIndex: 'profit',
      render: (profit: number) => <Tag color={profit >= 0 ? 'green' : 'red'}>{`${profit}%`}</Tag>,
    },
  ];
};
