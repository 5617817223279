import { UserDocumentDetails } from "../../models/UserDocumentDetails";

export const INITIAL_DOCUMENTS: UserDocumentDetails[] = [
    {
      createdAt: null,
      file: null,
      id: null,
      status: 1,
      type: 'copy_of_id_front',
      key: 'copy_of_id_front',
      isValid: false,
    },
    {
      createdAt: null,
      file: null,
      id: null,
      status: 1,
      type: 'copy_of_id_back',
      key: 'copy_of_id_back',
      isValid: false,
    },
    {
      createdAt: null,
      file: null,
      id: null,
      status: 1,
      type: 'proof_of_address_front',
      key: 'proof_of_address_front',
      isValid: false,
    },
    {
      createdAt: null,
      file: null,
      id: null,
      status: 1,
      type: 'proof_of_address_back',
      key: 'proof_of_address_back',
      isValid: false,
    },
];

