import { LanguagePhrases } from "../models/LanguagePhrases";

export const frenchPhrases: LanguagePhrases = {
    home: "Maison",
    downloads: "Téléchargements",
    support: "Soutien",
    topTraders: "Meilleurs commerçants",
    myProfile: "Mon profil",
    myDocuments: "Mes documents",
    myAccounts: "Mes comptes",
    openAccount: "Compte ouvert",
    liveAccount: "Compte en direct",
    demoAccount: "Compte démo",
    ibAccount: "Compte IB",
    depositAndWithdraw: "Dépôt retirer",
    deposit: "Dépôt",
    withdraw: "Retirer",
    transfer: "Transfert",
    trading: "Commerce",
    tradingHistory: "Historique des échanges",
    platformPasswords: "Mots de passe de la plate-forme",
    agentPortal: "Portail des agents",
    promotions: "Promotions",
    clients: "Clients",
    secondaryAgents: "Agents secondaires",
    myDetails: "Mes Details",
    changePassword: "Changer le mot de passe",
    myDetailsForm: "Formulaire Mes informations",
    changePasswordForm: "Formulaire de changement de mot de passe",
    firstName: "Prénom",
    surname: "Nom de famille",
    email: "E-mail",
    phone: "Téléphone",
    address: "Adresse",
    city: "Ville",
    zip: "Zipper",
    state: "État",
    country: "Pays",
    password: "Mot de passe",
    confirmPassword: "Confirmez le mot de passe",
    updateDetails: "Mettre à jour les détails",
    updatePassword: "Mettre à jour le mot de passe",
    fileType: "Type de fichier",
    status: "Statut",
    uploadDate: "Date de dépôt",
    preview: "Aperçu",
    upload: "Télécharger",
    uploadDocuments: "Télécharger des documents",
    mt5LiveAccounts: "Comptes en direct MT5",
    mt5DemoAccounts: "Comptes de démonstration MT5",
    cTraderLiveAccounts: "Comptes en direct cTrader",
    cTraderDemoAccounts: "Comptes démo cTrader",
    youDontHaveAccountsOfThisType: "Vous n'avez pas de compte de ce type",
    number: "Nombre",
    group: "Groupe",
    balance: "Équilibre",
    currency: "Monnaie",
    leverage: "Effet de levier",
    createdAt: "Créé à",
    openLiveAccount: "Ouvrir un compte réel",
    liveAccountForm: "Formulaire de compte en direct",
    platform: "Plateforme",
    liveAccountType: "Type de compte en direct",
    accountLaverage: "Moyenne du compte",
    agreeOnThe: "D'accord sur le",
    termsAndConditions: "Termes et conditions",
    createNewAccount: "Créer un nouveau compte",
    openDemoAccount: "Ouvrir un compte démo",
    demoAccountForm: "Formulaire de compte démo",
    demoAccountType: "Type de compte démo",
    openIbAccount: "Ouvrir un compte IB",
    ibAccountForm: "Formulaire de compte IB",
    writeYourServicesHere: "Écrivez vos services ici",
    depositToYourAccounts: "Dépôt sur vos comptes",
    depositInDigitalCurrencies: "Dépôt en devises numériques",
    depositUsingWallets: "Dépôt à l'aide de portefeuilles",
    cashWithdraw: "Retrait d'espèces",
    withdrawUsingWallets: "Retirer à l'aide de portefeuilles",
    walletsWithdrawal: "portefeuilles Retrait",
    newAccountWasCreated: "Un nouveau compte a été créé",
    somethingWentWrongWithCreatingNewAccount: "Une erreur s'est produite lors de la création d'un nouveau compte",
    platformShoudntBeEmpty: "La plateforme ne doit pas être vide",
    accountTypeShoudntBeEmpty: "Le type de compte ne doit pas être vide",
    accountLeverageShoudntBeEmpty: "L'effet de levier du compte ne doit pas être vide",
    firstNameFieldShouldNotBeEmpty: "Le champ Prénom ne doit pas être vide",
    surnameFieldShouldNotBeEmpty: "Le champ Nom de famille ne doit pas être vide",
    emailFieldShouldNotBeEmpty: "Le champ e-mail ne doit pas être vide",
    pleaseMakeSureTheEmailIsValid: "Veuillez vous assurer que l'e-mail est valide",
    accountIsRequired: "Le champ du compte ne doit pas être vide",
    amountIsRequired: "Le champ Montant ne doit pas être vide",
    currencyIsRequired: "Le champ Devise ne doit pas être vide",
    minimumValueIs: "Valeur minimaleIs",
    sendDeposit: "Envoyer le dépôt",
    somethingWentWrongWithTheDeposit: "Quelque chose s'est mal passé avec le dépôt",
    accountLeverage: "Effet de levier du compte",
    walletIsRequired: "Le portefeuille est requis",
    wallet: "Portefeuille",
    uploadConfirmation: "Confirmation de téléchargement",
    somethingWentWrongWithLoadingTheAccounts: "Une erreur s'est produite lors du chargement des comptes",
    theDepositWasSentSuccessfully: "Le dépôt a été envoyé avec succès",
    depositUsing: "Dépôt en utilisant",
    desktopWindows: "Fenêtres de bureau",
    desktopMacOs: "MacO de bureau",
    mobileIOS: "iOS mobile",
    mobileAndroid: "Android mobile",
    somethingWentWrongWithFetchingTheCompanyDetails: "Une erreur s'est produite lors de la récupération des informations sur l'entreprise",
    somethingWentWrongWithResetingThePassword: "Une erreur s'est produite lors de la réinitialisation du mot de passe",
    resetEmailWasSent: "L'URL de réinitialisation a été envoyée à votre adresse e-mail. Veuillez utiliser l'URL pour réinitialiser votre mot de passe",
    welcomeTo: "Bienvenue à",
    clietnPortal: "Portail clients",
    sendRequest: "Envoyer une demande",
    goBack: "Retourner",
    somethingWentWrongWithFetchingTheRecentTrades: "Une erreur s'est produite lors de la récupération des échanges récents",
    recentTradingActivity: "Activité commerciale récente",
    problemsWithLoadingTradingActivities: "Il y a quelques problèmes avec le chargement de l'activité commerciale récente",
    servicesFieldShouldNotBeEmpty: "Le champ Services ne doit pas être vide",
    createNewIbAccount: "Créer un nouveau compte IB",
    somethingWentWrongWithLoadingTheTrades: "Une erreur s'est produite lors du chargement des transactions",
    myTraders: "Mes commerçants",
    myTradersControlForm: "Formulaire de contrôle Mes commerçants",
    fromDateIsRequired: "La date de départ est requise",
    toDateIsRequired: "À ce jour est requis",
    fromDate: "Partir de la date",
    toDate: "À ce jour",
    theFromDateShouldBeBeforeToDate: "La date de début doit être antérieure à la date de fin",
    getMyTraders: "Obtenir mes commerçants",
    searchInUsersTable: "Rechercher dans le tableau des utilisateurs",
    somethingWentWrongWithLoadingTheSecondaryAgents: "Une erreur s'est produite lors du chargement des agents secondaires",
    mySecondaryAgents: "Mes agents secondaires",
    mySecondaryAgentsControlForm: "Formulaire de contrôle de mes agents secondaires",
    getMySecondaryAgents: "Obtenir mes agents secondaires",
    accountTypeShouldNotBeEmpty: "Le type de compte ne doit pas être vide",
    accountLeverageShouldNotBeEmpty: "L'effet de levier du compte ne doit pas être vide",
    thereIsProblemWithLoadingListCompetition: "Il y a quelques problèmes avec le chargement de la liste des compétitions",
    ibAccounts: "Comptes IB",
    youDoNotHaveAccountsOfThisType: "Vous n'avez pas de comptes de ce type",
    somethingWentWrongWithLoadingTheDocuments: "Une erreur s'est produite lors du chargement des documents",
    takeLivePictureOfTheDocument: "Prendre une photo en direct du document",
    somethingWentWrongWithLoadingPrfileData: "Une erreur s'est produite lors du chargement des données de profil",
    yourProfileDetailsGotUpdated: "Les détails de votre profil ont été mis à jour",
    somethingWentWrongWithUpdatingPrfileData: "Une erreur s'est produite lors de la mise à jour des données de profil",
    phoneFieldShouldNotBeEmpty: "Le champ Téléphone ne doit pas être vide",
    pleaseMakeSureThePhoneNumberIsValid: "Veuillez vous assurer que le numéro de téléphone est valide",
    addressFieldShouldNotBeEmpty: "Le champ d'adresse ne doit pas être vide",
    cityFieldShouldNotBeEmpty: "Le champ de la ville ne doit pas être vide",
    zipFieldShouldNotBeEmpty: "Le champ Code postal ne doit pas être vide",
    stateFieldShouldNotBeEmpty: "Le champ d'état ne doit pas être vide",
    countryFieldShouldNotBeEmpty: "Le champ Pays ne doit pas être vide",
    pleaseMakeSurePasswordIsValid: "Veuillez vous assurer que votre mot de passe comprend au moins une lettre majuscule, une lettre minuscule, un caractère spécial et que la longueur du mot de passe doit être d'au moins 8",
    twoPasswordsDoNotMatch: "Les deux mots de passe doivent être identiques",
    pageNotFound: "Page non trouvée",
    makeSureYouUseValidPages: "Assurez-vous de n'utiliser que des pages valides",
    GoToHomePage: "Aller à la maison",
    somethingWentWrongWithUpdatingPasswordForThisAccount: "Une erreur s'est produite lors de la mise à jour du mot de passe de ce compte",
    passwordType: "Type de mot de passe",
    passwordTypeIsRequired: "Le type de mot de passe est requis",
    passwordIsRequired: "Mot de passe requis",
    confirmPasswordIsRequired: "Confirmer le mot de passe est requis",
    clickToCopy: "Cliquez pour copier",
    myPromotionalMaterials: "Mes supports promotionnels",
    myPromotionalMaterialsControlForm: "Formulaire de contrôle de mon matériel promotionnel",
    account: "Compte",
    myReferalLink: "Mon lien de parrainage",
    myTestReferalLink: "Lien de parrainage de ma démo",
    redirectToSignInPage: "Rediriger vers la page de connexion",
    resetPassword: "Réinitialiser le mot de passe",
    somethingWentWrongWithSigningIn: "Une erreur s'est produite lors de la connexion",
    signIn: "S'identifier",
    forgotPassword: "Mot de passe oublié",
    somethingWentWrongWithSendingYourMessage: "Une erreur s'est produite lors de l'envoi de votre question",
    contactOurSupport: "Contactez notre assistance",
    questionCategory: "Catégorie de questions",
    questionCategoryShouldNotBeEmpty: "La catégorie de question ne doit pas être vide",
    changeTheAgentOfYourAccount: "Changer l'agent de votre compte",
    changeThePasswordForYourTradingAccount: "Changer le mot de passe de votre compte de trading",
    changeTheMarginOfYourAccount: "Modifier la marge de votre compte",
    writeYourQuestionHere: "Écrivez votre question ici",
    questionFieldShouldNotBeEmpty: "Le champ de la question ne doit pas être vide",
    sendQuestion: "Envoyer une question",
    yourQuestionSentSuccessfully: "Votre question a été envoyée avec succès",
    somethingWentWrongWithLoadingTheListOfTopTraders: "Une erreur s'est produite lors du chargement de la liste des meilleurs commerçants",
    somethingWentWrongWithLoadingTheListOfRecentTrades: "Une erreur s'est produite lors du chargement de la liste des échanges récents",
    tradingHistoryControlForm: "Formulaire de contrôle de l'historique des transactions",
    somethingWentWrongWithThisTransfer: "Quelque chose s'est mal passé avec ce transfert",
    trasnferBetweenAccounts: "Transfert entre comptes",
    trasnferBetweenAccountsForm: "Formulaire de transfert entre comptes",
    fromAccount: "Du compte",
    toAccount: "Estimer",
    shouldNotBeTheSameAsToAccount: "Ne doit pas être identique à To Account",
    shouldNotBeTheSameAsFromAccount: "Ne doit pas être identique à Du compte",
    theAmmountShouldBeAtLeast: "Le montant doit être d'au moins",
    transferInfo: "Veuillez vous assurer que vous disposez d'une marge libre suffisante pour occuper toutes les positions ouvertes que vous pourriez avoir. Nous ne serons pas responsables si votre niveau de marge tombe en dessous du niveau minimum. Cela peut entraîner la fermeture automatique de toutes vos positions ouvertes.",
    withdrawMoeny: "Retirer Moeny",
    andMaximumValueIs: "et la valeur maximale est",
    withdrawMethod: "Méthode de retrait",
    methodIsRequired: "La méthode est requise",
    leaveNote: "Laisser une note",
    somethingWentWrongWithLoadingTheWeekDay: "Une erreur s'est produite lors du chargement du jour de la semaine",
    pleaseBeAwareThatYouCantWithdrawMoenyOnSaturday: "Veuillez noter que vous ne pouvez pas retirer d'argent le samedi",
    walletWithdawal: "Retrait de portefeuille",
    somethingWentWrongWithTheWithdraw: "Quelque chose s'est mal passé avec le retrait",
    somethingWentWrongWithLoadingTheUserDetails: "Une erreur s'est produite lors du chargement des détails de l'utilisateur",
    yourAccountIsInactivePleaseContactYourAdminToActivateYourIt: "Votre compte est inactif. Veuillez contacter votre administrateur pour l'activer",
    footerContent: "Avertissement sur les risques : le Forex et les CFD sont des produits à effet de levier, comportent un niveau de risque élevé et peuvent ne pas convenir à tous les investisseurs. Vous ne devriez pas risquer plus que ce que vous êtes prêt à perdre. Avant de décider de trader, assurez-vous de bien comprendre les risques encourus et de prendre en compte votre niveau d'expérience. Demandez des conseils indépendants si nécessaire.",
    allRightsReserved: "Tous les droits sont réservés.",
    areYouSureYouWantToLogOut: "Êtes-vous sûr de vouloir vous déconnecter?",
    yes: "Oui",
    no: "Non",
    profit: "Profit",
    name: "Nom",
    time: "Temps",
    deal: "Accord",
    order: "Commande",
    position: "Position",
    symbol: "Symbole",
    type: "Taper",
    access: "Accès",
    volume: "Volume",
    price: "Prix",
    reason: "Raison",
    commission: "Commission",
    swap: "Échanger",
    comment: "Commentaire",
    idFront: "ID recto",
    idBack: "ID verso",
    addressFront: "Adresse recto",
    addressBack: "Adresse verso",
    amount: "Montante",
    getTrades: "Obtenez des échanges",
    sendMoenyTo: "Envoyez l'argent à",
    followTheInstructions: "Suivez les instructions",
    goToPayeerApp: "Accédez à l'application Payeer",
    sendTheMoneyToOurWalletAbove: "Envoyez l'argent à notre portefeuille ci-dessus",
    clickConfirmTransfer: "Cliquez sur Confirmer le transfert",
    takeAScreenshotAndUploadItToTheConfirmTransferField: "Prenez une capture d'écran et téléchargez-la dans le champ Confirmer le transfert",
    goToZainCashApplication: "Accédez à l'application Zain Cash",
    goToTheWithdrawOption: "Aller à l'option de retrait",
    writeOurAgencyNumberAbove: "Écrivez notre numéro d'agence ci-dessus",
    goToPerfectMoneyApplication: "Accédez à l'application Perfect Money",
    callTheNumberAboveToInquireAboutTheNearestWesternUnionCenter: "Appelez le numéro ci-dessus pour vous renseigner sur le centre Western Union le plus proche",
    transferTheFunds: "Transférer les fonds",
    callTheNumberAboveToInquireAboutTheNearestMoneyExchangeToTransferMoney: "Appelez le numéro ci-dessus pour vous renseigner sur le bureau de change le plus proche pour transférer de l'argent",
    takeAPictureOfTheTransferReceiptAndAttachItInTheTransferProofField: "Prenez une photo du reçu de transfert et joignez-la dans le champ Preuve de transfert",
    goToFastPayApp: "Accédez à l'application FastPay",
    goToTheWithdrawalOption: "Accédez à l'option de retrait",
    typeOurAgencyNumberAboveThenWriteInTheFieldBelowTheAmountYouWantToTransfer: "Tapez notre numéro d'agence ci-dessus, puis écrivez dans le champ ci-dessous le montant que vous souhaitez transférer",
    clickConfirmAndThenEnterYourPassword: "Cliquez sur Confirmer puis entrez votre mot de passe",
    thenClickOnViewInvoiceTakeAScreenshotAndAttachItAsProof: "Cliquez ensuite sur Afficher la facture, faites une capture d'écran et joignez-la comme preuve",
    goToAsiaHawalaApp: "Accédez à l'application Asia Hawala",
    goToTransfers: "Aller aux transferts",
    writeTheNumberAboveThenBelowItTheMoneyYouWantToTransfer: "Écrivez le numéro ci-dessus, puis en dessous l'argent que vous souhaitez transférer",
    clickConfirmTransferTakeAScreenshotAndUploadItToTheConfirmTransferField: "Cliquez sur Confirmer le transfert, prenez une capture d'écran et téléchargez-la dans le champ Confirmer le transfert",
    cryptoDeposit: "Dépôt cryptographique",
    processOrder: "Traiter la commande",
    ERC20PleaseNoteThatWeDoNotReceiveFundsUsingTheBEP20Method: "Traiter la commande ERC20 Veuillez noter que nous ne recevons pas de fonds en utilisant la méthode (BEP20).",
    newPassword: "Nouveau mot de passe",
    currentPassword: "Mot de passe actuel",
    copy: "copie",
    total: "totale",
}