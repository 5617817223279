import { EMAIL_VALIDATION, PHONE_VALIDATION } from "../assets/comfig/validation-regex";
import { MyProfileDetails } from "../models/myProfileDetails";

export function isMyProfileUserInValid(user: MyProfileDetails['data']) {
    return !user.firstName ||
        !user.lastName ||
        !user.email ||
        !EMAIL_VALIDATION.test(user.email) ||
        !user.phone ||
        !PHONE_VALIDATION.test(user.phone) ||
        !user.city ||
        !user.state ||
        !user.country ||
        !user.address ||
        !user.zip
}

export function isPasswordConfirmInValid(passwordForm: {currentPwd: string; newPwd: string;  confirmPwd: string}) {
    return !passwordForm.newPwd ||
        !passwordForm.currentPwd ||
        !passwordForm.confirmPwd ||
        passwordForm.newPwd !== passwordForm.confirmPwd
}

export function isPasswordInvalid(password: string,  confirmPassword: string) {
    return !password || !confirmPassword || password !== confirmPassword;
}