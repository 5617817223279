import { Alert, Badge, Button, Card, Form, Input } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import React from 'react';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { EMAIL_VALIDATION, PASSWORD_VALIDATION, PHONE_VALIDATION } from '../../assets/comfig/validation-regex';
import { isMyProfileUserInValid, isPasswordConfirmInValid } from '../../helpers/isUserValid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import './MyProfile.scss';

function MyProfile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [myDetailsForm] = Form.useForm();
  const [myPasswordForm] = Form.useForm();
  const [isUserDataLoading, setUserDataLoading] = React.useState(false);
  const [userDataError, setUserDataError] = React.useState('');
  const [editUserDataError, setEditUserDataError] = React.useState('');
  const [editUserDataSuccess, setEditUserDataSuccess] = React.useState('');
  const [userDetailsSubmitDisabled, setUserDetailsSubmitDisabled] = React.useState(true);
  const [userPasswordSubmitDisabled, setUserPasswordSubmitDisabled] = React.useState(true);

  React.useEffect(() => {
    const user: any = JSON.parse(window.localStorage.getItem('userInfo') as any);

    if (user?.id) {
      myDetailsForm.setFieldsValue({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone,
        city: user.city,
        country: user.country,
        state: user.state,
        zip: user.zip,
        address: user.address,
      });
    } else {
      navigate('/sign-in');
    }
  }, []);

  const onDetailsFormChange = () =>
    setUserDetailsSubmitDisabled(isMyProfileUserInValid(myDetailsForm.getFieldsValue()));
  const onPasswordFormChange = () =>
    setUserPasswordSubmitDisabled(isPasswordConfirmInValid(myPasswordForm.getFieldsValue()));

  const onDetailsFormSubmit = React.useCallback(() => {
    setUserDataLoading(true);
    axios
      .patch(API_ENDPOINTS.personalInfo, myDetailsForm.getFieldsValue(), {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((_: any) => {
        setUserDataLoading(false);
        setUserDetailsSubmitDisabled(true);
        setEditUserDataSuccess(t<string>('yourProfileDetailsGotUpdated'));
      })
      .catch((error) => {
        console.error(error);
        setEditUserDataError(t<string>('somethingWentWrongWithUpdatingPrfileData'));
        setUserDataLoading(false);
        setUserDetailsSubmitDisabled(true);
      });
  }, []);

  const onPasswordFormSubmit = React.useCallback(() => {
    setUserDataLoading(true);
    axios
      .post(API_ENDPOINTS.editMyPassword, myPasswordForm.getFieldsValue(), {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((_: any) => {
        setUserDataLoading(false);
        setUserPasswordSubmitDisabled(true);
        setEditUserDataSuccess(t<string>('yourProfileDetailsGotUpdated'));
      })
      .catch((error) => {
        console.error(error);
        setEditUserDataError(t<string>('somethingWentWrongWithUpdatingPrfileData'));
        setUserDataLoading(false);
        setUserPasswordSubmitDisabled(true);
      });
  }, []);

  return (
    <section className='my-profile-continer'>
      <div className='my-profile-forms-continer'>
        <div>
          <h1>{t<string>('myDetails')}</h1>
          <Badge.Ribbon text={<UserOutlined style={{ fontSize: 18 }} />}>
            <Card title={t<string>('myDetailsForm')}>
              <Form
                name='basic'
                form={myDetailsForm}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}
                onValuesChange={onDetailsFormChange}
                autoComplete='off'
                disabled={isUserDataLoading}
                onFinish={onDetailsFormSubmit}
              >
                <div className='my-profile-form-content'>
                  <div>
                    <Form.Item
                      label={t<string>('firstName')}
                      name='firstName'
                      rules={[{ required: true, message: t<string>('firstNameFieldShouldNotBeEmpty') }]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                      label={t<string>('surname')}
                      name='lastName'
                      rules={[{ required: true, message: t<string>('surnameFieldShouldNotBeEmpty') }]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                      label={t<string>('email')}
                      name='email'
                      rules={[
                        { required: true, message: t<string>('emailFieldShouldNotBeEmpty') },
                        { pattern: EMAIL_VALIDATION, message: t<string>('pleaseMakeSureTheEmailIsValid') },
                      ]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                      label={t<string>('phone')}
                      name='phone'
                      rules={[
                        { required: true, message: t<string>('phoneFieldShouldNotBeEmpty') },
                        { pattern: PHONE_VALIDATION, message: t<string>('pleaseMakeSureThePhoneNumberIsValid') },
                      ]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                      label={t<string>('address')}
                      name='address'
                      rules={[{ required: true, message: t<string>('addressFieldShouldNotBeEmpty') }]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                      label={t<string>('city')}
                      name='city'
                      rules={[{ required: true, message: t<string>('cityFieldShouldNotBeEmpty') }]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                      label={t<string>('zip')}
                      name='zip'
                      rules={[{ required: true, message: t<string>('zipFieldShouldNotBeEmpty') }]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                      label={t<string>('state')}
                      name='state'
                      rules={[{ required: true, message: t<string>('stateFieldShouldNotBeEmpty') }]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                      label={t<string>('country')}
                      name='country'
                      rules={[{ required: true, message: t<string>('countryFieldShouldNotBeEmpty') }]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 32 }}>
                      <Button
                        type='primary'
                        htmlType='submit'
                        disabled={userDetailsSubmitDisabled}
                        loading={isUserDataLoading}
                      >
                        {t<string>('updateDetails')}
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </Card>
          </Badge.Ribbon>
        </div>
        <div>
          <h1>{t<string>('changePassword')}</h1>
          <Badge.Ribbon text={<LockOutlined style={{ fontSize: 18 }} />}>
            <Card title={t<string>('changePasswordForm')}>
              <Form
                name='basic'
                form={myPasswordForm}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}
                onValuesChange={onPasswordFormChange}
                autoComplete='off'
                disabled={isUserDataLoading}
                onFinish={onPasswordFormSubmit}
              >
                <div className='my-profile-form-content'>
                  <div>
                    <Form.Item
                      label={t<string>('currentPassword')}
                      name='currentPwd'
                      rules={[
                        {
                          pattern: PASSWORD_VALIDATION,
                          message: t<string>('pleaseMakeSurePasswordIsValid'),
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      label={t<string>('newPassword')}
                      name='newPwd'
                      rules={[
                        {
                          pattern: PASSWORD_VALIDATION,
                          message: t<string>('pleaseMakeSurePasswordIsValid'),
                        },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item
                      label={t<string>('confirmPassword')}
                      name='confirmPwd'
                      rules={[
                        {
                          pattern: PASSWORD_VALIDATION,
                          message: t<string>('pleaseMakeSurePasswordIsValid'),
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue('newPwd') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(t<string>('twoPasswordsDoNotMatch')));
                          },
                        }),
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 32 }}>
                      <Button
                        type='primary'
                        htmlType='submit'
                        disabled={userPasswordSubmitDisabled}
                        loading={isUserDataLoading}
                      >
                        {t<string>('updatePassword')}
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </Card>
          </Badge.Ribbon>
        </div>
      </div>
      {userDataError ? (
        <div className='alert-container'>
          <Alert description={userDataError} type='error' showIcon closable onClose={() => setUserDataError('')} />
        </div>
      ) : undefined}
      {editUserDataError ? (
        <div className='alert-container'>
          <Alert
            description={editUserDataError}
            type='error'
            showIcon
            closable
            onClose={() => setEditUserDataError('')}
          />
        </div>
      ) : undefined}
      {editUserDataSuccess ? (
        <div className='alert-container'>
          <Alert
            description={editUserDataSuccess}
            type='success'
            showIcon
            closable
            onClose={() => setEditUserDataSuccess('')}
          />
        </div>
      ) : undefined}
    </section>
  );
}

export default MyProfile;
