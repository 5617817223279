import { CopyOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share';
import './SocialMedia.scss';

function SocialMedia({ url, accountNumber, isLoading }: { url: string; accountNumber: number; isLoading: boolean }) {
  const { t } = useTranslation();
  const fullUrl = React.useMemo(() => `${url}${accountNumber}`, [url, accountNumber]);

  return (
    <div className='social-media-container'>
      <Tooltip title={t<string>('clickToCopy')}>
        <CopyOutlined size={20} disabled={isLoading} onClick={() => navigator.clipboard.writeText(fullUrl)} />
      </Tooltip>
      <FacebookShareButton url={fullUrl} disabled={isLoading}>
        <FacebookIcon size={20} round />
      </FacebookShareButton>
      <TwitterShareButton url={fullUrl} disabled={isLoading}>
        <TwitterIcon size={20} round />
      </TwitterShareButton>
      <LinkedinShareButton url={fullUrl} disabled={isLoading}>
        <LinkedinIcon size={20} round />
      </LinkedinShareButton>
      <WhatsappShareButton url={fullUrl} disabled={isLoading}>
        <WhatsappIcon size={20} round />
      </WhatsappShareButton>
      <TelegramShareButton url={fullUrl} disabled={isLoading}>
        <TelegramIcon size={20} round />
      </TelegramShareButton>
      <RedditShareButton url={fullUrl} disabled={isLoading}>
        <RedditIcon size={20} round />
      </RedditShareButton>
    </div>
  );
}

export default SocialMedia;
