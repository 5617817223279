const TOKEN_COOKIE_NAME = 'CLIENT_USER_TOKEN';
const TOKEN_COOKIE_DURATION_IN_DAYS = 1;

const LANG_COOKIE_NAME = 'CLIENT_USER_LANG';
const LAN_COOKIE_DURATION_IN_DAYS = 1000;

export function setUserLanguageInCookie(lang: string) {
    const currentDate = new Date();
    currentDate.setTime(currentDate.getTime() + (LAN_COOKIE_DURATION_IN_DAYS * 24 * 60 * 60 * 1000));
    const expires = `expires=${currentDate.toUTCString()}`;
  
    document.cookie = `${LANG_COOKIE_NAME}=${lang};${expires};path=/`;
}

export function getUserLanguageInCookie() {
    const name = `${LANG_COOKIE_NAME}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
  
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
  
      while (c.charAt(0) === ' ') { c = c.substring(1); }
  
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  
    return '';
  }

export function setUserTokenInCookie(userToken: string) {
    const currentDate = new Date();
    currentDate.setTime(currentDate.getTime() + (TOKEN_COOKIE_DURATION_IN_DAYS * 24 * 60 * 60 * 1000));
    const expires = `expires=${currentDate.toUTCString()}`;
  
    document.cookie = `${TOKEN_COOKIE_NAME}=${userToken};${expires};path=/`;
}

export function getUserTokenInCookie() {
    const name = `${TOKEN_COOKIE_NAME}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
  
    for (let i = 0; i < ca.length; i += 1) {
      let c = ca[i];
  
      while (c.charAt(0) === ' ') { c = c.substring(1); }
  
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  
    return '';
  }

export function deleteUserTokenFromCookie() {
    document.cookie = `${TOKEN_COOKIE_NAME}= ;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
}