export const API_ENDPOINTS = {
    signIn: 'https://my.inzo.co/api/login',
    loginToken: 'https://my.inzo.co/api/login-token',
    recentTradingActivity: 'https://my.inzo.co/api/account/recent-trading-activity',
    support: 'https://my.inzo.co/api/support',
    competition: 'https://my.inzo.co/api/competition',
    account: 'https://my.inzo.co/api/account',
    personalInfo: 'https://my.inzo.co/api/personal-info',
    editMyPassword: 'https://my.inzo.co/api/personal-info/change-password',
    liveAccount: 'https://my.inzo.co/api/account/open-trading',
    demoAccount: 'https://my.inzo.co/api/account/open-demo',
    ibAccount: 'https://my.inzo.co/api/account/open-ib',
    accountChangePassword: 'https://my.inzo.co/api/account/%accountId%/change-password',
    dayOfWeek: 'https://my.inzo.co/api/day-of-week',
    tradingHistory: 'https://my.inzo.co/api/account/%accountId%/trading-history/%from%/%to%',
    partnerTraders: 'https://my.inzo.co/api/partner/%accountId%/traders/%from%/%to%',
    partneribs: 'https://my.inzo.co/api/partner/%accountId%/ibs/%from%/%to%',
    withdraw: 'https://my.inzo.co/api/account/withdraw-funds/wallet',
    transferFunds: 'https://my.inzo.co/api/account/transfer-funds',
    ninjacharge: 'https://my.inzo.co/api/deposit/ninjacharge',
    cryptoDeposit: 'https://my.inzo.co/api/deposit/crypto-deposit',
    forgotPassword: 'https://my.inzo.co/api/request-password-reset',
    resetPassword: 'https://my.inzo.co/api/reset-password',
    documents: 'https://my.inzo.co/api/documents',
}