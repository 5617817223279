import { LanguagePhrases } from "../models/LanguagePhrases";

export const englishPhrases: LanguagePhrases = {
    home: "Home",
    downloads: "Downloads",
    support: "Support",
    topTraders: "Top Traders",
    myProfile: "My Profile",
    myDocuments: "My Documents",
    myAccounts: "My Accounts",
    openAccount: "Open Account",
    liveAccount: "Live Account",
    demoAccount: "Demo Account",
    ibAccount: "IB Account",
    depositAndWithdraw: "Deposit & Withdraw",
    deposit: "Deposit",
    withdraw: "Withdraw",
    transfer: "Transfer",
    trading: "Trading",
    tradingHistory: "Trading History",
    platformPasswords: "Platform Passwords",
    agentPortal: "Agent Portal",
    promotions: "Promotions",
    clients: "Clients",
    secondaryAgents: "Secondary Agents",
    myDetails: "My Details",
    changePassword: "Change Password",
    myDetailsForm: "My Details Form",
    changePasswordForm: "Change Password Form",
    firstName: "First Name",
    surname: "Surname",
    email: "Email",
    phone: "Phone",
    address: "Address",
    city: "City",
    zip: "Zip",
    state: "State",
    country: "Country",
    password: "Password",
    confirmPassword: "Confirm Password",
    updateDetails: "Update Details",
    updatePassword: "Update Password",
    fileType: "File Type",
    status: "Status",
    uploadDate: "Upload Date",
    preview: "Preview",
    upload: "Upload",
    uploadDocuments: "Upload Documents",
    mt5LiveAccounts: "MT5 Live Accounts",
    mt5DemoAccounts: "MT5 Demo Accounts",
    cTraderLiveAccounts: "cTrader Live Accounts",
    cTraderDemoAccounts: "cTrader Demo Accounts",
    youDontHaveAccountsOfThisType: "You don't have accounts of this type",
    number: "Number",
    group: "Group",
    balance: "Balance",
    currency: "Currency",
    leverage: "Leverage",
    createdAt: "Created At",
    openLiveAccount: "Open Live Account",
    liveAccountForm: "Live Account Form",
    platform: "Platform",
    liveAccountType: "Live Account Type",
    accountLaverage: "Account Laverage",
    agreeOnThe: "Agree on the",
    termsAndConditions: "Terms and conditions",
    createNewAccount: "Create New Account",
    openDemoAccount: "Open Demo Account",
    demoAccountForm: "Demo Account Form",
    demoAccountType: "Demo Account Type",
    openIbAccount: "Open IB Account",
    ibAccountForm: "IB Account Form",
    writeYourServicesHere: "Write your services here",
    depositToYourAccounts: "Deposit To Your Accounts",
    depositInDigitalCurrencies: "Deposit In Digital Currencies",
    depositUsingWallets: "Deposit Using Wallets",
    cashWithdraw: "Cash Withdraw",
    withdrawUsingWallets: "Withdraw Using Wallets",
    walletsWithdrawal: "Wallets Withdrawal",
    newAccountWasCreated: "New account was created",
    somethingWentWrongWithCreatingNewAccount: "Something went wrong with creating a new account",
    platformShoudntBeEmpty: "Platform shoudn't be empty",
    accountTypeShoudntBeEmpty: "Account type shoudn't be empty",
    accountLeverageShoudntBeEmpty: "Account leverage shoudn't be empty",
    firstNameFieldShouldNotBeEmpty: "First Name field should not be empty",
    surnameFieldShouldNotBeEmpty: "Surname field should not be empty",
    emailFieldShouldNotBeEmpty: "Email field should not be empty",
    pleaseMakeSureTheEmailIsValid: "Please make sure the email is valid",
    accountIsRequired: "Account field should not be empty",
    amountIsRequired: "Ammount field should not be empty",
    currencyIsRequired: "Currency field should not be empty",
    minimumValueIs: "Minimum valueIs",
    sendDeposit: "Send Deposit",
    somethingWentWrongWithTheDeposit: "Something went wrong with the deposit",
    accountLeverage: "Account Leverage",
    walletIsRequired: "Wallet is required",
    wallet: "Wallet",
    uploadConfirmation: "Upload Confirmation",
    somethingWentWrongWithLoadingTheAccounts: "Something went wrong with loading the accounts",
    theDepositWasSentSuccessfully: "The deposit was sent successfully",
    depositUsing: "Deposit using",
    desktopWindows: "Desktop Windows",
    desktopMacOs: "Desktop MacOs",
    mobileIOS: "Mobile iOS",
    mobileAndroid: "Mobile Android",
    somethingWentWrongWithFetchingTheCompanyDetails: "Something went wrong with fetching the company details",
    somethingWentWrongWithResetingThePassword: "Something went wrong with reseting the password",
    resetEmailWasSent: "Reset URL was sent to your email. Please use the URL to reset your password",
    welcomeTo: "Welcome to",
    clietnPortal: "Client portal",
    sendRequest: "Send request",
    goBack: "Go back",
    somethingWentWrongWithFetchingTheRecentTrades: "Something went wrong with fetching the recent trades",
    recentTradingActivity: "Recent Trading Activity",
    problemsWithLoadingTradingActivities: "There are some problems with loading the recent trading activity",
    servicesFieldShouldNotBeEmpty: "Services field should not be empty",
    createNewIbAccount: "Create New IB Account",
    somethingWentWrongWithLoadingTheTrades: "Something went wrong with loading the trades",
    myTraders: "My Traders",
    myTradersControlForm: "My Traders Control Form",
    fromDateIsRequired: "From Date is required",
    toDateIsRequired: "To Date is required",
    fromDate: "From Date",
    toDate: "To Date",
    theFromDateShouldBeBeforeToDate: "The From Date should be before To Date",
    getMyTraders: "Get my traders",
    searchInUsersTable: "Search in the table",
    somethingWentWrongWithLoadingTheSecondaryAgents: "Something went wrong with loading the secondary agents",
    mySecondaryAgents: "My secondary agents",
    mySecondaryAgentsControlForm: "My secondary agents control form",
    getMySecondaryAgents: "Get my secondary agents",
    accountTypeShouldNotBeEmpty: "Account type should not be empty",
    accountLeverageShouldNotBeEmpty: "Account leverage should not be empty",
    thereIsProblemWithLoadingListCompetition: "There are some problems with loading the list of competition",
    ibAccounts: "IB Accounts",
    youDoNotHaveAccountsOfThisType: "You do not have accounts of this type",
    somethingWentWrongWithLoadingTheDocuments: "Something went wrong with loading the documents",
    takeLivePictureOfTheDocument: "Take a live picture of the document",
    somethingWentWrongWithLoadingPrfileData: "Something went wrong with loading the the profile data",
    yourProfileDetailsGotUpdated: "Your profile details got updated",
    somethingWentWrongWithUpdatingPrfileData: "Something went wrong with updating the the profile data",
    phoneFieldShouldNotBeEmpty: "Phone field should not be empty",
    pleaseMakeSureThePhoneNumberIsValid: "Please make sure the phone number is valid",
    addressFieldShouldNotBeEmpty: "Address field should not be empty",
    cityFieldShouldNotBeEmpty: "City field should not be empty",
    zipFieldShouldNotBeEmpty: "Zip field should not be empty",
    stateFieldShouldNotBeEmpty: "State field should not be empty",
    countryFieldShouldNotBeEmpty: "Country field should not be empty",
    pleaseMakeSurePasswordIsValid: "Please make sure your password includes at least one capital letter, small letter, special character and the length of the password should be at least 8",
    twoPasswordsDoNotMatch: "The two passwords must be the same",
    pageNotFound: "Page Not Found",
    makeSureYouUseValidPages: "Please make sure you use only valid pages",
    GoToHomePage: "Go to Home",
    somethingWentWrongWithUpdatingPasswordForThisAccount: "Something went wrong with updating the password for this account",
    passwordType: "Password Type",
    passwordTypeIsRequired: "Password Type is required",
    passwordIsRequired: "Password is required",
    confirmPasswordIsRequired: "Confirm Password is required",
    clickToCopy: "Click to copy",
    myPromotionalMaterials: "My Promotional Materials",
    myPromotionalMaterialsControlForm: "My Promotional Materials Control Form",
    account: "Account",
    myReferalLink: "My referal link",
    myTestReferalLink: "My demo referal link",
    redirectToSignInPage: "Redirect to Sign In page",
    resetPassword: "Reset password",
    somethingWentWrongWithSigningIn: "Something went wrong with signing in",
    signIn: "Sign In",
    forgotPassword: "Forgot Password",
    somethingWentWrongWithSendingYourMessage: "Something went wrong with sending your question",
    contactOurSupport: "Contact our support",
    questionCategory: "Question Category",
    questionCategoryShouldNotBeEmpty: "Question category should not be empty",
    changeTheAgentOfYourAccount: "Change the agent of your account",
    changeThePasswordForYourTradingAccount: "Change the password for your trading account",
    changeTheMarginOfYourAccount: "Change the margin of your account",
    writeYourQuestionHere: "Write your question here",
    questionFieldShouldNotBeEmpty: "Question field should not be empty",
    sendQuestion: "Send Question",
    yourQuestionSentSuccessfully: "Your question was sent successfully",
    somethingWentWrongWithLoadingTheListOfTopTraders: "Something went wrong with loading the list of top traders",
    somethingWentWrongWithLoadingTheListOfRecentTrades: "Something went wrong with loading the list of recent trades",
    tradingHistoryControlForm: "Trading History Control Form",
    somethingWentWrongWithThisTransfer: "Something went wrong with this transfer",
    trasnferBetweenAccounts: "Trasnfer Between Accounts",
    trasnferBetweenAccountsForm: "Trasnfer Between Accounts Form",
    fromAccount: "From Account",
    toAccount: "To Account",
    shouldNotBeTheSameAsToAccount: "Should not be the same as To Account",
    shouldNotBeTheSameAsFromAccount: "Should not be the same as From Account",
    theAmmountShouldBeAtLeast: "The ammount should be at least",
    transferInfo: "Please ensure that you have enough free margin to hold any open positions you may have. We will not be liable if your margin level falls below the minimum level. This may cause all your open positions to be closed automatically.",
    somethingWentWrongWithTheWithdraw: "Something went wrong with the withdraw",
    withdrawMoeny: "Withdraw Moeny",
    andMaximumValueIs: "and maximum value is",
    withdrawMethod: "Withdraw method",
    methodIsRequired: "Method is required",
    leaveNote: "Leave a note",
    somethingWentWrongWithLoadingTheWeekDay: "Something went wrong with loading the week day",
    pleaseBeAwareThatYouCantWithdrawMoenyOnSaturday: "Please be aware that you can't withdraw moeny on Saturday",
    walletWithdawal: "Wallet withdawal",
    somethingWentWrongWithLoadingTheUserDetails: "Something went wrong with loading the user details",
    yourAccountIsInactivePleaseContactYourAdminToActivateYourIt: "Your account is inactive. Please contact your Admin to activate your it",
    footerContent: "Risk Warning: Forex and CFDs are leveraged products, carry a high level of risk and may not be suitable for all investors. You should not risk more than you are prepared to lose. Before deciding to trade, please ensure you understand the risks involved and take into account your level of experience. Seek independent advice if necessary.",
    allRightsReserved: "All rights reserved.",
    areYouSureYouWantToLogOut: "Are you sure you want to log out?",
    yes: "YES",
    no: "NO",
    profit: "Profit",
    name: "Name",
    time: "Time",
    deal: "Deal",
    order: "Order",
    position: "Position",
    symbol: "Symbol",
    type: "Type",
    access: "Access",
    volume: "Volume",
    price: "Price",
    reason: "Reason",
    commission: "Commission",
    swap: "Swap",
    comment: "Comment",
    idFront: "ID front side",
    idBack: "ID back side",
    addressFront: "Address front side",
    addressBack: "Address back side",
    amount: "Amount",
    getTrades: "Get trades",
    sendMoenyTo: "Send moeny to",
    followTheInstructions: "Follow the instructions",
    goToPayeerApp: "Go to Payeer app",
    sendTheMoneyToOurWalletAbove: "Send the money to our wallet above",
    clickConfirmTransfer: "Click Confirm Transfer",
    takeAScreenshotAndUploadItToTheConfirmTransferField: "Take a screenshot and upload it to the Confirm Transfer field",
    goToZainCashApplication: "Go to Zain Cash application",
    goToTheWithdrawOption: "Go to the withdraw option",
    writeOurAgencyNumberAbove: "Write our agency number above",
    goToPerfectMoneyApplication: "Go to Perfect Money application",
    callTheNumberAboveToInquireAboutTheNearestWesternUnionCenter: "Call the number above to inquire about the nearest Western Union center",
    transferTheFunds: "Transfer the funds",
    callTheNumberAboveToInquireAboutTheNearestMoneyExchangeToTransferMoney: "Call the number above to inquire about the nearest money exchange to transfer money",
    takeAPictureOfTheTransferReceiptAndAttachItInTheTransferProofField: "Take a picture of the transfer receipt and attach it in the Transfer Proof field",
    goToFastPayApp: "Go to FastPay app",
    goToTheWithdrawalOption: "Go to the withdrawal option",
    typeOurAgencyNumberAboveThenWriteInTheFieldBelowTheAmountYouWantToTransfer: "Type our agency number above, then write in the field below the amount you want to transfer",
    clickConfirmAndThenEnterYourPassword: "Click Confirm and then enter your password",
    thenClickOnViewInvoiceTakeAScreenshotAndAttachItAsProof: "Then click on View Invoice, take a screenshot and attach it as proof",
    goToAsiaHawalaApp: "Go to Asia Hawala app",
    goToTransfers: "Go to transfers",
    writeTheNumberAboveThenBelowItTheMoneyYouWantToTransfer: "Write the number above, then below it the money you want to transfer",
    clickConfirmTransferTakeAScreenshotAndUploadItToTheConfirmTransferField: "Click Confirm Transfer, take a screenshot and upload it to the Confirm Transfer field",
    cryptoDeposit: "Crypto Deposit",
    processOrder: "Process Order",
    ERC20PleaseNoteThatWeDoNotReceiveFundsUsingTheBEP20Method: "ERC20 Please note that we do not receive funds using the (BEP20) method.",
    newPassword: "New password",
    currentPassword: "Current password",
    copy: "Copy",
    total: "Total",
}