import { ConfigProvider } from 'antd';
import React from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { initialMainState } from './assets/comfig/initial-main-state';
import { getProjectTheme } from './helpers/getProjectTheme';
import { MainState } from './models/main-state';
import Views from './routes/Views';
import { MainContext } from './store/MainContext';
import { getUserLanguageInCookie } from './helpers/cookiesHandler';
import { arabicPhrases } from './languages/arabicPhrases';
import { englishPhrases } from './languages/englishPhrases';
import { frenchPhrases } from './languages/frenchPhrases';
import { polishPhrases } from './languages/polishPhrases';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: englishPhrases },
    ar: { translation: arabicPhrases },
    fr: { translation: frenchPhrases },
    pl: { translation: polishPhrases },
  },
  lng: getUserLanguageInCookie(),
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
});

function App() {
  const [mainState, setMainState] = React.useState<MainState>(initialMainState);

  return (
    <ConfigProvider theme={getProjectTheme(mainState)}>
      <MainContext.Provider value={{ mainState, setMainState }}>
        <BrowserRouter>
          <Views />
        </BrowserRouter>
      </MainContext.Provider>
    </ConfigProvider>
  );
}

export default App;
