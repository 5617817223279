import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import { MainState } from '../../models/main-state';
import { initialTheme } from './initial-theme';
import { initialUser } from './initial-user';

export const initialMainState: MainState = {
    isLoggedIn: Boolean(getUserTokenInCookie()),
    isDetailsLoading: false,
    isCompanyLoading: false,
    ...initialUser,
    ...initialTheme,
}