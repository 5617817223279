import { LanguagePhrases } from "../models/LanguagePhrases";

export const arabicPhrases: LanguagePhrases = {
    home: "الرئيسية",
    downloads: "التحميلات",
    support: "الدعم",
    topTraders: "أفضل المتداولين",
    myProfile: "ملفي الشخصي",
    myDocuments: "مستنداتي",
    myAccounts: "حساباتي",
    openAccount: "افتح حساب",
    liveAccount: "حساب حقيقي",
    demoAccount: "حساب تجريبي",
    ibAccount: "حساب الوكيل",
    depositAndWithdraw: "إيداع و سحب",
    deposit: "إيداع",
    withdraw: "سحب",
    transfer: "تحويل",
    trading: "تداول",
    tradingHistory: "تاريخ التداول",
    platformPasswords: "كلمات المرور",
    agentPortal: "بوابة الوكيل",
    promotions: "العروضات",
    clients: "العملاء",
    secondaryAgents: "الوكلاء الثانويون",
    myDetails: "تفاصيلي",
    changePassword: "تغيير كلمة المرور",
    myDetailsForm: "نموذج التفاصيل الخاصة بي",
    changePasswordForm: "نموذج تغيير كلمة المرور",
    firstName: "الاسم الأول",
    surname: "اسم العائلة",
    email: "بريد إلكتروني",
    phone: "هاتف",
    address: "عنوان",
    city: "مدينة",
    zip: "zip",
    state: "ولاية",
    country: "دولة",
    password: "كلمة المرور",
    confirmPassword: "تأكيد كلمة المرور",
    updateDetails: "تحديث التفاصيل",
    updatePassword: "تحديث كلمة السر",
    fileType: "نوع الملف",
    status: "حالة",
    uploadDate: "تاريخ الرفع",
    preview: "معاينة",
    upload: "رفع",
    uploadDocuments: "رفع المستندات",
    mt5LiveAccounts: "MT5 الحسابات الحقيقية",
    mt5DemoAccounts: "MT5 الحسابات التجريبية",
    cTraderLiveAccounts: "cTrader الحسابات الحقيقية",
    cTraderDemoAccounts: "cTrader الحسابات التجريبية",
    youDontHaveAccountsOfThisType: "ليس لديك حسابات من هذا النوع",
    number: "رقم",
    group: "مجموعة",
    balance: "توازن",
    currency: "عملة",
    leverage: "الرافعة",
    createdAt: "أنشئت في",
    openLiveAccount: "إفتح حساب حقيقي",
    liveAccountForm: "نموذج حساب حقيقي",
    platform: "منصة",
    liveAccountType: "نوع الحساب الحقيقي",
    accountLaverage: "رافعة الحساب",
    agreeOnThe: " أوافق على",
    termsAndConditions: "الأحكام والشروط",
    createNewAccount: "انشاء حساب جديد",
    openDemoAccount: "افتح حساب تجريبي",
    demoAccountForm: "نموذج حساب تجريبي",
    demoAccountType: "نوع الحساب التجريبي",
    openIbAccount: "افتح حساب IB",
    ibAccountForm: "نموذج حساب الوكيل",
    writeYourServicesHere: "اكتب خدماتك هنا",
    depositToYourAccounts: "الإيداع في حساباتك",
    depositInDigitalCurrencies: "الإيداع بالعملات الرقمية",
    depositUsingWallets: "الإيداع باستخدام المحافظ",
    cashWithdraw: "سحب نقود",
    withdrawUsingWallets: "سحب باستخدام المحافظ",
    walletsWithdrawal: "سحب بواسطة المحافظ",
    newAccountWasCreated: "تم إنشاء حساب جديد",
    somethingWentWrongWithCreatingNewAccount: "حدث خطأ ما في إنشاء حساب جديد",
    platformShoudntBeEmpty: "يجب ألا يكون حقل المنصة فارغًا",
    accountTypeShoudntBeEmpty: "يجب ألا يكون نوع الحساب فارغًا",
    accountLeverageShoudntBeEmpty: "يجب ألا تكون الرافعة المالية للحساب فارغة",
    firstNameFieldShouldNotBeEmpty: "يجب ألا يكون حقل الاسم الأول فارغًا",
    surnameFieldShouldNotBeEmpty: "يجب ألا يكون حقل اللقب فارغًا",
    emailFieldShouldNotBeEmpty: "يجب ألا يكون حقل البريد الإلكتروني فارغًا",
    pleaseMakeSureTheEmailIsValid: "يرجى التأكد من صحة البريد الإلكتروني",
    accountIsRequired: "يجب ألا يكون حقل الحساب فارغًا",
    amountIsRequired: "يجب ألا يكون حقل المبلغ فارغًا",
    currencyIsRequired: "يجب ألا يكون حقل العملة فارغًا",
    minimumValueIs: "أدنى قيمة",
    sendDeposit: "إرسال الإيداع",
    somethingWentWrongWithTheDeposit: "حدث خطأ ما في الإيداع",
    accountLeverage: "الرافعة المالية للحساب",
    walletIsRequired: "المحفظة مطلوبة",
    wallet: "محفظة",
    uploadConfirmation: "تأكيد التحميل",
    somethingWentWrongWithLoadingTheAccounts: "حدث خطأ ما في تحميل الحسابات",
    theDepositWasSentSuccessfully: "تم إرسال الإيداع بنجاح",
    depositUsing: "الإيداع باستخدام",
    desktopWindows: "ويندوز سطح المكتب",
    desktopMacOs: "ماك سطح المكتب",
    mobileIOS: "iOS المحمول",
    mobileAndroid: "موبايل أندرويد",
    somethingWentWrongWithFetchingTheCompanyDetails: "حدث خطأ في تحميل تفاصيل الشركة",
    somethingWentWrongWithResetingThePassword: "حدث خطأ ما في إعادة تعيين كلمة المرور",
    resetEmailWasSent: "تم إرسال إعادة تعيين URL إلى بريدك الإلكتروني. الرجاء استخدام URL لإعادة تعيين كلمة المرور الخاصة بك",
    welcomeTo: "مرحبا بك",
    clietnPortal: "بوابة العميل",
    sendRequest: "ارسل الطلب",
    goBack: "إرجع",
    somethingWentWrongWithFetchingTheRecentTrades: "حدث خطأ ما في تحميل التداولات الأخيرة",
    recentTradingActivity: "نشاط التداول الأخير",
    problemsWithLoadingTradingActivities: "هناك بعض المشاكل في تحميل نشاط التداول الأخير",
    servicesFieldShouldNotBeEmpty: "يجب ألا يكون حقل الخدمات فارغًا",
    createNewIbAccount: "إنشاء حساب IB جديد",
    somethingWentWrongWithLoadingTheTrades: "حدث خطأ ما في تحميل التداولات",
    myTraders: "المتداولون",
    myTradersControlForm: "نموذج مراقبة المتداوليين الخاص بي",
    fromDateIsRequired: "التاريخ مطلوب",
    toDateIsRequired: "تاريخ مطلوب",
    fromDate: "من تاريخ",
    toDate: "إلى تاريخ",
    theFromDateShouldBeBeforeToDate: "يجب أن يكون تاريخ البدء قبل تاريخ النهاية",
    getMyTraders: "احصل على المتداولين",
    searchInUsersTable: "البحث في الجدول",
    somethingWentWrongWithLoadingTheSecondaryAgents: "حدث خطأ أثناء تحميل الوكلاء الثانويين",
    mySecondaryAgents: "الوكلائي الثانويين",
    mySecondaryAgentsControlForm: "نموذج التحكم في وكلائي الثانويين",
    getMySecondaryAgents: "احصل على وكالئي الثانويين",
    accountTypeShouldNotBeEmpty: "يجب ألا يكون نوع الحساب فارغًا",
    accountLeverageShouldNotBeEmpty: "يجب ألا تكون الرافعة المالية للحساب فارغة",
    thereIsProblemWithLoadingListCompetition: "هناك بعض المشاكل في تحميل قائمة المنافسة",
    ibAccounts: "IB حسابات",
    youDoNotHaveAccountsOfThisType: "ليس لديك حسابات من هذا النوع",
    somethingWentWrongWithLoadingTheDocuments: "حدث خطأ ما في تحميل المستندات",
    takeLivePictureOfTheDocument: "التقط صورة حية للمستند",
    somethingWentWrongWithLoadingPrfileData: "حدث خطأ أثناء تحميل بيانات الملف الشخصي",
    yourProfileDetailsGotUpdated: "تم تحديث تفاصيل ملفك الشخصي",
    somethingWentWrongWithUpdatingPrfileData: "حدث خطأ أثناء تحديث بيانات الملف الشخصي",
    phoneFieldShouldNotBeEmpty: "يجب ألا يكون حقل الهاتف فارغًا",
    pleaseMakeSureThePhoneNumberIsValid: "يرجى التأكد من صحة رقم الهاتف",
    addressFieldShouldNotBeEmpty: "يجب ألا يكون حقل العنوان فارغًا",
    cityFieldShouldNotBeEmpty: "يجب ألا يكون حقل المدينة فارغًا",
    zipFieldShouldNotBeEmpty: "يجب ألا يكون الحقل zip فارغًا",
    stateFieldShouldNotBeEmpty: "يجب ألا يكون حقل المنطقة فارغًا",
    countryFieldShouldNotBeEmpty: "يجب ألا يكون حقل البلد فارغًا",
    pleaseMakeSurePasswordIsValid: "يرجى التأكد من أن كلمة مرورك تتضمن على الأقل حرفًا كبيرًا وحرفًا صغيرًا وحرفًا خاصًا وأن يكون طول كلمة المرور 8 على الأقل وباللغة الإنكليزية",
    twoPasswordsDoNotMatch: "يجب أن تكون كلمتا المرور متطابقتين",
    pageNotFound: "الصفحة غير موجودة",
    makeSureYouUseValidPages: "من فضلك تأكد من أنك تستخدم صفحات صالحة فقط",
    GoToHomePage: "اذهب إلى الصفحة الرئيسية",
    somethingWentWrongWithUpdatingPasswordForThisAccount: "حدث خطأ أثناء تحديث كلمة المرور لهذا الحساب",
    passwordType: "نوع كلمة المرور",
    passwordTypeIsRequired: "نوع كلمة المرور مطلوب",
    passwordIsRequired: "كلمة المرور مطلوبة",
    confirmPasswordIsRequired: "تأكيد كلمة المرور مطلوب",
    clickToCopy: "انقر للنسخ",
    myPromotionalMaterials: "المواد الترويجية الخاصة بي",
    myPromotionalMaterialsControlForm: "نموذج مراقبة المواد الترويجية الخاصة بي",
    account: "حساب",
    myReferalLink: "رابط الإحالة الخاص بي",
    myTestReferalLink: "رابط الإحالة التجريبي الخاص بي",
    redirectToSignInPage: "إعادة التوجيه إلى صفحة تسجيل الدخول",
    resetPassword: "إعادة تعيين كلمة المرور",
    somethingWentWrongWithSigningIn: "حدث خطأ ما في تسجيل الدخول",
    signIn: "تسجيل الدخول",
    forgotPassword: "هل نسيت كلمة السر",
    somethingWentWrongWithSendingYourMessage: "حدث خطأ ما في إرسال سؤالك",
    contactOurSupport: "اتصل بفريق الدعم",
    questionCategory: "فئة السؤال",
    questionCategoryShouldNotBeEmpty: "يجب ألا تكون فئة السؤال فارغة",
    changeTheAgentOfYourAccount: "تغيير وكيل حسابك",
    changeThePasswordForYourTradingAccount: "قم بتغيير كلمة المرور لحساب التداول الخاص بك",
    changeTheMarginOfYourAccount: "قم بتغيير رافعة حسابك",
    writeYourQuestionHere: "اكتب سؤالك هنا",
    questionFieldShouldNotBeEmpty: "يجب ألا يكون حقل السؤال فارغًا",
    sendQuestion: "إرسال السؤال",
    yourQuestionSentSuccessfully: "تم إرسال سؤالك بنجاح",
    somethingWentWrongWithLoadingTheListOfTopTraders: "حدث خطأ ما أثناء تحميل قائمة كبار المتداولين",
    somethingWentWrongWithLoadingTheListOfRecentTrades: "حدث خطأ ما أثناء تحميل قائمة الصفقات الأخيرة",
    tradingHistoryControlForm: "نموذج تاريخ سجل التداول",
    somethingWentWrongWithThisTransfer: "حدث خطأ ما في هذا النقل",
    trasnferBetweenAccounts: "التحويل بين الحسابات",
    trasnferBetweenAccountsForm: "نموذج التحويل بين الحسابات",
    fromAccount: "من حساب",
    toAccount: "إلى حساب",
    shouldNotBeTheSameAsToAccount: "لا ينبغي أن يكون نفس الحساب الاخر",
    shouldNotBeTheSameAsFromAccount: "لا ينبغي أن يكون نفس الحساب الاخر",
    theAmmountShouldBeAtLeast: "يجب أن يكون المبلغ على الأقل",
    transferInfo: "يرجى التأكد من أنه لديك هامش حر كافٍ للاحتفاظ بأي مراكز مفتوحة قد تكون لديك. لن نكون مسؤولين إذا انخفض مستوى الهامش الخاص بك عن المستوى الأدنى. قد يتسبب هذا في إغلاق جميع صفقاتك المفتوحة تلقائيًا.",
    withdrawMoeny: "سحب الأموال",
    andMaximumValueIs: "والقيمة القصوى هي",
    withdrawMethod: "طريقة السحب",
    methodIsRequired: "الطريقة مطلوبة",
    leaveNote: "ترك ملاحظة",
    somethingWentWrongWithLoadingTheWeekDay: "حدث خطأ ما في تحميل يوم الأسبوع",
    pleaseBeAwareThatYouCantWithdrawMoenyOnSaturday: "يرجى العلم أنه لا يمكنك سحب الأموال يوم السبت",
    walletWithdawal: "سحب المحفظة",
    somethingWentWrongWithTheWithdraw: "حدث خطأ ما في الانسحاب",
    somethingWentWrongWithLoadingTheUserDetails: "حدث خطأ ما أثناء تحميل تفاصيل المستخدم",
    yourAccountIsInactivePleaseContactYourAdminToActivateYourIt: "حسابك غير مفعل. يرجى الاتصال بالمسؤول الخاص بك لتنشيطه الخاص بك",
    footerContent: "تحذير من المخاطر: الفوركس والعقود مقابل الفروقات هي منتجات ذات رافعة مالية وتحمل درجة عالية من المخاطر وقد لا تكون مناسبة لجميع المستثمرين. يجب ألا تخاطر بأكثر مما أنت على استعداد لخسارته. قبل اتخاذ قرار بالتداول ، يرجى التأكد من فهمك للمخاطر التي تنطوي عليها ومراعاة مستوى خبرتك. اطلب المشورة المستقلة إذا لزم الأمر.",
    allRightsReserved: "كل الحقوق محفوظة.",
    areYouSureYouWantToLogOut: "هل أنت متأكد أنك تريد تسجيل الخروج؟",
    yes: "نعم",
    no: "لا",
    profit: "الربح",
    name: "الإسم",
    time: "التوقيت",
    deal: "الصفقة",
    order: "الأمر",
    position: "الموقع",
    symbol: "الرمز",
    type: "النوع",
    access: "الدخول",
    volume: "القيمة",
    price: "السعر",
    reason: "السبب",
    commission: "العمولة",
    swap: "تبديل",
    comment: "تعليق",
    idFront: "الوجه الأمامي للهوية",
    idBack: "الوجه الخلفي للهوية",
    addressFront: "الوجه الأمامي للعنوان",
    addressBack: "الوجه الخلفي للعنوان",
    amount: "المبلغ",
    getTrades: "احصل على التداولات",
    sendMoenyTo: "أرسل الأموال إلى",
    followTheInstructions: "اتبع التعليمات",
    goToPayeerApp: "انتقل إلى تطبيق Payeer",
    sendTheMoneyToOurWalletAbove: "أرسل الأموال إلى محفظتنا أعلاه",
    clickConfirmTransfer: "انقر فوق تأكيد النقل",
    takeAScreenshotAndUploadItToTheConfirmTransferField: "التقط لقطة شاشة وقم بتحميلها في حقل تأكيد النقل",
    goToZainCashApplication: "انتقل إلى تطبيق زين كاش",
    goToTheWithdrawOption: "انتقل إلى خيار السحب",
    writeOurAgencyNumberAbove: "اكتب رقم وكالتنا أعلاه",
    goToPerfectMoneyApplication: "انتقل إلى تطبيق Perfect Money",
    callTheNumberAboveToInquireAboutTheNearestWesternUnionCenter: "اتصل بالرقم أعلاه للاستعلام عن أقرب مركز ويسترن يونيون",
    transferTheFunds: "تحويل الأموال",
    callTheNumberAboveToInquireAboutTheNearestMoneyExchangeToTransferMoney: "اتصل بالرقم أعلاه للاستعلام عن أقرب صرافة لتحويل الأموال",
    takeAPictureOfTheTransferReceiptAndAttachItInTheTransferProofField: "التقط صورة لإيصال التحويل وأرفقه في حقل إثبات التحويل",
    goToFastPayApp: "انتقل إلى تطبيق FastPay",
    goToTheWithdrawalOption: "اذهب إلى خيار السحب",
    typeOurAgencyNumberAboveThenWriteInTheFieldBelowTheAmountYouWantToTransfer: "اكتب رقم وكالتنا أعلاه، ثم اكتب في الحقل أسفل المبلغ الذي تريد تحويله",
    clickConfirmAndThenEnterYourPassword: "انقر فوق تأكيد ثم أدخل كلمة المرور الخاصة بك",
    thenClickOnViewInvoiceTakeAScreenshotAndAttachItAsProof: "ثم انقر فوق عرض الفاتورة، والتقط لقطة شاشة وأرفقها كدليل",
    goToAsiaHawalaApp: "انتقل إلى تطبيق Asia Hawala",
    goToTransfers: "اذهب إلى التحويلات",
    writeTheNumberAboveThenBelowItTheMoneyYouWantToTransfer: "اكتب الرقم أعلاه ، ثم أسفله الأموال التي تريد تحويلها",
    clickConfirmTransferTakeAScreenshotAndUploadItToTheConfirmTransferField: "انقر فوق تأكيد النقل، والتقط لقطة شاشة وتحميلها إلى حقل تأكيد النقل",
    cryptoDeposit: "إيداع العملات المشفرة",
    processOrder: "معالجة الطلب",
    ERC20PleaseNoteThatWeDoNotReceiveFundsUsingTheBEP20Method: "أمر العملية ERC20 يرجى ملاحظة أننا لا نتلقى أموالًا باستخدام طريقة (BEP20).",
    newPassword: "كلمة المرور الجديدة",
    currentPassword: "كلمة السر الحالية",
    copy: "النسخ",
    total: "العدد الكلي",
}
