import { Popover, Button, Tooltip } from 'antd';
import { getUserLanguageInCookie, setUserLanguageInCookie } from '../../helpers/cookiesHandler';
import { getCountryFlag } from '../../helpers/getCountryFlag';
import ArFlag from '../../languages/flags/ar';
import EnFlag from '../../languages/flags/en';
import FrFlag from '../../languages/flags/fr';
import PlFlag from '../../languages/flags/pl';
import i18n from 'i18next';
import React from 'react';
import './Languages.scss';

function Languages() {
  const [selectedLanguage, setSelectedLanguage] = React.useState(getUserLanguageInCookie());

  const onLanguageChange = (lang: string) => {
    i18n.changeLanguage(lang);
    setUserLanguageInCookie(lang);
    setSelectedLanguage(lang);
    const body = document.body;
    body.className = '';
    body.classList.add(lang === 'ar' ? 'rtl' : 'ltr');
  };

  return (
    <Popover
      placement={selectedLanguage === 'ar' ? 'topLeft' : 'topRight'}
      trigger='click'
      content={
        <div className='flags-container'>
          <Tooltip placement='bottom' title='العربية'>
            <Button onClick={() => onLanguageChange('ar')} className='flag-button'>
              <ArFlag />
            </Button>
          </Tooltip>
          <Tooltip placement='bottom' title='English'>
            <Button onClick={() => onLanguageChange('en')} className='flag-button'>
              <EnFlag />
            </Button>
          </Tooltip>
          <Tooltip placement='bottom' title='Français'>
            <Button onClick={() => onLanguageChange('fr')} className='flag-button'>
              <FrFlag />
            </Button>
          </Tooltip>
          <Tooltip placement='bottom' title='polski'>
            <Button onClick={() => onLanguageChange('pl')} className='flag-button'>
              <PlFlag />
            </Button>
          </Tooltip>
        </div>
      }
    >
      <Button className='languages-button'>{getCountryFlag(selectedLanguage)}</Button>
    </Popover>
  );
}

export default Languages;
