import { Alert, Badge, Button, Card, Form, Input, Select } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import React from 'react';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { PASSWORD_VALIDATION } from '../../assets/comfig/validation-regex';
import { isPasswordInvalid } from '../../helpers/isUserValid';
import { useTranslation } from 'react-i18next';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import axios from 'axios';
import './PlatformsPassword.scss';

function PlatformsPassword() {
  const { t } = useTranslation();
  const [changePasswordForm] = Form.useForm();
  const [userAccountsOptions, setUserAccountsOptions] = React.useState([]);
  const [userAccounts, setUserAccounts] = React.useState<unknown[]>([]);
  const [changePasswordFormLoading, setChangePasswordFormLoading] = React.useState(false);
  const [changePasswordFormDisabled, setChangePasswordFormDisabled] = React.useState(true);
  const [userAccountsError, setUserAccountsError] = React.useState('');
  const [changePasswordError, setChangePasswordError] = React.useState('');
  const [changePasswordSuccess, setChangePasswordSuccess] = React.useState('');

  React.useEffect(() => {
    setChangePasswordFormLoading(true);

    axios
      .get(API_ENDPOINTS.account, {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((myAccounts: any) => {
        const tradingAccounts = myAccounts?.data?.data?.trading;

        const allAccounts = [
          ...tradingAccounts?.demo,
          ...tradingAccounts?.ib,
          ...tradingAccounts?.investor,
          ...tradingAccounts?.mam,
          ...tradingAccounts?.trading,
          ...tradingAccounts?.wallet,
        ];
        setUserAccounts(allAccounts);

        setUserAccountsOptions(
          allAccounts.map(
            (account: any) =>
              ({
                value: account?.id,
                label: `#${account?.number} ${account?.accountType.name} | ${account?.balance} ${account?.currency}`,
              } as never)
          )
        );
        setChangePasswordFormLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setUserAccountsError(t<string>('somethingWentWrongWithLoadingTheAccounts'));
        setChangePasswordFormLoading(false);
      });
  }, []);

  const onPasswordFormChange = () => {
    const changePasswordFormValues = changePasswordForm.getFieldsValue();

    setChangePasswordFormDisabled(
      isPasswordInvalid(changePasswordFormValues.password, changePasswordFormValues.confirmPassword) ||
        !Boolean(changePasswordFormValues.accountId) ||
        !Boolean(changePasswordFormValues.type)
    );
  };

  const onChangePasswordFormSubmit = React.useCallback(() => {
    const formValues = changePasswordForm.getFieldsValue();
    const accountLaverage =
      (userAccounts.find((account: any) => formValues.accountId === account?.id) as any)?.leverage ?? 1;

    setChangePasswordFormLoading(true);
    axios
      .post(
        API_ENDPOINTS.accountChangePassword.replace('%accountId%', formValues.accountId),
        {
          type: formValues.type,
          password: formValues.password,
          laverage: accountLaverage,
        },
        {
          headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
        }
      )
      .then((_: any) => {
        setChangePasswordFormLoading(false);
        setChangePasswordFormDisabled(true);
        setChangePasswordSuccess(t<string>('yourProfileDetailsGotUpdated'));
      })
      .catch((error) => {
        console.error(error);
        setChangePasswordError(t<string>('somethingWentWrongWithUpdatingPasswordForThisAccount'));
        setChangePasswordFormLoading(false);
        setChangePasswordFormDisabled(true);
      });
  }, []);

  return (
    <section className='platforms-password-container'>
      <h1>{t<string>('changePassword')}</h1>
      <Badge.Ribbon text={<SettingOutlined style={{ fontSize: 18 }} />}>
        <Card title={t<string>('changePasswordForm')}>
          <Form
            name='basic'
            form={changePasswordForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}
            onValuesChange={onPasswordFormChange}
            autoComplete='off'
            disabled={changePasswordFormLoading}
            onFinish={onChangePasswordFormSubmit}
          >
            <div className='platforms-password-content'>
              <Form.Item name='accountId' label='Account' rules={[{ required: true, message: 'Account is required!' }]}>
                <Select style={{ width: '100%' }} options={userAccountsOptions} loading={changePasswordFormLoading} />
              </Form.Item>
              <Form.Item
                name='type'
                label={t<string>('passwordType')}
                rules={[{ required: true, message: t<string>('passwordTypeIsRequired') }]}
              >
                <Select
                  style={{ width: '100%' }}
                  options={[
                    { label: 'Main', value: 1 },
                    { label: 'Investor', value: 2 },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={t<string>('password')}
                name='password'
                rules={[
                  { required: true, message: t<string>('passwordIsRequired') },
                  {
                    pattern: PASSWORD_VALIDATION,
                    message: t<string>('pleaseMakeSurePasswordIsValid'),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={t<string>('confirmPassword')}
                name='confirmPassword'
                rules={[
                  { required: true, message: t<string>('confirmPasswordIsRequired') },
                  {
                    pattern: PASSWORD_VALIDATION,
                    message: t<string>('pleaseMakeSurePasswordIsValid'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t<string>('twoPasswordsDoNotMatch')));
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 32 }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={changePasswordFormDisabled}
                  loading={changePasswordFormLoading}
                >
                  {t<string>('updatePassword')}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Card>
      </Badge.Ribbon>
      {userAccountsError ? (
        <div className='alert-container'>
          <Alert
            description={userAccountsError}
            type='error'
            showIcon
            closable
            onClose={() => setUserAccountsError('')}
          />
        </div>
      ) : undefined}
      {changePasswordError ? (
        <div className='alert-container'>
          <Alert
            description={changePasswordError}
            type='error'
            showIcon
            closable
            onClose={() => setChangePasswordError('')}
          />
        </div>
      ) : undefined}
      {changePasswordSuccess ? (
        <div className='alert-container'>
          <Alert
            description={changePasswordSuccess}
            type='success'
            showIcon
            closable
            onClose={() => setChangePasswordSuccess('')}
          />
        </div>
      ) : undefined}
    </section>
  );
}

export default PlatformsPassword;
