import { Alert, Button, Form, Input, InputNumber, Select } from 'antd';
import React from 'react';
import { DEPOSIT_CURENCIES, getAccountMinimumDeposit } from '../../../assets/comfig/deposit-ways';
import { API_ENDPOINTS } from '../../../assets/api/endpoints';
import { EMAIL_VALIDATION } from '../../../assets/comfig/validation-regex';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getUserTokenInCookie } from '../../../helpers/cookiesHandler';
import './BinPayDeposit.scss';

function BinPayDeposit({
  userAccountsOptions,
  userAccounts,
  closeModelSuccessHandler,
  errorHandler,
}: {
  userAccountsOptions: { labebl: string; value: string }[];
  userAccounts: any[];
  closeModelSuccessHandler: () => void;
  errorHandler: (error: string) => void;
}) {
  const { t } = useTranslation();
  const [depositForm] = Form.useForm();
  const [accountMinimumDeposit, setAccountMinimumDeposit] = React.useState<number | string>('');
  const [selectedAccount, setSelectedAccount] = React.useState<any>(null);
  const [depositFormDisabled, setDepositFormDisabled] = React.useState(true);
  const [depositFormLoading, setDepositFormLoading] = React.useState(false);
  const [depositFormError, setDepositFormError] = React.useState('');
  const [methodSelected, setMethodSelected] = React.useState('');

  const currencyOptions = React.useMemo(() => DEPOSIT_CURENCIES, []);

  const onAccountChange = () => {
    const account = userAccounts?.find((account) => account?.id === depositForm.getFieldsValue()?.accountId);
    setSelectedAccount(account);
    const minDeposit = getAccountMinimumDeposit(account?.accountType?.id || '');
    setAccountMinimumDeposit(minDeposit);
  };

  const onDepositFormChange = () => {
    const depositFormValues = depositForm.getFieldsValue();

    setDepositFormDisabled(
      depositFormValues?.ammount < accountMinimumDeposit ||
        !depositFormValues.email ||
        !EMAIL_VALIDATION.test(depositFormValues.email) ||
        !depositFormValues.currency ||
        selectedAccount === null
    );
  };

  const onDepositFormSubmit = React.useCallback(() => {
    const formValues = depositForm.getFieldsValue();
    setDepositFormLoading(true);

    axios
      .post(
        API_ENDPOINTS.ninjacharge,
        {
          firstName: '',
          lastName: '',
          email: formValues.email,
          account: selectedAccount,
          accountId: selectedAccount?.id,
          currency: formValues.currency,
          amount: formValues.ammount.toString(),
          merchant: 'binpay2',
          merchantId: 150,
          extMerchant: null,
        },
        {
          headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
        }
      )
      .then(() => {
        setDepositFormLoading(false);
        closeModelSuccessHandler();
      })
      .catch((error) => {
        console.error(error);
        errorHandler(t<string>('openDemoAccount'));
        setDepositFormLoading(false);
      });
  }, []);

  return (
    <section className='other-deposit-container'>
      <Form
        name='basic'
        form={depositForm}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}
        onValuesChange={() => onDepositFormChange()}
        autoComplete='off'
        onFinish={onDepositFormSubmit}
      >
        <Form.Item
          name='accountId'
          label={t<string>('account')}
          rules={[{ required: true, message: t<string>('accountFieldShouldNotBeEmpty') }]}
        >
          <Select style={{ width: '100%' }} options={userAccountsOptions} onChange={onAccountChange} />
        </Form.Item>
        <Form.Item
          label={t<string>('email')}
          name='email'
          rules={[
            { required: true, message: t<string>('emailFieldShouldNotBeEmpty') },
            { pattern: EMAIL_VALIDATION, message: t<string>('pleaseMakeSureTheEmailIsValid') },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='ammount'
          label={t<string>('amount')}
          rules={[
            { required: true, message: t<string>('ammountFieldShouldNotBeEmpty') },
            () => ({
              validator(_, value) {
                return value >= accountMinimumDeposit
                  ? Promise.resolve()
                  : Promise.reject(`${t<string>('minimumValueIs')}: ${accountMinimumDeposit}`);
              },
            }),
          ]}
        >
          <InputNumber
            defaultValue={accountMinimumDeposit}
            style={{ width: '100%' }}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value: any) => value?.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item
          name='currency'
          label={t<string>('currency')}
          rules={[{ required: true, message: t<string>('currencyIsRequired') }]}
        >
          <Select
            style={{ width: '100%' }}
            options={currencyOptions}
            onChange={(method) => setMethodSelected(method)}
          />
        </Form.Item>
        {methodSelected === 'eth' ? (
          <Alert
            description={t<string>('ERC20PleaseNoteThatWeDoNotReceiveFundsUsingTheBEP20Method')}
            type='info'
            showIcon
            style={{ padding: 4, marginBottom: 8, fontSize: 12 }}
          />
        ) : null}
        <Button type='primary' htmlType='submit' disabled={depositFormDisabled} loading={depositFormLoading}>
          {t<string>('processOrder')}
        </Button>
      </Form>
      {depositFormError ? (
        <div className='alert-container'>
          <Alert
            description={depositFormError}
            type='error'
            showIcon
            closable
            onClose={() => setDepositFormError('')}
          />
        </div>
      ) : undefined}
    </section>
  );
}

export default BinPayDeposit;
