export const WITHDRAW_METHODS = [
    {label: 'Ethereum', value: '041e09a0-ec7c-11ec-be15-450b199bc6ab'},
    {label: 'Fastpay', value: '0fd55e20-ced9-11ed-b2c0-b1d90f5c440c'},
    {label: 'Asia Hawala', value: '130820f0-2ac0-11ec-bc32-5dd935fa1ccc'},
    {label: 'Zain Cash', value: '17a84ee0-2a71-11ec-bc32-5dd935fa1ccc'},
    {label: 'Perfect Money', value: '201f6aa0-2ac0-11ec-bc32-5dd935fa1ccc'},
    {label: 'Payeer', value: '37f3e160-2ac0-11ec-bc32-5dd935fa1ccc'},
    {label: 'Money Transfer', value: '459e4350-2ac0-11ec-bc32-5dd935fa1ccc'},
    {label: 'USDT', value: '4a9f5cd0-d421-11ec-80a2-2beb39a75dc1'},
    {label: 'Westren Union', value: '57a311c0-2b9c-11ec-b8e5-c1c85db5a4b5'},
    {label: 'Bitcoin', value: 'd2ad1410-ec80-11ec-be15-450b199bc6ab'},
];