import ArFlag from '../languages/flags/ar';
import EnFlag from '../languages/flags/en';
import FrFlag from '../languages/flags/fr';
import PlFlag from '../languages/flags/pl';

export function getCountryFlag(lang: string) {
  switch (lang) {
    case 'ar':
      return <ArFlag />;
    case 'fr':
      return <FrFlag />;
    case 'pl':
      return <PlFlag />;
    case 'en':
    default:
      return <EnFlag />;
  }
}
