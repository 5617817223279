import { initialTheme } from "../assets/comfig/initial-theme";
import { MainState } from "../models/main-state";

export function getProjectTheme(mainState: MainState) {
    return {
        token: {
          colorPrimary: mainState.colorPrimary || initialTheme.colorPrimary,
          colorPrimaryHover: mainState.colorPrimaryHover || initialTheme.colorPrimaryHover,
          colorError: mainState.colorError || initialTheme.colorError,
          colorSuccess: mainState.colorSuccess || initialTheme.colorSuccess,
          colorInfo: mainState.colorInfo || initialTheme.colorInfo,
          colorTextBase: mainState.colorTextBase || initialTheme.colorTextBase,
          colorBgLayout: mainState.colorBgLayout || initialTheme.colorBgLayout,
        },
        components: {
          Menu: {
            colorItemBg: mainState.colorBgMenu,
            colorSubItemBg: mainState.colorBgMenu,
          },
        },
    }
}