import {
  CheckCircleFilled,
  CheckCircleOutlined,
  UploadOutlined,
  EllipsisOutlined,
  ExclamationCircleFilled,
  CloseCircleFilled,
  EyeFilled,
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { format } from 'date-fns';
import { ReactNode } from 'react';
import { UserDocumentDetails } from '../../models/UserDocumentDetails';

function getFileTypeTitle(fileType: string, t: (value: string) => void) {
  switch (fileType) {
    case 'copy_of_id_front':
      return t('idFront');
    case 'copy_of_id_back':
      return t('idBack');
    case 'proof_of_address_front':
      return t('addressFront');
    case 'proof_of_address_back':
      return t('addressBack');
  }
}
function getFileStatusIcon(status: number, t: (value: string) => void) {
  switch (status) {
    case 1:
      return (
        <Tooltip placement='top' title={t('notUploaded') as ReactNode}>
          <EllipsisOutlined />
        </Tooltip>
      );
    case 2:
      return (
        <Tooltip placement='top' title={t('accepted') as ReactNode}>
          <CheckCircleFilled style={{ color: '#229f22' }} />
        </Tooltip>
      );
    case 3:
      return (
        <Tooltip placement='top' title={t('rejected') as ReactNode}>
          <CloseCircleFilled style={{ color: '#e34b4b' }} />
        </Tooltip>
      );
    case 4:
      return (
        <Tooltip placement='top' title={t('expired') as ReactNode}>
          <ExclamationCircleFilled style={{ color: '#ababab' }} />
        </Tooltip>
      );
    case 5:
      return (
        <Tooltip placement='top' title={t('waitingToBeUploaded') as ReactNode}>
          <CheckCircleOutlined style={{ color: '#229f22' }} />
        </Tooltip>
      );
  }
}

export const getDocumentsColumns = (
  onUploadDocument: (documentDetails: UserDocumentDetails) => void,
  t: (value: string) => void
) => {
  return [
    {
      title: t('fileType'),
      dataIndex: 'type',
      fixed: 'left' as any,
      render: (fileType: string) => getFileTypeTitle(fileType, t),
    },
    {
      title: t('status'),
      dataIndex: 'status',
      render: (status: number) => getFileStatusIcon(status, t),
      align: 'center' as any,
    },
    {
      title: t('uploadDate'),
      dataIndex: 'createdAt',
      render: (createdAt: Date) => (createdAt ? format(new Date(createdAt), 'MM/dd/yyyy') : <EllipsisOutlined />),
      align: 'center' as any,
    },
    {
      title: t('preview'),
      dataIndex: 'file',
      render: (_: string, documentDetails: UserDocumentDetails) =>
        documentDetails.file && documentDetails.status === 2 ? (
          <Tooltip placement='top' title='Preview Document'>
            <a href={documentDetails.file} target='_blank'>
              <EyeFilled />
            </a>
          </Tooltip>
        ) : (
          <EllipsisOutlined />
        ),
      align: 'center' as any,
    },
    {
      title: t('upload'),
      dataIndex: 'upload',
      render: (_: string, documentDetails: UserDocumentDetails) =>
        documentDetails.status !== 2 ? (
          <UploadOutlined onClick={() => onUploadDocument(documentDetails)} />
        ) : (
          <EllipsisOutlined />
        ),
      align: 'center' as any,
    },
  ];
};
