import { TrophyOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Card, Checkbox, Form, Input } from 'antd';
import React from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { useTranslation } from 'react-i18next';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import './IbAccount.scss';

function IbAccount() {
  const { t } = useTranslation();
  const [ibAccountForm] = Form.useForm();
  const [ibAccountFormValid, setIbAccountFormValid] = React.useState(false);
  const [isIbAccountFormLoading, setIsIbAccountFormLoading] = React.useState(false);
  const [ibAccountSuccess, setIbAccountSuccess] = React.useState('');
  const [ibAccountError, setIbAccountError] = React.useState('');

  function onIbAccountFormChange(ibAccountValues: { agree: boolean; services: string }) {
    setIbAccountFormValid(Boolean(ibAccountValues.services) && Boolean(ibAccountValues.agree));
  }

  const onIbAccountFormSubmit = React.useCallback(() => {
    setIsIbAccountFormLoading(true);
    axios
      .post(
        API_ENDPOINTS.ibAccount,
        {
          ...ibAccountForm.getFieldsValue(),
          accountTypeId: 'ce598ff0-2855-11ec-9002-55ac8a3dc838',
        },
        { headers: { Authorization: `Bearer ${getUserTokenInCookie()}` } }
      )
      .then((_: any) => {
        setIsIbAccountFormLoading(false);
        setIbAccountSuccess('A new account was created');
      })
      .catch((error) => {
        console.error(error);
        setIbAccountError(t<string>('somethingWentWrongWithCreatingNewAccount'));
        setIsIbAccountFormLoading(false);
      });
  }, []);

  return (
    <>
      <section className='ibAccount-container'>
        <h1>{t<string>('openIbAccount')}</h1>
        <Badge.Ribbon text={<TrophyOutlined style={{ fontSize: 18 }} />}>
          <Card title={t<string>('ibAccountForm')}>
            <Form
              name='basic'
              form={ibAccountForm}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600, display: 'flex', flexDirection: 'column' }}
              onValuesChange={(_: any, ibAccountValues: { agree: boolean; services: string }) =>
                onIbAccountFormChange(ibAccountValues)
              }
              autoComplete='off'
              initialValues={{ text: '', type: '' }}
              onFinish={onIbAccountFormSubmit}
            >
              <Form.Item
                name='services'
                label={t<string>('writeYourServicesHere')}
                rules={[{ required: true, message: t<string>('servicesFieldShouldNotBeEmpty') }]}
              >
                <Input.TextArea />
              </Form.Item>
              <div style={{ display: 'flex' }}>
                <Form.Item name='agree' valuePropName='checked'>
                  <Checkbox style={{ marginRight: 8 }} />
                </Form.Item>
                <div style={{ marginTop: 4 }}>
                  <span className='no-wrap'>{t<string>('agreeOnThe')}</span>
                  <a href='https://my.inzo/assets/files/terms-and-conditions.pdf' target='_blank' className='no-wrap'>
                    {t<string>('termsAndConditions')}
                  </a>
                </div>
              </div>
              <Form.Item wrapperCol={{ offset: 8, span: 32 }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={!ibAccountFormValid}
                  loading={isIbAccountFormLoading}
                >
                  {t<string>('createNewIbAccount')}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Badge.Ribbon>
      </section>
      {ibAccountError ? (
        <div className='alert-container'>
          <Alert description={ibAccountError} type='error' showIcon closable onClose={() => setIbAccountError('')} />
        </div>
      ) : undefined}
      {ibAccountSuccess ? (
        <div className='alert-container'>
          <Alert
            description={ibAccountSuccess}
            type='success'
            showIcon
            closable
            onClose={() => setIbAccountSuccess('')}
          />
        </div>
      ) : undefined}
    </>
  );
}

export default IbAccount;
