import { Routes, Route, Navigate } from 'react-router-dom';
import DemoAccount from '../pages/DemoAccount/DemoAccount';
import Deposit from '../pages/Deposit/Deposit';
import Downloads from '../pages/Downloads/Downloads';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import Home from '../pages/Home/Home';
import IbAccount from '../pages/IbAccount/IbAccount';
import LiveAccount from '../pages/LiveAccount/LiveAccount';
import MyAccounts from '../pages/MyAccounts/MyAccounts';
import MyDocuments from '../pages/MyDocuments/MyDocuments';
import MyProfile from '../pages/MyProfile/MyProfile';
import NotFound from '../pages/NotFound/NotFound';
import PlatformsPassword from '../pages/PlatformsPassword/PlatformsPassword';
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import SignIn from '../pages/SignIn/SignIn';
import Support from '../pages/Support/Support';
import TopTraders from '../pages/TopTraders/TopTraders';
import TradingHistory from '../pages/TradingHistory/TradingHistory';
import Transfer from '../pages/Transfer/Transfer';
import Withdraw from '../pages/Withdraw/Withdraw';
import PromotionalMaterials from '../pages/PromotionalMaterials/PromotionalMaterials';
import IbMyClients from '../pages/IbMyClients/IbMyClients';
import IbMySecondaryAgents from '../pages/IbMySecondaryAgents/IbMySecondaryAgents';
import ProtectedRoutes from './ProtectedRoutes';
import Copy from '../pages/Copy/Copy';
import React from 'react';

function Views() {
  return (
    <Routes>
      <Route path='/sign-in' element={<SignIn />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route element={<ProtectedRoutes />}>
        {/* Default */}
        <Route path='/' element={<Navigate to='/home' />} />
        {/* Header Navigation */}
        <Route path='/home' element={<Home />} />
        <Route path='/downloads' element={<Downloads />} />
        <Route path='/support' element={<Support />} />
        <Route path='/top-traders' element={<TopTraders />} />
        <Route path='/my-profile' element={<MyProfile />} />
        <Route path='/my-documents' element={<MyDocuments />} />
        <Route path='/my-accounts' element={<MyAccounts />} />
        <Route path='/live-account' element={<LiveAccount />} />
        <Route path='/demo-account' element={<DemoAccount />} />
        <Route path='/ib-account' element={<IbAccount />} />
        <Route path='/withdraw' element={<Withdraw />} />
        <Route path='/deposit' element={<Deposit />} />
        <Route path='/transfer' element={<Transfer />} />
        <Route path='/trading-history' element={<TradingHistory />} />
        <Route path='/platforms-password' element={<PlatformsPassword />} />
        <Route path='/promotional-materials' element={<PromotionalMaterials />} />
        <Route path='/ib-my-clients' element={<IbMyClients />} />
        <Route path='/ib-my-secondary-agents' element={<IbMySecondaryAgents />} />
        <Route path='/copy' element={<Copy />} />
        <Route path='*' element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default React.memo(Views);
