import { DEPOSIT_WAYS } from "./deposit-ways";

export const DEPOSIT_METHODS = [
    { label: 'Payeer', value: DEPOSIT_WAYS.payeer },
    { label: 'Zain Cash', value: DEPOSIT_WAYS.zainCash },
    { label: 'Perfect Money', value: DEPOSIT_WAYS.perfectMoney },
    { label: 'Western Union', value: DEPOSIT_WAYS.westernUnion },
    { label: 'Bank Transfer', value: DEPOSIT_WAYS.bankTransfer },
    { label: 'Fastpay', value: DEPOSIT_WAYS.fastpay },
    { label: 'Asia Hawala', value: DEPOSIT_WAYS.asiaHawala },
];







