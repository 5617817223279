import { Alert, Badge, Button, Card, Form, Input, Result, Select, Table } from 'antd';
import generatePicker from 'antd/es/date-picker/generatePicker';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import React from 'react';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { TradeInfo } from '../../models/TradeInfo';
import { dateFormat } from '../../assets/comfig/dateFormat';
import { IdcardOutlined } from '@ant-design/icons';
import { getMyTradersColumns } from '../../assets/comfig/my-traders-columns';
import { filterTraders } from '../../helpers/filterTraders';
import { dateToSecondsConverter } from '../../helpers/dateToSecondsConverter';
import { useTranslation } from 'react-i18next';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import axios from 'axios';
import './IbMySecondaryAgents.scss';

const ONE_WEEK_AGO_MS = 604800000;

function IbMySecondaryAgents() {
  const { t } = useTranslation();
  const [ibMyClientsForm] = Form.useForm();
  const [userAccountsOptions, setUserAccountsOptions] = React.useState([]);
  const [userAccountsLoading, setUserAccountsLoading] = React.useState(false);
  const [userAccountsError, setUserAccountsError] = React.useState('');
  const [trades, setTrades] = React.useState<any[] | null>(null);
  const [initialTrades, setInitialTrades] = React.useState<any[] | null>(null);
  const [tradesError, setTradesError] = React.useState('');
  const [tradesLoading, setTradesLoading] = React.useState(false);
  const [tradesFormDisabled, setTradesFormDisabled] = React.useState(true);
  const DatePicker = generatePicker(dateFnsGenerateConfig);
  const todayDate = React.useMemo(() => new Date(), []);

  React.useEffect(() => {
    setUserAccountsLoading(true);

    axios
      .get(API_ENDPOINTS.account, {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((myAccounts: any) => {
        setUserAccountsOptions(
          myAccounts?.data?.data?.ib.map(
            (account: any) =>
              ({
                value: account?.id,
                label: `#${account?.number} ${account?.accountType.name} | ${account?.balance} ${account?.currency}`,
              } as never)
          )
        );
        setUserAccountsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setUserAccountsError(t<string>('somethingWentWrongWithLoadingTheAccounts'));
        setUserAccountsLoading(false);
      });
  }, []);

  const onGetMyIbAgentsFormSubmit = React.useCallback(() => {
    setTradesLoading(true);
    const ibMyClientsFormValues = ibMyClientsForm.getFieldsValue();

    axios
      .get(
        API_ENDPOINTS.partneribs
          .replace('%accountId%', ibMyClientsFormValues.accountId)
          .replace('%from%', dateToSecondsConverter(ibMyClientsFormValues.fromDate))
          .replace('%to%', dateToSecondsConverter(ibMyClientsFormValues.toDate)),
        {
          headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
        }
      )
      .then((ibMyClientsData: any) => {
        const myTraders = ibMyClientsData?.data?.data?.map((trade: TradeInfo, index: number) => ({
          ...trade,
          key: index,
        }));
        setTrades(myTraders);
        setInitialTrades(myTraders);
        setTradesLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setTradesError(t<string>('somethingWentWrongWithLoadingTheSecondaryAgents'));
        setTradesLoading(false);
      });
  }, []);

  const onTradeHistoryFormChange = () => {
    const ibMyClientsFormValues = ibMyClientsForm.getFieldsValue();

    const fromDateSec = ibMyClientsFormValues.fromDate?.getTime()
      ? Math.floor(ibMyClientsFormValues.fromDate?.getTime() / 1000)
      : 0;
    const toDateSec = ibMyClientsFormValues.toDate?.getTime()
      ? Math.floor(ibMyClientsFormValues.toDate?.getTime() / 1000)
      : 0;

    setTradesFormDisabled(!ibMyClientsFormValues.accountId || !fromDateSec || !toDateSec || toDateSec < fromDateSec);
  };

  const tradesColumns: any[] = getMyTradersColumns(t);

  const onSearch = (searchValue: string) => {
    searchValue
      ? setTrades((initialTrades || []).filter((trader) => filterTraders(trader, searchValue)))
      : setTrades(initialTrades);
  };

  return (
    <section className='my-traders-container'>
      <h1>{t<string>('mySecondaryAgents')}</h1>
      <Badge.Ribbon text={<IdcardOutlined style={{ fontSize: 18 }} />}>
        <Card title={t<string>('mySecondaryAgentsControlForm')}>
          <Form
            name='basic'
            form={ibMyClientsForm}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ display: 'flex', flexDirection: 'column', marginTop: '30px' }}
            onValuesChange={onTradeHistoryFormChange}
            autoComplete='off'
            disabled={userAccountsLoading}
            onFinish={onGetMyIbAgentsFormSubmit}
            initialValues={{
              accountId: null,
              fromDate: new Date(todayDate.getTime() - ONE_WEEK_AGO_MS),
              toDate: todayDate,
            }}
          >
            <div className='trade-history-content'>
              <Form.Item
                name='accountId'
                label={t<string>('account')}
                rules={[{ required: true, message: t<string>('accountIsRequired') }]}
              >
                <Select style={{ width: '100%' }} options={userAccountsOptions} loading={userAccountsLoading} />
              </Form.Item>
              <div className='date-pickers'>
                <Form.Item
                  label={t<string>('fromDate')}
                  name='fromDate'
                  rules={[
                    { required: true, message: t<string>('fromDateIsRequired') },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const doDateMs = getFieldValue('toDate')?.getTime();
                        if (!doDateMs || doDateMs >= value.getTime()) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t<string>('theFromDateShouldBeBeforeToDate')));
                      },
                    }),
                  ]}
                >
                  <DatePicker format={dateFormat} />
                </Form.Item>
                <Form.Item
                  label={t<string>('toDate')}
                  name='toDate'
                  rules={[
                    { required: true, message: t<string>('toDateIsRequired') },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const fromDateMs = getFieldValue('fromDate')?.getTime();
                        if (!fromDateMs || fromDateMs < value.getTime()) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t<string>('theFromDateShouldBeBeforeToDate')));
                      },
                    }),
                  ]}
                >
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </div>
            </div>
            <Form.Item wrapperCol={{ offset: 8, span: 32 }}>
              <Button type='primary' htmlType='submit' disabled={tradesFormDisabled} loading={tradesLoading}>
                {t<string>('getMySecondaryAgents')}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Badge.Ribbon>
      <div className='my-trades-table-container'>
        {tradesError ? (
          <Result status='warning' title={t<string>('problemsWithLoadingTradingActivities')} />
        ) : tradesLoading || trades ? (
          <>
            <Input.Search
              placeholder={t<string>('searchInUsersTable')}
              allowClear
              onSearch={onSearch}
              loading={userAccountsLoading}
              disabled={userAccountsLoading || (initialTrades?.length || 0) <= 0}
              style={{ width: 300, marginBottom: 12 }}
            />
            <>
              {trades!?.length > 0 ? (
                <h2>
                  {t<string>('total')}: {trades?.length}
                </h2>
              ) : null}
              <Table
                columns={tradesColumns as any}
                dataSource={trades || []}
                bordered
                loading={tradesLoading}
                pagination={{ pageSize: 15 }}
              />
            </>
          </>
        ) : null}
      </div>
      {userAccountsError ? (
        <div className='alert-container'>
          <Alert
            description={userAccountsError}
            type='success'
            showIcon
            closable
            onClose={() => setUserAccountsError('')}
          />
        </div>
      ) : undefined}
    </section>
  );
}

export default IbMySecondaryAgents;
