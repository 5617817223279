import React from 'react';
import { initialMainState } from '../assets/comfig/initial-main-state';
import { MainState } from '../models/main-state';

type Store = {
  mainState: MainState;
  setMainState: React.Dispatch<React.SetStateAction<MainState>>;
};

export const MainContext = React.createContext<Store>({ mainState: initialMainState } as Store);

export const useMainContext = () => React.useContext(MainContext);
