import { Timeline } from 'antd';
import { WindowsFilled, AppleFilled, AppleOutlined, AndroidFilled } from '@ant-design/icons';
import './Downloads.scss';
import { useTranslation } from 'react-i18next';

function Downloads() {
  const { t } = useTranslation();

  return (
    <section className='download-platforms'>
      <div>
        <h1>MT5 {t<string>('platform')}</h1>
        <Timeline
          items={[
            {
              color: '#af1e23',
              dot: <WindowsFilled style={{ fontSize: 30 }} />,
              children: (
                <a
                  href='https://download.mql5.com/cdn/web/inzo.llc/mt5/inzo5setup.exe'
                  target='_blank'
                  rel='noopener'
                  style={{ color: '#af1e23' }}
                >
                  {t<string>('desktopWindows')}
                </a>
              ),
            },
            {
              color: '#af1e23',
              dot: <AppleFilled style={{ fontSize: 30 }} />,
              children: (
                <a
                  href='https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg?utm_source=support.metaquotes.net&utm_campaign=download.mt5.macos'
                  target='_blank'
                  rel='noopener'
                  style={{ color: '#af1e23' }}
                >
                  {t<string>('desktopMacOs')}
                </a>
              ),
            },
            {
              color: '#af1e23',
              dot: <AppleOutlined style={{ fontSize: 30 }} />,
              children: (
                <a
                  href='https://download.mql5.com/cdn/mobile/mt5/ios?server=Inzo-Demo,Inzo-Live'
                  target='_blank'
                  rel='noopener'
                  style={{ color: '#af1e23' }}
                >
                  {t<string>('mobileIOS')}
                </a>
              ),
            },
            {
              color: '#af1e23',
              dot: <AndroidFilled style={{ fontSize: 30 }} />,
              children: (
                <a
                  href='https://download.mql5.com/cdn/mobile/mt5/android?server=Inzo-Live%20&%20Demo'
                  target='_blank'
                  rel='noopener'
                  style={{ color: '#af1e23' }}
                >
                  {t<string>('mobileAndroid')}
                </a>
              ),
            },
          ]}
          style={{ fontSize: 30 }}
        />
      </div>
      <div>
        <h1>cTrader {t<string>('platform')}</h1>
        <Timeline
          items={[
            {
              color: '#1677ff',
              dot: <WindowsFilled style={{ fontSize: 30 }} />,
              children: (
                <a
                  href='https://getctrader.com/inzo/ctrader-inzo-setup.exe'
                  target='_blank'
                  rel='noopener'
                  style={{ color: '#1677ff' }}
                >
                  {t<string>('desktopWindows')}
                </a>
              ),
            },
            {
              color: '#1677ff',
              dot: <AppleOutlined style={{ fontSize: 30 }} />,
              children: (
                <a
                  href='https://apps.apple.com/us/app/inzo-ctrader/id1662543230'
                  target='_blank'
                  rel='noopener'
                  style={{ color: '#1677ff' }}
                >
                  {t<string>('mobileIOS')}
                </a>
              ),
            },
            {
              color: '#1677ff',
              dot: <AndroidFilled style={{ fontSize: 30 }} />,
              children: (
                <a
                  href='https://play.google.com/store/apps/details?id=com.inzo.app&hl=en&gl=US'
                  target='_blank'
                  rel='noopener'
                  style={{ color: '#1677ff' }}
                >
                  {t<string>('mobileAndroid')}
                </a>
              ),
            },
          ]}
          style={{ fontSize: 30 }}
        />
      </div>
    </section>
  );
}

export default Downloads;
