import { Alert, Result, Table } from 'antd';
import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { getMyAccountsColumns } from '../../assets/comfig/myAccountsColumns';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import './MyAccounts.scss';

function MyAccounts() {
  const { t } = useTranslation();
  const [mt5LiveAccounts, setMt5LiveAccounts] = React.useState([]);
  const [mt5DemoAccounts, setMt5DemoAccounts] = React.useState([]);
  const [ibAccounts, setIbAccounts] = React.useState([]);
  const [cTraderLiveAccounts, setCTraderLiveAccounts] = React.useState([]);
  const [cTraderDemoAccounts, setCTraderDemoAccounts] = React.useState([]);
  const [isAccountsLoading, setIsAccountsLoading] = React.useState(false);
  const [isAccountsError, setIsAccountsError] = React.useState('');

  React.useEffect(() => {
    setIsAccountsLoading(true);

    axios
      .get(API_ENDPOINTS.account, { headers: { Authorization: `Bearer ${getUserTokenInCookie()}` } })
      .then((myAccounts: any) => {
        const allAccounts = myAccounts?.data?.data;

        setMt5LiveAccounts(
          allAccounts?.trading.filter((account: any) => account?.accountType?.platform?.type === 'MT5')
        );
        setMt5DemoAccounts(allAccounts?.demo.filter((account: any) => account?.accountType?.platform?.type === 'MT5'));
        setCTraderLiveAccounts(
          allAccounts?.trading.filter((account: any) => account?.accountType?.platform?.type === 'CTRD')
        );
        setCTraderDemoAccounts(
          allAccounts?.demo.filter((account: any) => account?.accountType?.platform?.type === 'CTRD')
        );
        setIbAccounts(allAccounts?.ib);

        setIsAccountsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsAccountsError(t<string>('somethingWentWrongWithLoadingTheAccounts'));
        setIsAccountsLoading(false);
      });
  }, []);

  const myAccountsColumns = React.useMemo(() => getMyAccountsColumns(t), []);

  return (
    <section className='my-accounts-continer'>
      <h1>{t<string>('myAccounts')}</h1>
      <div className='my-accounts-table-container'>
        {isAccountsError ? (
          <Result status='warning' title={t<string>('thereIsProblemWithLoadingListCompetition')} />
        ) : (
          <>
            <div className='account-type-container'>
              <h3>{t<string>('mt5LiveAccounts')}</h3>
              {!isAccountsLoading && mt5LiveAccounts.length === 0 ? (
                <Alert showIcon message={t<string>('youDoNotHaveAccountsOfThisType')} type='info' />
              ) : (
                <Table
                  columns={myAccountsColumns as any}
                  dataSource={mt5LiveAccounts}
                  bordered
                  loading={isAccountsLoading}
                  pagination={false}
                />
              )}
            </div>
            <div className='account-type-container'>
              <h3>{t<string>('cTraderLiveAccounts')}</h3>
              {!isAccountsLoading && cTraderLiveAccounts.length === 0 ? (
                <Alert showIcon message={t<string>('youDoNotHaveAccountsOfThisType')} type='info' />
              ) : (
                <Table
                  columns={myAccountsColumns as any}
                  dataSource={cTraderLiveAccounts}
                  bordered
                  loading={isAccountsLoading}
                  pagination={false}
                />
              )}
            </div>
            <div className='account-type-container'>
              <h3>{t<string>('ibAccounts')}</h3>
              {!isAccountsLoading && ibAccounts.length === 0 ? (
                <Alert showIcon message={t<string>('youDoNotHaveAccountsOfThisType')} type='info' />
              ) : (
                <Table
                  columns={myAccountsColumns as any}
                  dataSource={ibAccounts}
                  bordered
                  loading={isAccountsLoading}
                  pagination={false}
                />
              )}
            </div>
            <div className='account-type-container'>
              <h3>{t<string>('mt5DemoAccounts')}</h3>
              {!isAccountsLoading && mt5DemoAccounts.length === 0 ? (
                <Alert showIcon message={t<string>('youDoNotHaveAccountsOfThisType')} type='info' />
              ) : (
                <Table
                  columns={myAccountsColumns as any}
                  dataSource={mt5DemoAccounts}
                  bordered
                  loading={isAccountsLoading}
                  pagination={false}
                />
              )}
            </div>
            <div className='account-type-container'>
              <h3>{t<string>('cTraderDemoAccounts')}</h3>
              {!isAccountsLoading && cTraderDemoAccounts.length === 0 ? (
                <Alert showIcon message={t<string>('youDoNotHaveAccountsOfThisType')} type='info' />
              ) : (
                <Table
                  columns={myAccountsColumns as any}
                  dataSource={cTraderDemoAccounts}
                  bordered
                  loading={isAccountsLoading}
                  pagination={false}
                />
              )}
            </div>
          </>
        )}
      </div>
      {isAccountsError ? (
        <div className='alert-container'>
          <Alert description={isAccountsError} type='error' showIcon closable onClose={() => setIsAccountsError('')} />
        </div>
      ) : undefined}
    </section>
  );
}

export default MyAccounts;
