import { Tag } from 'antd';
import { format } from 'date-fns';
import { dateFormat } from './dateFormat';

export const getTradesColumns = (t: (value: string) => void) => {
  return [
    {
      title: t('signIn'),
      dataIndex: 'login',
      fixed: 'left' as any,
      sorter: (a: { login: number }, b: { login: number }) => a.login - b.login,
      render: (login: string) => (login !== undefined ? login : '-'),
    },
    {
      title: t('time'),
      dataIndex: 'time',
      sorter: (a: { time: number }, b: { time: number }) => a.time - b.time,
      render: (time: number) => format(time * 1000, dateFormat),
    },
    {
      title: t('deal'),
      dataIndex: 'deal',
      sorter: (a: { deal: number }, b: { deal: number }) => a.deal - b.deal,
      render: (deal: number) => (deal !== undefined ? deal : '-'),
    },
    {
      title: t('order'),
      dataIndex: 'order',
      sorter: (a: { order: number }, b: { order: number }) => a.order - b.order,
      render: (order: number) => (order !== undefined ? order : '-'),
    },
    {
      title: t('position'),
      dataIndex: 'position',
      sorter: (a: { position: number }, b: { position: number }) => a.position - b.position,
      render: (position: number) => (position !== undefined ? position : '-'),
    },
    {
      title: t('symbol'),
      dataIndex: 'symbol',
      sorter: (a: { symbol: string }, b: { symbol: string }) => a.symbol.localeCompare(b.symbol),
      render: (symbol: string) => (symbol !== undefined ? symbol : '-'),
    },
    {
      title: t('type'),
      dataIndex: 'type',
      sorter: (a: { type: number }, b: { type: number }) => a.type - b.type,
      render: (type: number | string) =>
        type === 0 || type === 'TRADE_SIDE_BUY' ? <Tag color='green'>Buy</Tag> : <Tag color='red'>Sell</Tag>,
    },
    {
      title: t('access'),
      dataIndex: 'entry',
      sorter: (a: { entry: number }, b: { entry: number }) => a.entry - b.entry,
      render: (entry: number) =>
        entry !== undefined ? entry === 0 ? <Tag color='green'>In</Tag> : <Tag color='red'>Out</Tag> : '-',
    },
    {
      title: t('volume'),
      dataIndex: 'volume',
      sorter: (a: { volume: number }, b: { volume: number }) => a.volume - b.volume,
      render: (volume: number) => (volume !== undefined ? Math.round((1 / volume) * 100) / 100 : '-'),
    },
    {
      title: t('price'),
      dataIndex: 'price',
      sorter: (a: { price: number }, b: { price: number }) => a.price - b.price,
      render: (price: number) => (price !== undefined ? price : '-'),
    },
    {
      title: 'SL',
      dataIndex: 'sl',
      sorter: (a: { sl: number }, b: { sl: number }) => a.sl - b.sl,
      render: (sl: number) => (sl !== undefined ? sl : '-'),
    },
    {
      title: 'TP',
      dataIndex: 'tp',
      sorter: (a: { tp: number }, b: { tp: number }) => a.tp - b.tp,
      render: (tp: number) => (tp !== undefined ? tp : '-'),
    },
    {
      title: t('reason'),
      dataIndex: 'reason',
      sorter: (a: { reason: number }, b: { reason: number }) => a.reason - b.reason,
      render: (reason: number) => (reason === 16 ? 'Mobile' : 'Desktop'),
    },
    {
      title: t('commission'),
      dataIndex: 'commission',
      sorter: (a: { commission: number }, b: { commission: number }) => a.commission - b.commission,
      render: (commission: number) => (commission !== undefined ? commission : '-'),
    },
    {
      title: t('swap'),
      dataIndex: 'swap',
      sorter: (a: { swap: number }, b: { swap: number }) => a.swap - b.swap,
      render: (swap: number) => (swap !== undefined ? swap : '-'),
    },
    {
      title: t('profit'),
      dataIndex: 'profit',
      sorter: (a: { profit: number }, b: { profit: number }) => a.profit - b.profit,
      render: (profit: number) => (
        <span style={{ color: profit > 0 ? 'green' : profit < 0 ? 'red' : 'gray' }}>{profit}</span>
      ),
    },
    {
      title: t('comment'),
      dataIndex: 'comment',
      sorter: (a: { comment: string }, b: { comment: string }) => a.comment.localeCompare(b.comment),
      render: (comment: string) => (comment !== undefined || comment !== '' ? comment : '-'),
    },
  ];
};
