import { DollarOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Card, Modal, Spin } from 'antd';
import React from 'react';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import WithdawModal from './WithdrawModal/WithdrawModal';
import { useTranslation } from 'react-i18next';
import { WITHDRAW_METHODS } from '../../assets/comfig/withdraw-config';
import axios from 'axios';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import './Withdraw.scss';

const initialWithdrawMethod = { label: '', value: '' };

function Withdraw() {
  const { t } = useTranslation();
  const [showWithdrawModal, setShowWithdrawModal] = React.useState<{ label: string; value: string }>(
    initialWithdrawMethod
  );
  const [userAccounts, setUserAccounts] = React.useState([]);
  const [userAccountsOptions, setUserAccountsOptions] = React.useState([]);
  const [dayOfWeek, setDayOfWeek] = React.useState(0);
  const [isMainPageLoading, setIsMainPageLoading] = React.useState(true);
  const [isMainPageError, setIsMainPageError] = React.useState('');
  const [withdrawSuccess, setWithdrawSuccess] = React.useState('');
  const [withdrawError, setWithdrawError] = React.useState('');

  React.useEffect(() => {
    Promise.all([
      axios.get(API_ENDPOINTS.dayOfWeek, { headers: { Authorization: `Bearer ${getUserTokenInCookie()}` } }),
      axios.get(API_ENDPOINTS.account, { headers: { Authorization: `Bearer ${getUserTokenInCookie()}` } }),
    ])
      .then(([dayOfWeek, myAccounts]: any) => {
        setDayOfWeek(dayOfWeek?.data?.data ?? 0);
        const tradingAccounts = myAccounts?.data?.data?.trading;
        setUserAccounts(tradingAccounts);
        setUserAccountsOptions(
          tradingAccounts.map(
            (account: any) =>
              ({
                value: account?.id,
                label: `#${account?.number} ${account?.accountType.name} | ${account?.balance} ${account?.currency}`,
              } as never)
          )
        );
        setIsMainPageLoading(false);
      })
      .catch((error) => {
        console.error(error);
        console.error(t<string>('somethingWentWrongWithLoadingTheWeekDay'));
        setIsMainPageLoading(false);
      });
  }, []);

  const handelSuccessWidthdaw = () => {
    setWithdrawSuccess(t<string>('theDepositWasSentSuccessfully'));
    setShowWithdrawModal(initialWithdrawMethod);
  };

  return (
    <>
      <section className='withdraw-container'>
        <h1>{t<string>('cashWithdraw')}</h1>
        {isMainPageLoading ? (
          <div className='withdraw-spinner-container'>
            <Spin size='large' />
          </div>
        ) : dayOfWeek === 5 ? (
          <Alert description={t<string>('pleaseBeAwareThatYouCantWithdrawMoenyOnSaturday')} type='warning' showIcon />
        ) : (
          <>
            <Badge.Ribbon text={<DollarOutlined style={{ fontSize: 18 }} />}>
              <Card title='Deposit using wallets'>
                <div className='cards-container'>
                  {WITHDRAW_METHODS.map((withdrawMethod) => (
                    <Button
                      type='primary'
                      loading={isMainPageLoading}
                      onClick={() => setShowWithdrawModal(withdrawMethod)}
                    >
                      {withdrawMethod.label}
                    </Button>
                  ))}
                </div>
              </Card>
            </Badge.Ribbon>
            {Boolean(showWithdrawModal.value) ? (
              <Modal
                title={t<string>('walletWithdawal')}
                open={Boolean(showWithdrawModal.value)}
                footer={null}
                onCancel={() => setShowWithdrawModal(initialWithdrawMethod)}
              >
                <WithdawModal
                  selectedMethod={showWithdrawModal}
                  userAccounts={userAccounts}
                  userAccountsOptions={userAccountsOptions}
                  closeModelSuccessHandler={() => handelSuccessWidthdaw()}
                  errorHandler={(error) => setWithdrawError(error)}
                />
              </Modal>
            ) : undefined}
          </>
        )}
      </section>
      {isMainPageError ? (
        <div className='alert-container'>
          <Alert description={isMainPageError} type='error' showIcon closable onClose={() => setIsMainPageError('')} />
        </div>
      ) : undefined}
      {withdrawError ? (
        <div className='alert-container'>
          <Alert description={withdrawError} type='error' showIcon closable onClose={() => setWithdrawError('')} />
        </div>
      ) : undefined}
      {withdrawSuccess ? (
        <div className='alert-container'>
          <Alert
            description={withdrawSuccess}
            type='success'
            showIcon
            closable
            onClose={() => setWithdrawSuccess('')}
          />
        </div>
      ) : undefined}
    </>
  );
}

export default Withdraw;
