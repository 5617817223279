import { RiseOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Card, Checkbox, Form, Select } from 'antd';
import React from 'react';
import axios from 'axios';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { liveCTraderAccountsTypes, liveMT5AccountsTypes } from '../../assets/comfig/accounts-types';
import { AccountValues } from '../../models/AccountValues';
import { useTranslation } from 'react-i18next';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import './LiveAccount.scss';

function LiveAccount() {
  const { t } = useTranslation();
  const [liveAccountForm] = Form.useForm();
  const [liveAccountFormValid, setLiveAccountFormValid] = React.useState(false);
  const [liveAccountOptions, setLiveAccountOptions] = React.useState([]);
  const [isLiveAccountFormLoading, setIsLiveAccountFormLoading] = React.useState(false);
  const [liveAccountSuccess, setLiveAccountSuccess] = React.useState('');
  const [liveAccountError, setLiveAccountError] = React.useState('');
  const [accountMargins, setAccountMargins] = React.useState<number[]>([]);

  const allLiveAccounts = React.useMemo(
    () => [...Object.values(liveMT5AccountsTypes), ...Object.values(liveCTraderAccountsTypes)],
    []
  );

  function onLiveAccountFormChange(liveAccountValues: AccountValues) {
    setLiveAccountFormValid(
      Boolean(liveAccountValues.accountTypeId) &&
        Boolean(liveAccountValues.leverage) &&
        Boolean(liveAccountValues.agree)
    );
  }

  const onLiveAccountFormSubmit = React.useCallback(() => {
    setIsLiveAccountFormLoading(true);
    axios
      .post(API_ENDPOINTS.liveAccount, liveAccountForm.getFieldsValue(), {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((_: any) => {
        setIsLiveAccountFormLoading(false);
        setLiveAccountSuccess(t<string>('newAccountWasCreated'));
      })
      .catch((error) => {
        console.error(error);
        setLiveAccountError(t<string>('somethingWentWrongWithCreatingNewAccount'));
        setIsLiveAccountFormLoading(false);
      });
  }, []);

  const onPlatformChange = (platformId: string) => {
    if (platformId === 'MT5') {
      setLiveAccountOptions(
        (Object.values(liveMT5AccountsTypes) as any).map((account: any) => ({
          label: account.label,
          value: account.id,
        })) || []
      );
    }
    if (platformId === 'cTrader') {
      setLiveAccountOptions(
        (Object.values(liveCTraderAccountsTypes) as any).map((account: any) => ({
          label: account.label,
          value: account.id,
        })) || []
      );
    }
    liveAccountForm.setFieldValue('leverage', null);
    liveAccountForm.setFieldValue('accountTypeId', '');
    onLiveAccountFormChange(liveAccountForm.getFieldsValue());
    setAccountMargins([]);
  };
  const onAccountTypeChange = (accountTypeId: string) => {
    setAccountMargins(allLiveAccounts.find((account) => account.id === accountTypeId)?.values || []);
    liveAccountForm.setFieldValue('leverage', null);
    onLiveAccountFormChange(liveAccountForm.getFieldsValue());
  };

  return (
    <>
      <section className='liveAccount-container'>
        <h1>{t<string>('openLiveAccount')}</h1>
        <Badge.Ribbon text={<RiseOutlined style={{ fontSize: 18 }} />}>
          <Card title={t<string>('liveAccountForm')}>
            <Form
              name='basic'
              form={liveAccountForm}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600, display: 'flex', flexDirection: 'column' }}
              onValuesChange={(_: any, liveAccountValues: AccountValues) => onLiveAccountFormChange(liveAccountValues)}
              autoComplete='off'
              initialValues={{ text: '', type: '' }}
              onFinish={onLiveAccountFormSubmit}
            >
              <Form.Item
                name='platform'
                label={t<string>('platform')}
                rules={[{ required: true, message: t<string>('platformShoudntBeEmpty') }]}
              >
                <Select
                  onChange={onPlatformChange}
                  style={{ width: '100%' }}
                  options={[
                    { label: 'MT5', value: 'MT5' },
                    { label: 'cTrader', value: 'cTrader' },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name='accountTypeId'
                label={t<string>('liveAccountType')}
                rules={[{ required: true, message: t<string>('accountTypeShouldNotBeEmpty') }]}
              >
                <Select onChange={onAccountTypeChange} style={{ width: '100%' }} options={liveAccountOptions} />
              </Form.Item>
              <Form.Item
                name='leverage'
                label={t<string>('accountLeverage')}
                rules={[{ required: true, message: t<string>('accountLeverageShouldNotBeEmpty') }]}
              >
                <Select
                  style={{ width: '100%' }}
                  options={accountMargins.map((margin) => ({ label: `1:${margin}`, value: margin }))}
                />
              </Form.Item>
              <div style={{ display: 'flex' }}>
                <Form.Item name='agree' valuePropName='checked'>
                  <Checkbox style={{ marginRight: 8 }} />
                </Form.Item>
                <div style={{ marginTop: 4 }}>
                  <span className='no-wrap'>{t<string>('agreeOnThe')}</span>
                  <a href='https://my.inzo/assets/files/terms-and-conditions.pdf' target='_blank' className='no-wrap'>
                    {t<string>('termsAndConditions')}
                  </a>
                </div>
              </div>
              <Form.Item wrapperCol={{ offset: 8, span: 32 }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  disabled={!liveAccountFormValid}
                  loading={isLiveAccountFormLoading}
                >
                  {t<string>('createNewAccount')}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Badge.Ribbon>
      </section>
      {liveAccountError ? (
        <div className='alert-container'>
          <Alert
            description={liveAccountError}
            type='error'
            showIcon
            closable
            onClose={() => setLiveAccountError('')}
          />
        </div>
      ) : undefined}
      {liveAccountSuccess ? (
        <div className='alert-container'>
          <Alert
            description={liveAccountSuccess}
            type='success'
            showIcon
            closable
            onClose={() => setLiveAccountSuccess('')}
          />
        </div>
      ) : undefined}
    </>
  );
}

export default LiveAccount;
