import { MailOutlined } from '@ant-design/icons';
import { Alert, Badge, Button, Card, Form, Input, Select } from 'antd';
import React from 'react';
import { SupportMessage } from '../../models/SupportMessage';
import { API_ENDPOINTS } from '../../assets/api/endpoints';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getUserTokenInCookie } from '../../helpers/cookiesHandler';
import './Support.scss';

function Support() {
  const { t } = useTranslation();
  const [supportForm] = Form.useForm();
  const [supportFormValid, setSupportFormValid] = React.useState(false);
  const [isSupportFormLoading, setIsSupportFormLoading] = React.useState(false);
  const [supportSuccess, setSupportSuccess] = React.useState('');
  const [supportError, setSupportError] = React.useState('');

  function onSupportFormChange(supportValues: SupportMessage) {
    setSupportFormValid(Boolean(supportValues.text) && Boolean(supportValues.topic));
  }

  const onSupportFormSubmit = React.useCallback(() => {
    setIsSupportFormLoading(true);
    axios
      .post(API_ENDPOINTS.support, supportForm.getFieldsValue(), {
        headers: { Authorization: `Bearer ${getUserTokenInCookie()}` },
      })
      .then((_: any) => {
        setIsSupportFormLoading(false);
        setSupportSuccess(t<string>('yourQuestionSentSuccessfully'));
      })
      .catch((error) => {
        console.error(error);
        setSupportError(t<string>('somethingWentWrongWithSendingYourMessage'));
        setIsSupportFormLoading(false);
      });
  }, []);

  return (
    <>
      <section className='support-container'>
        <h1>{t<string>('support')}</h1>
        <Badge.Ribbon text={<MailOutlined style={{ fontSize: 18 }} />}>
          <Card title={t<string>('contactOurSupport')}>
            <Form
              name='basic'
              form={supportForm}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600, display: 'flex', flexDirection: 'column' }}
              onValuesChange={(_: any, supportValues: SupportMessage) => onSupportFormChange(supportValues)}
              autoComplete='off'
              initialValues={{ text: '', type: '' }}
              onFinish={onSupportFormSubmit}
            >
              <Form.Item
                name='topic'
                label={t<string>('questionCategory')}
                rules={[{ required: true, message: t<string>('questionCategoryShouldNotBeEmpty') }]}
              >
                <Select
                  style={{ width: '100%' }}
                  options={[
                    { value: 'type_1', label: t<string>('changeTheAgentOfYourAccount') },
                    { value: 'type_2', label: t<string>('changeThePasswordForYourTradingAccount') },
                    { value: 'type_3', label: t<string>('changeTheMarginOfYourAccount') },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={t<string>('writeYourQuestionHere')}
                name='text'
                rules={[{ required: true, message: t<string>('questionFieldShouldNotBeEmpty') }]}
              >
                <Input.TextArea />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 32 }}>
                <Button type='primary' htmlType='submit' disabled={!supportFormValid} loading={isSupportFormLoading}>
                  {t<string>('sendQuestion')}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Badge.Ribbon>
      </section>
      {supportError ? (
        <div className='alert-container'>
          <Alert description={supportError} type='error' showIcon closable onClose={() => setSupportError('')} />
        </div>
      ) : undefined}
      {supportSuccess ? (
        <div className='alert-container'>
          <Alert description={supportSuccess} type='success' showIcon closable onClose={() => setSupportSuccess('')} />
        </div>
      ) : undefined}
    </>
  );
}

export default Support;
